import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  IconButton,
  Button,
  Tooltip,
  ListItemText,
  FormControlLabel,
  CircularProgress,
  Fade,
  Typography,
  TextField,
  FormGroup,
  TextareaAutosize,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link as LinKDom } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import clsx from "clsx";
// iconos para filtro
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import "moment/locale/es";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import UserStateContext, { signOut } from "../../context/UserContext";
import ResponsiveDatePickers from "../../components/dateRangePicker/dateRangePicker";
import MUIDataTable from "mui-datatables";
import { useStyles } from "./styles";
import UserService from "../../context/UserService";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import moment from "moment";
import MuiToggleButton from "@mui/material/ToggleButton";
import Modal from "@mui/material/Modal";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";
import { darken } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import tinycolor from "tinycolor2";
import FilterListIcon from "@mui/icons-material/FilterList";
import { CustomSearchButton } from "../../components/search-button/search-button";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PreciosEstimadosTable from "../../components/preciosEstimadosTable/preciosEstimadosTable";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';

const ToggleButton = styled(MuiToggleButton)(
  ({ textColor, selectedColor, borderColor }) => ({
    color: "inherit",
    borderColor: borderColor,
    borderRadius: "15px",
    textTransform: "capitalize",
    fontWeight: 'bold',
    maxHeight: "60px",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: textColor,
      backgroundColor: selectedColor,
    },
  }),
);

const EST_FR = 1001;


export default function EsatdoCompras(props) {
  const theme = useTheme();
  const classes = useStyles();
  //var rol = localStorage.getItem("permissions");
  // chequeo que sea chica la pantalla
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isExtraLargeScreen = window.screen.height > 900;
  const tableBodyHeight = isExtraLargeScreen ? 800 : 700;

  const [badgeCount, setBadgeCount] = useState(0);

  const [groupedByDestinoCuota, setGroupedByDestinoCuota] = useState([]);

  //const [valor, setValor] = useState("");
  //valor fecha para buscar
  const [fechaStart, setFechaStart] = useState(() => {
    if (localStorage.getItem("fechaInicio") != undefined) {
      return localStorage.getItem("fechaInicio");
    } else {
      return moment().format("YYYY-MM-DD");
    }
  });
  const [fechaEnd, setFechaEnd] = useState();

  const [searchedMonth, setSearchedMonth] = useState(() => {
    return moment(fechaStart).month() + 1;
  });

  // valor del check agrupacion
  const [agrup, setAgrup] = useState("semana");
  const [tipoCompra, setTipoCompra] = useState(() => {
    if (localStorage.getItem("tCompra") != undefined) {
      return localStorage.getItem("tCompra");
    } else {
      return "Faena";
    }
  });
  const [moneda, setMoneda] = useState("ARG");
  const [remitente, setRemitente] = useState(() => {
    if (localStorage.getItem("remit") != undefined) {
      return localStorage.getItem("remit");
    } else {
      return "";
    }
  });
  const [consignatario, setConsignatario] = useState(() => {
    if (localStorage.getItem("consig") != undefined) {
      return localStorage.getItem("consig");
    } else {
      return "";
    }
  });
  const [especie, setEspecie] = useState("");
  const [destinoComercial, setDestinoComercial] = useState("");
  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);

  // Subtotales desagrupados por peso
  const [agruparPorPeso, setAgruparPorPeso] = useState(false);

  // Mostrar tabla detalle solo cuando sea solicitada
  const [mostrarTablaDetalle, setMostrarTablaDetalle] = useState(false);

  // data para tabla general
  let [dataCompras, setDataCompras] = useState([]);
  // data detalle compras
  const [dataTables, setdataTables] = useState("");

  const [datosTabla, setDatosTabla] = useState("");



  // rol de usuario
  //var rol = localStorage.getItem("permissions");

  // usuario
  //var user = localStorage.getItem("user")
  const { user } = useContext(UserStateContext);
  // desplegables
  const [despStatusCompra, setDespStatusCompra] = useState([]);
  const [despComprador, setDespComprador] = useState([]);

  // filtros personalizados
  const [filtroStatus, setFiltroStatus] = useState(() => {
    if (localStorage.getItem("status") != null) {
      return localStorage.getItem("status").split(",");
    } else {
      return [];
    }
  });
  const [filtroComprador, setFiltroComprador] = useState(() => {
    if (localStorage.getItem("comprador") != null) {
      return localStorage.getItem("comprador").split(",");
    } else {
      return [];
    }
  });
  // autocomplete
  const [autocompleteRemitente, setAutocompleteRemitente] = useState([]);
  const [autocompleteConsig, setAutocompleteConsig] = useState([]);
  //alineamiento de button
  const [alignment, setAlignment] = useState(() => {
    const tcompra = localStorage.getItem("tCompra");
    if (tcompra != undefined && tcompra == 'Invernada') {
      return ["left", "right", "left"]
    } else {
      return ["left", "left", "left"]
    }
  });
  // detalle de busqueda
  const [detalleBusqueda, setDetalleBusqueda] = useState("");
  const [rowDetalle, setRowDetalle] = useState("");

  // datos para el modal
  const [open, setOpen] = React.useState(false);

  const [openBorrar, setOpenBorrar] = React.useState(false);
  const [openConfirmMail, setopenConfirmMail] = React.useState(false);
  // data proveedores
  var [bp, setBp] = useState("");
  var [email, setEmail] = useState("");
  var [cuit, setCUIT] = useState("");
  var [alias, setAlias] = useState("");
  // pagination dataGrid
  const [pageSize, setPageSize] = useState(0);
  // data GENERAL FAENA
  const [dataFaenaAño, setDataFaenaAño] = useState([0]);
  const [dataFaenaMEs, setDataFaenaMEs] = useState([0]);
  const [id, setId] = useState("");
  const [
    selectableRowsHideCheckboxes,
    setSelectableRowsHideCheckboxes,
  ] = useState(false);
  const [rowsSelected, setRowsSelected] = useState("");
  const [IndexRows, setIndexRows] = useState("");
  const [dataEmails, setDataEmails] = useState("");
  const [mensaje, setMensaje] = useState("");
  //estado de indicadores
  const [indicadoresIniciales, setIndicadoresIniciales] = useState([]);

  //Ocultar o mostrar filtros
  const [showFilters, setShowFilters] = useState(false);

  const [showIndicadores, setShowIndicadores] = useState(() => {
    const showInd = localStorage.getItem("showIndicadores");
    if (showInd != undefined) {
      return showInd === 'true';
    } else {
      return false;
    }
  });

  // mostrar u ocultar dias de la semana/mes
  const [showDays, setShowDays] = useState(true);

  // Mensaje opcional para enviar por mail
  var [mensajeEmail, setMensajeEmail] = useState("");

  // Opcion para enviar email predeterminado o personalizado
  var [switchEmail, setSwitchEmail] = useState(false);

  // Opcion para incluir botones de postergar / confirmar carga en el mail
  const [incluyeBotones, setIncluyeBotones] = useState(true);

  // Respuestas de los emails enviados
  var [emailResponses, setEmailResponses] = useState([]);

  var [emailEnviados, setEmailEnviados] = useState(false);

  var [mostrandoRespuestasEmail, setMostrandoRespuestasEmail] = useState(false);

  const isFirstRender = useRef(true);

  const [mostrarFechaFaena, setMostrarFechaFaena] = useState(false);
  const [fechaBusqueda, setFechaBusqueda] = useState(() => {
    const fechaB = localStorage.getItem("fechaBusqueda");
    if (fechaB != undefined) {
      setMostrarFechaFaena(false)
      return fechaB;
    } else {
      setMostrarFechaFaena(true);
      return "";
    }
  });

  const currencyFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: moneda === 'ARG' ? 'ARS' : 'USD',
    maximumFractionDigits: moneda === 'ARG' ? 0 : 2
  });

  // para focus en tabla detalle
  const tableDetalleRef = useRef(null);

  const scrollToTableDetalle = () => {
    setTimeout(() => {
      tableDetalleRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 800);
  }

  useEffect(() => {
    if (mostrarTablaDetalle) {
      scrollToTableDetalle();
    }
  }, [dataTables, mostrarTablaDetalle])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    cargarData(fechaStart, fechaEnd);
    setMostrarTablaDetalle(false);
  }, [tipoCompra, moneda])

  const [estadoFiltros, setEstadoFiltros] = useState({
    start_date: fechaStart,
    end_date: fechaEnd,
    agrupacion: agrup,
    consignatario: consignatario,
    remitente: remitente,
    status: filtroStatus,
    comprador: filtroComprador,
    moneda: moneda,
    especie: '',
    destinoComercial: '',
    tipoCompra: tipoCompra,
    peso: '',
    cuota: '',
    agruparPorPeso: agruparPorPeso,
  });

  // para abrir un snackbar cuando se edita un precio estimado
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState({})

  const handleOpenSnackbar = useCallback((message, severity) => {
    setSnackbar({ message, severity });
    setOpenSnackbar(true);
  }, [])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  useEffect(() => {
    setEstadoFiltros({
      ...estadoFiltros,
      start_date: fechaStart,
      end_date: fechaEnd,
      agrupacion: agrup,
      consignatario: consignatario,
      remitente: remitente,
      status: filtroStatus,
      comprador: filtroComprador,
      moneda: moneda,
      agruparPorPeso: agruparPorPeso,
      tipoCompra: tipoCompra
    })
    let count = 0;
    if (filtroStatus?.length > 0 && filtroStatus[0] !== "") {
      count++;
    }
    if (filtroComprador?.length > 0 && filtroComprador[0] !== "") {
      count++;
    }
    if (remitente !== "") {
      count++;
    }
    if (consignatario !== "") {
      count++;
    }
    setBadgeCount(count);
  }, [fechaStart, fechaEnd, agrup, consignatario, remitente, filtroStatus, filtroComprador, moneda, tipoCompra, agruparPorPeso]);

  const handleSwitchEmail = () => {
    setSwitchEmail(!switchEmail);
  };

  const handleMensajeEmail = (event) => {
    setMensajeEmail(event.target.value);
  };

  const handleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleShowIndicadores = () => {
    localStorage.setItem("showIndicadores", !showIndicadores);
    setShowIndicadores(!showIndicadores);
  };

  // funcion para manejar el modal.
  const handleOpen = (value) => {
    //mensaje modal
    setMensaje(`Confirma borrar la compra?`);
    // abro el modal
    setId(value);
    setOpenBorrar(true);
  };
  const optionsEmailResponses = {
    filter: false,
    responsive: "standard",
    search: false,
    viewColumns: false,
    print: false,
    download: false,
    sort: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,

    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
  };

  const ModalEmail = () => {
    if (emailEnviados === true && mostrandoRespuestasEmail === false) {
      return (
        <div
          className={classes.ModalEmail}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CircularProgress size={26} />
        </div>
      );
    } else if (emailEnviados === true && mostrandoRespuestasEmail === true) {
      return (
        <Box className={classes.ModalRespuestas}>
          <MUIDataTable
            title={"Resultado del envio de emails"}
            data={emailResponses}
            options={optionsEmailResponses}
            columns={[
              {
                name: "Id",
                options: {
                  setCellHeaderProps: () => ({
                    style: { textAlign: "center" },
                  }),
                  setCellProps: () => ({ style: { textAlign: "center" } }),
                },
              },
              {
                name: "Status",
                options: {
                  setCellHeaderProps: () => ({
                    style: { textAlign: "center" },
                  }),
                  setCellProps: () => ({ style: { textAlign: "center" } }),
                  customBodyRender: (valor, dataIndex, updateValue) => {
                    return (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {valor === 201 ? (
                          <CircleRoundedIcon className={classes.verde} />
                        ) : (
                          <CircleRoundedIcon className={classes.rojo} />
                        )}
                      </div>
                    );
                  },
                },
              },
              {
                name: "Email",
                options: {
                  setCellHeaderProps: () => ({
                    style: { textAlign: "center" },
                  }),
                  //setCellProps: () => ({ style: { minWidth: "40px", textAlign: 'center' } })
                },
              },
              {
                name: "Mensaje",
                options: {
                  setCellHeaderProps: () => ({
                    style: { textAlign: "center" },
                  }),
                  //setCellProps: () => ({ style: { minWidth: "40px", textAlign: 'center' } })
                },
              },
            ]}
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleCloseopenConfirmMail}
              className={classes.buttonModal}
            >
              Ok
            </Button>
          </div>
        </Box>
      );
    } else {
      return (
        <Box className={classes.ModalEmail}>
          <div>
            <h2 id="child-modal-description">{mensaje}</h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={switchEmail}
                    onChange={handleSwitchEmail}
                    inputProps={{ "aria-label": "Enviar Email Personalizado" }}
                  />
                }
                label="Email Personalizado"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={incluyeBotones}
                    onChange={() => {
                      setIncluyeBotones(!incluyeBotones);
                    }}
                    inputProps={{ "aria-label": "Enviar Email Personalizado" }}
                  />
                }
                label="Confirmar / Postergar Carga"
              />
            </FormGroup>
          </div>
          {!switchEmail && incluyeBotones ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {
                  "Por default se enviara un email para confirmar/postegar la compra"
                }
              </Typography>
            </div>
          ) : !switchEmail && !incluyeBotones ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {"Debe seleccionar al menos una opcion"}
              </Typography>
            </div>
          ) : (
            <div style={{ marginTop: "5px" }}>
              <TextareaAutosize
                rowsMin={3}
                value={mensajeEmail}
                onChange={handleMensajeEmail}
                aria-label="Mensaje"
                className={classes.textarea}
                placeholder="Ingrese el mensaje a enviar"
                style={{ width: "100%" }}
              />
            </div>
          )}
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonModal}
              disabled={
                (switchEmail && mensajeEmail === "") ||
                  (!switchEmail && !incluyeBotones)
                  ? true
                  : false
              }
              onClick={() => sendEmail(IndexRows, dataEmails)}
            >
              Confirmar
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleCloseopenConfirmMail}
              className={classes.buttonModal}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      );
    }
  };

  //borrar Compra
  const borrarCompra = async (history) => {
    //funcion para borrar compra. Sino esta logueado hace signout.
    try {
      let response = await UserService.deleteCompra(id, history)
      if (response.status === 200) {
        handleOpenSnackbar('Compra eliminada correctamente', 'success' );
        await setTimeout(5000);
        cargarData(fechaStart, fechaEnd);
        const paramsLS = localStorage.getItem("params")
        const fechaBusqueda = localStorage.getItem("fechaBusqueda")
        if (paramsLS != undefined) {
          const parametros = JSON.parse(paramsLS);
          dataTablaDetalle(fechaStart, fechaEnd, true, parametros);
        } else {
          dataTablaDetalle(fechaBusqueda, fechaBusqueda);
        }
      } else {
        throw new Error(response?.data?.message || 'Error al borrar la compra');
      }
    } catch (error) {
      handleOpenSnackbar(error?.response?.data?.message || error?.message, 'error' );
    } finally {
      setOpenBorrar(false);
    }
  };
  const handleClose = () => setOpen(false);

  const handleCloseBorrar = () => setOpenBorrar(false);

  const handleOpenConfirmMail = (selectedRows, displayData) => {
    setMensaje(
      "¿Confirma enviar email a los proveedores de las compras seleccionadas?",
    );
    setIndexRows(selectedRows);
    setDataEmails(displayData);
    setopenConfirmMail(true);
  };
  const handleCloseopenConfirmMail = () => {
    setopenConfirmMail(false);
    setMostrandoRespuestasEmail(false);
    setEmailResponses([]);
    setEmailEnviados(false);
    setMensajeEmail("");
    setSwitchEmail(false);
    setIncluyeBotones(true);
  };
  // saco los dias de la primer semana.
  function dates(current) {
    var week = new Array();
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay());
    for (var i = 0; i < 5; i++) {
      week.push(new Date(current).toISOString().split("T")[0]);
      current.setDate(current.getDate() + 1);
    }
    return week;
  }

  //funcion para sacar todas las fechas de un mes
  function getDatesInMonth(date) {
    var year = date.getFullYear();
    var month = date.getMonth();
    var dates = [];

    // Creamos una nueva fecha con el primer día del mes
    var currentDate = new Date(year, month, 1);

    // Mientras que el mes de la fecha actual sea igual al mes original
    while (currentDate.getMonth() === month) {
      // Añadimos la fecha actual al array
      dates.push(new Date(currentDate).toISOString().split("T")[0]);

      // Incrementamos la fecha en un día
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  // funcion primer dia de la semana
  function getFirstDayOfWeek(weekNumber, year) {
    var date = new Date(year, 0, 1);
    var day = date.getDay();
    var daysToAdd = (weekNumber - 1) * 7;

    if (day === 0) {
      daysToAdd -= 7;
    } else {
      daysToAdd += 1 - day;
    }

    date.setDate(date.getDate() + daysToAdd);

    return date.toISOString().split("T")[0];
  }

  //agrupar sacar semanas
  function showWeeks(year, month) {
    let firstWeek = moment(new Date(year, month, 1)).isoWeek();
    let lastWeek = moment(new Date(year, month + 1, 0)).isoWeek();
    let out = [firstWeek];
    if (firstWeek === 52 || firstWeek === 53) {
      firstWeek = 0;
    }
    for (let i = firstWeek + 1; i <= lastWeek; i++) {
      out.push(i);
    }
    return out;
  }

  // ajusto fechas para la primer semana por las dudas
  const DiasJustos = (data, inicio) => {
    //
    const value = data;
    const fechaInicial = new Date(inicio);
    const fechaFinal = new Date(inicio);
    fechaFinal.setDate(fechaInicial.getDate() + 4);

    //var daylist = getDaysArray(fechaInicial,fechaFinal);
    var daylist = dates(fechaInicial);

    var weeklist = showWeeks(
      moment(inicio).startOf("isoWeek").year(),
      moment(inicio).startOf("isoWeek").month(),
    );
    for (let i = 0; i < value.length; i++) {
      const element = value[i]["status"];
      if (element) {
        if (element.find((el) => el == "SinConfirmar")) {
          value[i]["status"] = "SinConfirmar";
        } else if (
          element.find((el) => el == "tramite") &&
          element.find((el) => el != "SinConfirmar")
        ) {
          value[i]["status"] = "tramite";
        } else if (element.find((el) => el == "total")) {
          value[i]["status"] = "total";
        } else {
          value[i]["status"] = "confirmado";
        }
      }
    }

    for (let i = 0; i < value.length; i++) {
      const fechas = value[i]["fecha_de_faena"];
      const fech2 = [];
      const status2 = [];

      if (agrup == "semana") {
        daylist.forEach((val) => {
          if (fechas.includes(val)) {
            const index = fechas.indexOf(val);
            fech2.push(value[i]["cabezas_compradas"][index]);
            status2.push(value[i]["status"]);
          } else {
            fech2.push(0);
            status2.push(0);
          }
        });
      } else {
        weeklist.forEach((val) => {
          if (fechas.includes(val)) {
            const index = fechas.indexOf(val);
            fech2.push(value[i]["cabezas_compradas"][index]);
            status2.push(value[i]["status"]);
          } else {
            fech2.push(0);
            status2.push(0);
          }
        });
      }

      value[i]["cabezas_compradas"] = fech2;
      value[i]["status"] = status2;
    }
    return value;
  };

  // ajusto fechas para la primer semana por las dudas
  const DiasJustosI = (data, inicio) => {
    //
    const value = data;
    const fechaInicial = new Date(inicio);
    const fechaFinal = new Date(inicio);
    fechaFinal.setDate(fechaInicial.getDate() + 4);

    //var daylist = getDaysArray(fechaInicial,fechaFinal);
    var daylist = dates(fechaInicial);

    var weeklist = showWeeks(
      moment(inicio).startOf("isoWeek").year(),
      moment(inicio).startOf("isoWeek").month(),
    );
    for (let i = 0; i < value.length; i++) {
      const element = value[i]["status"];
      if (element) {
        if (element.find((el) => el == "SinConfirmar")) {
          value[i]["status"] = "SinConfirmar";
        } else if (
          element.find((el) => el == "tramite") &&
          element.find((el) => el != "SinConfirmar")
        ) {
          value[i]["status"] = "tramite";
        } else if (element.find((el) => el == "total")) {
          value[i]["status"] = "total";
        } else {
          value[i]["status"] = "confirmado";
        }
      }
    }

    for (let i = 0; i < value.length; i++) {
      const fechas = value[i]["fecha_de_compra"];
      const fech2 = [];
      const status2 = [];

      if (agrup == "semana") {
        daylist.forEach((val) => {
          if (fechas.includes(val)) {
            const index = fechas.indexOf(val);
            fech2.push(value[i]["cabezas_compradas"][index]);
            status2.push(value[i]["status"]);
          } else {
            fech2.push(0);
            status2.push(0);
          }
        });
      } else {
        weeklist.forEach((val) => {
          if (fechas.includes(val)) {
            const index = fechas.indexOf(val);
            fech2.push(value[i]["cabezas_compradas"][index]);
            status2.push(value[i]["status"]);
          } else {
            fech2.push(0);
            status2.push(0);
          }
        });
      }

      value[i]["cabezas_compradas"] = fech2;
      value[i]["status"] = status2;
    }
    return value;
  };
  //agrupar data filtros
  const agruparData = (data, start) => {
    var result = [];
    var fechas = getDatesInMonth(new Date(start));
    //console.log(fechas)
    if (agrup == "semana") {
      //agrupor data por dia
      //debugger;
      data.reduce(function (res, value) {
        var temp = (value.destino_Comercial == 'Exportación UE' && (value.cuota == null || value.cuota == '')) ? 'Sin cuota' : value.cuota;
        //if (temp == 'Sin cuota') {console.log(temp)}
        var key =
          value.categoriaEXP +
          "-" +
          value.destino_Comercial.split(" ")[0] +
          "-" +
          value.especie +
          temp;
        if (!res[key]) {
          res[key] = {
            categoriaEXP: value.categoriaEXP,
            destino_Comercial: value.destino_Comercial,
            fecha_de_faena: [],
            cuota: value.cuota,
            peso: 0,
            precio: 0,
            cabezas_compradas: [],
            cabezasTot: 0,
            count: 0,
            status: [],
            precio_total: 0,
            kilos_totales: 0,
          };
          result.push(res[key]);
        }
        res[key].count += 1;
        if (res[key].fecha_de_faena.includes(value.fecha_de_faena)) {
          const index = res[key].fecha_de_faena.indexOf(value.fecha_de_faena);
          res[key].cabezas_compradas[index] += value.cabezas_compradas;
        } else {
          res[key].fecha_de_faena.push(value.fecha_de_faena);
          res[key].cabezas_compradas.push(value.cabezas_compradas);
        }
        res[key].status.push(value.status);
        res[key].cabezasTot += parseInt(value.cabezas_compradas);
        res[key].cuota = value.cuota;
        res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
        res[key].peso += parseInt(value.peso);
        res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
        res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

        return res;
      }, {});
    } else {
      //agrupor data por semana
      data.reduce(function (res, value) {
        var temp = (value.destino_Comercial == 'Exportación UE' && value.cuota == null || value.cuota == '') ? 'Sin cuota' : value.cuota;
        var key =
          value.categoriaEXP +
          "-" +
          value.destino_Comercial.split(" ")[0] +
          "-" +
          value.especie +
          temp;
        // if (fechas.includes(value.fecha_de_faena)) {

        if (!res[key]) {
          res[key] = {
            categoriaEXP: value.categoriaEXP,
            destino_Comercial: value.destino_Comercial,
            fecha_de_faena: [],
            cuota: 0,
            peso: 0,
            precio: 0,
            cabezas_compradas: [],
            cabezasTot: 0,
            count: 0,
            status: [],
            precio_total: 0,
            kilos_totales: 0,
          };
          result.push(res[key]);
        }
        res[key].count += 1;
        if (
          res[key].fecha_de_faena.includes(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          )
        ) {
          const index = res[key].fecha_de_faena.indexOf(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          );
          res[key].cabezas_compradas[index] += value.cabezas_compradas;
        } else {
          res[key].fecha_de_faena.push(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          );
          res[key].cabezas_compradas.push(value.cabezas_compradas);
        }

        res[key].status.push(value.status);
        res[key].cabezasTot += parseInt(value.cabezas_compradas);
        res[key].cuota = value.cuota;
        res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
        res[key].peso += parseInt(value.peso);
        res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
        res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        // }
        //console.log(fechas.includes(value.fecha_de_faena))
        /* res[key].count +=1
        if ( res[key].fecha_de_faena.includes(moment(value.fecha_de_faena).startOf("isoWeek").week())){
          const index = res[key].fecha_de_faena.indexOf(moment(value.fecha_de_faena).startOf("isoWeek").week())
          res[key].cabezas_compradas[index]+=(value.cabezas_compradas)
        }else{
          res[key].fecha_de_faena.push(moment(value.fecha_de_faena).startOf("isoWeek").week())
          res[key].cabezas_compradas.push(value.cabezas_compradas)
        }
        
        res[key].status.push(value.status)
        res[key].cabezasTot +=parseInt(value.cabezas_compradas)
        res[key].cuota=value.cuota
        res[key].precio += parseInt(value.precio)
        res[key].peso += parseInt(value.peso) */

        return res;
      }, {});
    }

    const ordenado = [
      ...result.filter((x) => x.destino_Comercial == "Exportación UE"),
      ...result.filter((x) => x.destino_Comercial == "Exportación TP"),
      ...result.filter((x) => x.destino_Comercial == "Exportacion Manufactura"),
      ...result.filter((x) => x.destino_Comercial == "Consumo"),
    ];

    return ordenado;
  };
  // totales con categoria
  const totalesCategoria = (data, start) => {
    var result = [];
    var fechas = getDatesInMonth(new Date(start));

    if (agrup == "mes") {
      data.reduce(function (res, value) {
        // if (fechas.includes(value.fecha_de_faena)) {

        if (value.destino_Comercial == "Exportación UE") {
          var key = value.destino_Comercial;

          if (!res[key]) {
            res[key] = {
              categoriaEXP: "Total " + value.destino_Comercial,
              categoria: value.destino_Comercial,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0
            };
            result.push(res[key]);
          }
          res[key].count += 1;

          if (
            res[key].fecha_de_faena.includes(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            )
          ) {
            const index = res[key].fecha_de_faena.indexOf(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            );
            res[key].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[key].fecha_de_faena.push(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            );
            res[key].cabezas_compradas.push(value.cabezas_compradas);
          }

          res[key].cabezasTot += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        } else {
          var key = value.destino_Comercial;
          if (!res[key]) {
            res[key] = {
              categoriaEXP:
                "Total " +
                (value.destino_Comercial == "Exportación TP"
                  ? "Otros Países"
                  : value.destino_Comercial == "Exportacion Manufactura"
                    ? "Conserva"
                    : value.destino_Comercial),
              categoria: value.destino_Comercial,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0
            };
            result.push(res[key]);
          }
          res[key].count += 1;

          if (
            res[key].fecha_de_faena.includes(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            )
          ) {
            const index = res[key].fecha_de_faena.indexOf(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            );
            res[key].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[key].fecha_de_faena.push(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            );
            res[key].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[key].cabezasTot += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        }
        // }

        return res;
      }, {});
    } else {
      data.reduce(function (res, value) {
        if (value.destino_Comercial == "Exportación UE") {
          // var key = (value.destino_Comercial + '-' + value.categoriaEXP)
          // if (!res[key]) {
          //   res[key] = {
          //     categoriaEXP: 'Total ' + value.categoriaEXP + ' ' + (value.destino_Comercial), categoria: value.categoriaEXP, destino: value.destino_Comercial, fecha_de_faena: [], cuota: 0, peso: 0,
          //     precio: 0, cabezas_compradas: [], cabezasTot: 0, count: 0, tipo: 'subtotales', status: ['total']
          //   };
          //   result.push(res[key])
          // }
          // res[key].count += 1

          // if (res[key].fecha_de_faena.includes(value.fecha_de_faena)) {
          //   const index = res[key].fecha_de_faena.indexOf(value.fecha_de_faena)
          //   res[key].cabezas_compradas[index] += (value.cabezas_compradas)
          // } else {
          //   res[key].fecha_de_faena.push(value.fecha_de_faena)
          //   res[key].cabezas_compradas.push(value.cabezas_compradas)
          // }
          // res[key].cabezasTot += parseInt(value.cabezas_compradas)
          // res[key].cuota = value.cuota
          // res[key].precio += parseInt(value.precio)
          // res[key].peso += parseInt(value.peso)
          var key = value.destino_Comercial;
          if (!res[key]) {
            res[key] = {
              categoriaEXP: "Total " + value.destino_Comercial,
              categoria: value.destino_Comercial,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0,
            };
            result.push(res[key]);
          }
          res[key].count += 1;

          if (res[key].fecha_de_faena.includes(value.fecha_de_faena)) {
            const index = res[key].fecha_de_faena.indexOf(value.fecha_de_faena);
            res[key].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[key].fecha_de_faena.push(value.fecha_de_faena);
            res[key].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[key].cabezasTot += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        } else {
          var key = value.destino_Comercial;
          if (!res[key]) {
            res[key] = {
              categoriaEXP:
                "Total " +
                (value.destino_Comercial == "Exportación TP"
                  ? "Otros Países"
                  : value.destino_Comercial == "Exportacion Manufactura"
                    ? "Conserva"
                    : value.destino_Comercial),
              categoria: value.destino_Comercial,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0
            };
            result.push(res[key]);
          }
          res[key].count += 1;

          if (res[key].fecha_de_faena.includes(value.fecha_de_faena)) {
            const index = res[key].fecha_de_faena.indexOf(value.fecha_de_faena);
            res[key].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[key].fecha_de_faena.push(value.fecha_de_faena);
            res[key].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[key].cabezasTot += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        }

        return res;
      }, {});
    }
    const ordenado = [
      ...result.filter((x) => x.destino == "Exportación UE"),
      ...result.filter((x) => x.destino == "Exportación TP"),
      ...result.filter((x) => x.destino == "Exportacion Manufactura"),
      ...result.filter((x) => x.destino == "Consumo"),
    ];

    return ordenado;
  };

  // totales con categoria UE
  const totalesCategoriaUE = (data, start) => {
    var result = [];
    var fechas = getDatesInMonth(new Date(start));

    if (agrup == "mes") {
      data.reduce(function (res, value) {
        if (fechas.includes(value.fecha_de_faena)) {
          if (value.destino_Comercial == "Exportación UE") {
            var key = value.destino_Comercial + "-" + value.categoriaEXP;

            if (!res[key]) {
              res[key] = {
                categoriaEXP: "Total " + value.destino_Comercial,
                categoria: value.categoriaEXP,
                destino: value.destino_Comercial,
                fecha_de_faena: [],
                cuota: 0,
                peso: 0,
                precio: 0,
                cabezas_compradas: [],
                cabezasTot: 0,
                count: 0,
                tipo: "subtotales",
                status: ["total"],
                precio_total: 0,
                kilos_totales: 0,
              };
              result.push(res[key]);
            }
            res[key].count += 1;

            if (res[key].fecha_de_faena.includes(value.fecha_de_faena)) {
              const index = res[key].fecha_de_faena.indexOf(
                value.fecha_de_faena,
              );
              res[key].cabezas_compradas[index] += value.cabezas_compradas;
            } else {
              res[key].fecha_de_faena.push(value.fecha_de_faena);
              res[key].cabezas_compradas.push(value.cabezas_compradas);
            }
            res[key].cabezasTot += parseInt(value.cabezas_compradas);
            res[key].cuota = value.cuota;
            res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
            res[key].peso += parseInt(value.peso);
            res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
            res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
          }
        }

        return res;
      }, {});
    } else {
      data.reduce(function (res, value) {
        if (value.destino_Comercial == "Exportación UE") {
          var key = value.destino_Comercial + "-" + value.categoriaEXP;
          if (!res[key]) {
            res[key] = {
              categoriaEXP: "Total " + value.destino_Comercial,
              categoria: value.categoriaEXP,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0,
            };
            result.push(res[key]);
          }
          res[key].count += 1;

          if (res[key].fecha_de_faena.includes(value.fecha_de_faena)) {
            const index = res[key].fecha_de_faena.indexOf(value.fecha_de_faena);
            res[key].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[key].fecha_de_faena.push(value.fecha_de_faena);
            res[key].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[key].cabezasTot += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        }

        return res;
      }, {});
    }
    const ordenado = [...result.filter((x) => x.destino == "Exportación UE")];

    return ordenado;
  };

  // totales con categoria UE
  const totalesCategoriaUEOPaises = (data, start) => {
    var result = [];
    var fechas = getDatesInMonth(new Date(start));

    if (agrup == "mes") {
      data.reduce(function (res, value) {
        // if (fechas.includes(value.fecha_de_faena)) {

        if (
          value.destino_Comercial == "Exportación UE" ||
          value.destino_Comercial == "Exportación TP"
        ) {
          if (!res[0]) {
            res[0] = {
              categoriaEXP: "Total Exportacion UE + Otros Paises ",
              categoria: value.categoriaEXP,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotalesUE",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0,
            };
            result.push(res[0]);
          }
          res[0].count += 1;

          if (
            res[0].fecha_de_faena.includes(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            )
          ) {
            const index = res[0].fecha_de_faena.indexOf(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            );
            res[0].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[0].fecha_de_faena.push(
              moment(value.fecha_de_faena).startOf("isoWeek").week(),
            );
            res[0].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[0].cabezasTot += parseInt(value.cabezas_compradas);
          res[0].cuota = value.cuota;
          res[0].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[0].peso += parseInt(value.peso);
          res[0].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[0].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        }
        // }

        return res;
      }, {});
    } else {
      data.reduce(function (res, value) {
        if (
          value.destino_Comercial == "Exportación UE" ||
          value.destino_Comercial == "Exportación TP"
        ) {
          if (!res[0]) {
            res[0] = {
              categoriaEXP: "Total Exportacion UE + Otros Paises ",
              categoria: value.categoriaEXP,
              destino: value.destino_Comercial,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotalesUE",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0,
            };
            result.push(res[0]);
          }
          res[0].count += 1;

          if (res[0].fecha_de_faena.includes(value.fecha_de_faena)) {
            const index = res[0].fecha_de_faena.indexOf(value.fecha_de_faena);
            res[0].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[0].fecha_de_faena.push(value.fecha_de_faena);
            res[0].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[0].cabezasTot += parseInt(value.cabezas_compradas);
          res[0].cuota = value.cuota;
          res[0].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[0].peso += parseInt(value.peso);
          res[0].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[0].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        }

        return res;
      }, {});
    }
    const ordenado = [
      ...result.filter((x) => x.destino == "Exportación UE"),
      ...result.filter((x) => x.destino == "Exportación TP"),
      ...result.filter((x) => x.destino == "Exportacion Manufactura"),
      ...result.filter((x) => x.destino == "Consumo"),
    ];

    return ordenado;
  };
  //totales general
  const Totales = (data, start) => {
    var result = [];
    var fechas = getDatesInMonth(new Date(start));
    //console.log(data)

    if (agrup == "mes") {
      data.reduce(function (res, value) {
        // if (fechas.includes(value.fecha_de_faena)) {

        if (!res[0]) {
          res[0] = {
            categoriaEXP: "Total Compras",
            fecha_de_faena: [],
            cuota: 0,
            peso: 0,
            precio: 0,
            cabezas_compradas: [],
            cabezasTot: 0,
            count: 0,
            tipo: "totales",
            status: ["total"],
            precio_total: 0,
            kilos_totales: 0
          };
          result.push(res[0]);
        }
        res[0].count += 1;
        if (
          res[0].fecha_de_faena.includes(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          )
        ) {
          const index = res[0].fecha_de_faena.indexOf(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          );
          res[0].cabezas_compradas[index] += value.cabezas_compradas;
        } else {
          res[0].fecha_de_faena.push(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          );
          res[0].cabezas_compradas.push(value.cabezas_compradas);
        }

        res[0].cabezasTot += parseInt(value.cabezas_compradas);
        res[0].cuota = value.cuota;
        res[0].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
        res[0].peso += parseInt(value.peso);
        res[0].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
        res[0].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        // }

        return res;
      }, {});
    } else {
      data.reduce(function (res, value) {
        //debugger;
        var key = value.destino_Comercial;
        if (!res[0]) {
          res[0] = {
            categoriaEXP: "Total Compras",
            fecha_de_faena: [],
            cuota: 0,
            peso: 0,
            precio: 0,
            cabezas_compradas: [],
            cabezasTot: 0,
            count: 0,
            tipo: "totales",
            status: ["total"],
            precio_total: 0,
            kilos_totales: 0
          };
          result.push(res[0]);
        }
        res[0].count += 1;
        // res[0].fecha_de_faena.push(value.fecha_de_faena)
        // res[0].cabezas_compradas.push(value.cabezas_compradas)
        if (res[0].fecha_de_faena.includes(value.fecha_de_faena)) {
          const index = res[0].fecha_de_faena.indexOf(value.fecha_de_faena);
          res[0].cabezas_compradas[index] += value.cabezas_compradas;
        } else {
          res[0].fecha_de_faena.push(value.fecha_de_faena);
          res[0].cabezas_compradas.push(value.cabezas_compradas);
        }
        res[0].cabezasTot += parseInt(value.cabezas_compradas);
        res[0].cuota = value.cuota;
        res[0].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
        res[0].peso += parseInt(value.peso);
        res[0].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
        res[0].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

        return res;
      }, {});
    }
    return result;
  };

  // totales Especies
  //totales general
  const TotalesEsp = (data, start) => {
    var result = [];
    var fechas = getDatesInMonth(new Date(start));
    //console.log(fechas)
    if (agrup == "mes") {
      data.reduce(function (res, value) {
        var key = value.especie;
        if (fechas.includes(value.fecha_de_faena)) {
          if (!res[key]) {
            res[key] = {
              categoriaEXP: "Total " + value.especie,
              fecha_de_faena: [],
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "subtotales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0
            };
            result.push(res[key]);
          }
          res[key].count += 1;
          res[key].fecha_de_faena.push(
            moment(value.fecha_de_faena).startOf("isoWeek").week(),
          );
          res[key].cabezas_compradas.push(value.cabezas_compradas);
          res[key].cabezasTot += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);
        }

        return res;
      }, {});
    } else {
      data.reduce(function (res, value) {
        var key = value.especie;
        if (!res[key]) {
          res[key] = {
            categoriaEXP: "Total " + value.especie,
            fecha_de_faena: [],
            cuota: 0,
            peso: 0,
            precio: 0,
            cabezas_compradas: [],
            cabezasTot: 0,
            count: 0,
            tipo: "subtotales",
            status: ["total"],
            precio_total: 0,
            kilos_totales: 0
          };
          result.push(res[key]);
        }
        res[key].count += 1;
        res[key].fecha_de_faena.push(value.fecha_de_faena);
        res[key].cabezas_compradas.push(value.cabezas_compradas);
        res[key].cabezasTot += parseInt(value.cabezas_compradas);
        res[key].cuota = value.cuota;
        res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
        res[key].peso += parseInt(value.peso);
        res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
        res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

        return res;
      }, {});
    }
    return result;
  };

  //agrupo para tabla pequeña Indicadores
  const agruparDataDetalle = (data) => {
    var result = [];
    //agrupor data por dia
    data.reduce(function (res, value) {
      var key = value.destino_Comercial + "-" + value.cuota;
      if (!res[key]) {
        res[key] = {
          Destino: value.destino_Comercial,
          Cuota: value.cuota,
          CantidadReal: 0,
        };
        result.push(res[key]);
      }

      res[key].CantidadReal += parseInt(value.cabezas_compradas);
      return res;
    }, {});

    return result;
  };
  // modal para remitente o consignatario
  const openModal = (id) => {
    setOpen(true);
    setBp("");
    setEmail("");
    setCUIT("");
    setAlias("");

    UserService.getProveedorRazonS(id).then((response) => {
      if (response.status == 200) {
        const data = response.data.users[0];
        setBp(data.bp);
        setEmail(data.email);
        setCUIT(data.cuit);

        setAlias(data.razonS);
      }
    });
  };

  // Funcion para agregar los dias de la semana.
  const sumarInfoTabla = (start, end) => {
    const columns = [];
    var daylist = dates(new Date(start));
    let i = agrup == "semana" ? 0 : 1;
    for (i; i < daylist.length; i++) {
      if (agrup == "semana") {
        const fecha = moment(daylist[i], "YYYY-MM-DD")
          .format("ddd DD")
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        const fechaFormato = moment(daylist[i], "YYYY-MM-DD").format(
          "YYYY-MM-DD",
        );
        columns.push({
          field: i + 8,
          headerName: (
            <Link
              className={classes.customLink}
              replace
              onClick={() => {
                setMostrarFechaFaena(false);
                setFechaBusqueda(fechaFormato);
                localStorage.setItem("fechaBusqueda", fechaFormato);
                localStorage.removeItem("params");
                dataTablaDetalle(fechaFormato, fechaFormato);
                let fechaInicioSemana = localStorage.getItem("fechaInicio");
                cargarData(fechaInicioSemana);
              }}
            >
              {fecha}
            </Link>
          ),
          flex: 1,
          disableColumnMenu: true,
          disableReorder: true,
          sortable: false,
          minWidth: 60,
          align: "right",
        });
      } else {
        var weeklist = showWeeks(
          moment(start).startOf("isoWeek").year(),
          moment(start).startOf("isoWeek").month(),
        );
        var fecha = getFirstDayOfWeek(
          weeklist[i],
          moment(start).startOf("isoWeek").year(),
        );
        //console.log(weeklist)
        const fechaInicio = moment(fecha, "YYYY-MM-DD").format("YYYY-MM-DD");
        const fechaFormato = moment(fecha, "YYYY-MM-DD").add(5, 'days').format("YYYY-MM-DD");
        columns.push({
          field: i + 8,
          headerName: (
            <Link
              className={classes.customLink}
              replace
              onClick={() => {
                setMostrarFechaFaena(true);
                dataTablaDetalle(fechaInicio, fechaFormato);
                let fechaInicioSemana = localStorage.getItem("fechaInicio");
                cargarData(fechaInicioSemana);
                // dataTablaDetalleFECHA(
                //   getFirstDayOfWeek(
                //     weeklist[i + 1],
                //     moment(start).startOf("isoWeek").year(),
                //   ),
                // );
              }}
            >
              {"Sem " + weeklist[i]}
            </Link>
          ),

          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          minWidth: 60,
          align: "right",
        });
      }
    }
    return columns;
  };

  //Cargo los estados de los filtros
  useEffect(() => {
    UserService.getFiltros().then(
      (response) => {
        if (response.status == 200) {
          setDespStatusCompra(response.data.dataStatus);
        }

        if (response.status == 401) {
          signOut(props.history);
        }
      },
      (error) => {
        setErrorServidor(true);
        if (error.response.status == 401) {
          signOut(props.history);
        }
      },
    );
    UserService.getListcompradores().then(
      (response) => {
        if (response.status == 200) {
          setDespComprador(response.data.data);
        }
      },
      (error) => {
        setErrorServidor(true);
      },
    );
    UserService.getListConsigRemit().then(
      (response) => {
        if (response.status == 200) {
          //console.log(response.data)
          const dataTropaConsig = response.data.dataConsig.map((a) => {
            if (a.consignatario != "" && a.consignatario != null) {
              if (a.consignatario.includes("-")) {
                return {
                  cuit: a.consignatario.split("-")[0],
                  label: a.consignatario.split("-")[1],
                };
              } else {
                return { cuit: "", label: a.consignatario };
              }
            }
          });
          const dataTropaRemit = response.data.dataREmitente.map((a) => {
            if (a.remitente != "" && a.remitente != null) {
              if (a.remitente.includes("-")) {
                return {
                  cuit: a.remitente.split("-")[0],
                  label: a.remitente.split("-")[1],
                };
              } else {
                return { cuit: "", label: a.remitente };
              }
            }
          });
          const dataC = dataTropaConsig.filter(function (element) {
            return element !== undefined;
          });
          const dataR = dataTropaRemit.filter(function (element) {
            return element !== undefined;
          });

          setAutocompleteConsig(dataC);
          setAutocompleteRemitente(dataR);
          dataInicial();



          //
          /*   const dataTropaConsig = response.data.map(a =>({cuit: a.cuit_proveedor, label:a.razon_social}) )
    
            //autocomplete
            setAutocompleteRemitente((dataTropaConsig))
            setAutocompleteConsig((dataTropaConsig))
            setAutocompleteComisionista((dataTropaConsig)) */
        }
      },
      (error) => {
        setErrorServidor(true);
      },
    );
    /*      // saco la info de datas compras del local storage
         localStorage.removeItem('datosTabla')
         localStorage.removeItem('datosTabla')  */
    //localStorage.getItem('status')!=''?setFiltroStatus(localStorage.getItem('status').split(',')):'';
    //setFiltroComprador(localStorage.getItem('comprador').split(','))
  }, []);

  const dataInicial = () => {
    let InitDate = localStorage.getItem("fechaInicio");
    let EndtDate = localStorage.getItem("fechaEnd");
    let tipoCompra = localStorage.getItem("tCompra");
    let dataComprasInicial = JSON.parse(localStorage.getItem("dataCompras"));

    if (
      InitDate != undefined &&
      EndtDate != undefined //&&
      //(dataComprasInicial != undefined || tipoCompra === 'Invernada')
    ) {
      cargarData(InitDate);

      const fechaInicial = localStorage.getItem("fechaBusqueda")
      const paramsLS = localStorage.getItem("params")

      const fechaTablaDetalle = fechaInicial != undefined ? fechaInicial : InitDate

      if (paramsLS != undefined) {
        const parametros = JSON.parse(paramsLS);
        //console.log(parametros)
        dataTablaDetalle(InitDate, EndtDate, true, parametros);
      } else {
        dataTablaDetalle(fechaTablaDetalle, fechaTablaDetalle);
      }
    } else {
      // get the first day of the current week
      const monday = moment().startOf("isoWeek").format("YYYY-MM-DD");

      let fechas = dates(new Date(monday));
      setFechaStart(fechas[0]);
      setFechaEnd(fechas[4]);
      setEstadoFiltros({
        ...estadoFiltros,
        start_date: fechas[0],
        end_date: fechas[4],
      })
      localStorage.setItem("fechaInicio", fechas[0]);
      localStorage.setItem("fechaEnd", fechas[4]);
      cargarData(fechas[0]);

    }
  };

  // definir FEcha
  const setDate = (event) => {
    setFechaEnd(moment(event.endDate).format("YYYY-MM-DD"));
    setFechaStart(moment(event.startDate).format("YYYY-MM-DD"));
  };

  //Ordernar alfabeticamente el array de objetos por categoria - destino comercial
  function compareObjects(a, b) {

    const CuotaOrder = {
      "Hilton": 1,
      "No Hilton": 2,
      "Cuota 481": 3,
      null: 4
    };

    //Primero ordeno por destino
    const destinoComparison = b.destino_Comercial.localeCompare(a.destino_Comercial);
    if (destinoComparison !== 0) {
      return destinoComparison;
    }

    const categoriaComparison = b.categoriaEXP.localeCompare(a.categoriaEXP);
    if (categoriaComparison !== 0) {
      return categoriaComparison;
    }

    //Si las categorías y destinos son iguales, comparar por cuota
    const cuotaAValue = CuotaOrder[a.cuota] || 0;
    const cuotaBValue = CuotaOrder[b.cuota] || 0;
    return cuotaBValue - cuotaAValue;
    //return b.destino_Comercial.localeCompare(a.destino_Comercial);

  }
  // function compareObjects(a, b) {
  //   if (a.categoriaEXP === b.categoriaEXP) {
  //     return b.destino_Comercial.localeCompare(a.destino_Comercial);
  //   } else {
  //     return b.categoriaEXP.localeCompare(a.categoriaEXP);
  //   }
  // }


  const getComprasExcel = async () => {

    let fecha_desde = estadoFiltros.start_date;
    let fecha_hasta = estadoFiltros.end_date;

    const fecha_busqueda = localStorage.getItem("fechaBusqueda");
    if (fecha_busqueda != undefined) {
      fecha_desde = fecha_busqueda;
      fecha_hasta = fecha_busqueda;
    }

    const establecimiento = localStorage.getItem("establecimiento");

    UserService.getListComprasEstadoExcel(
      fecha_desde,
      fecha_hasta,
      estadoFiltros.agrupacion,
      estadoFiltros.consignatario,
      estadoFiltros.remitente,
      estadoFiltros.status,
      estadoFiltros.comprador,
      estadoFiltros.moneda,
      estadoFiltros.especie,
      estadoFiltros.destinoComercial,
      estadoFiltros.tipoCompra,
      estadoFiltros.peso,
      estadoFiltros.cuota,
      estadoFiltros.agruparPorPeso,
      false,
      establecimiento
    ).then((response) => {
      if (response.status == 200) {
        //console.log("aca")
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Detalle Compras.xlsx');
        document.body.appendChild(link);
        link.click();
      }
    })
  }
  const getComprasPdf = async () => {

    let fecha_desde = estadoFiltros.start_date;
    let fecha_hasta = estadoFiltros.end_date;

    const fecha_busqueda = localStorage.getItem("fechaBusqueda");
    if (fecha_busqueda != undefined) {
      fecha_desde = fecha_busqueda;
      fecha_hasta = fecha_busqueda;
    }

    const establecimiento = localStorage.getItem("establecimiento");

    UserService.getListComprasEstadoExcel(
      fecha_desde,
      fecha_hasta,
      estadoFiltros.agrupacion,
      estadoFiltros.consignatario,
      estadoFiltros.remitente,
      estadoFiltros.status,
      estadoFiltros.comprador,
      estadoFiltros.moneda,
      estadoFiltros.especie,
      estadoFiltros.destinoComercial,
      estadoFiltros.tipoCompra,
      estadoFiltros.peso,
      estadoFiltros.cuota,
      estadoFiltros.agruparPorPeso,
      true,
      establecimiento
    ).then((response) => {
      if (response.status == 200) {
        //console.log("aca")
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Detalle Compras.pdf');
        document.body.appendChild(link);
        link.click();
      }
    })
  }

  const getComprasAgrupadasExcel = async () => {
    let fechas = dates(new Date(estadoFiltros.start_date));
    UserService.getListComprasEstadoAgrupadasExcel(
      fechas[0],
      fechas[4],
      estadoFiltros.agrupacion,
      estadoFiltros.consignatario,
      estadoFiltros.remitente,
      estadoFiltros.status,
      estadoFiltros.comprador,
      estadoFiltros.moneda,
      estadoFiltros.especie,
      estadoFiltros.destinoComercial,
      estadoFiltros.tipoCompra,
      estadoFiltros.peso,
      estadoFiltros.cuota,
      estadoFiltros.agruparPorPeso,
      false

    ).then((response) => {
      if (response.status == 200) {
        //console.log("aca")
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Compras Agrupadas.xlsx');
        document.body.appendChild(link);
        link.click();
      }
    })
  }
  const getComprasAgrupadasPdf = async () => {
    UserService.getListComprasEstadoAgrupadasExcel(
      estadoFiltros.start_date,
      estadoFiltros.end_date,
      estadoFiltros.agrupacion,
      estadoFiltros.consignatario,
      estadoFiltros.remitente,
      estadoFiltros.status,
      estadoFiltros.comprador,
      estadoFiltros.moneda,
      estadoFiltros.especie,
      estadoFiltros.destinoComercial,
      estadoFiltros.tipoCompra,
      estadoFiltros.peso,
      estadoFiltros.cuota,
      estadoFiltros.agruparPorPeso,
      true

    ).then((response) => {
      if (response.status == 200) {
        //console.log("aca")
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Compras Agrupadas.pdf');
        document.body.appendChild(link);
        link.click();
      }
    })
  }

  const agruparParaTablaResumen = (data, start) => {
    if (tipoCompra == "Faena") {
      // agrupar datos por semana
      const result = agruparData(data, start);
      // agrupar datos por categoria
      const resultTotCategorias = totalesCategoria(
        data,
        start,
      );

      //agrupar datos UE y TP
      const resultTotCategoriaUETP = totalesCategoriaUEOPaises(
        data,
        start,
      );


      // agrupar datos por espécie
      //const resultTotalesEspecies = TotalesEsp(data, start);
      // totales general
      const totales = Totales(data, start);
      // totales general
      //const totalesUE = DiasJustos(resultTotCategoriaUE, start);
      // totales general
      const totalesUEOP = DiasJustos(resultTotCategoriaUETP, start);
      // dias x  espécie
      //const totalesEsp = DiasJustos(resultTotalesEspecies, start);
      // dias x  Totales
      const totales2 = DiasJustos(totales, start);
      // dias x  semana
      const resultJustos = DiasJustos(result, start);
      // dias x  semana Categorias
      const DiasTotalesCategorias = DiasJustos(
        resultTotCategorias,
        start,
      );
      //Uno por categoria
      const group = DiasTotalesCategorias.reduce((acc, item) => {
        if (!acc[item.categoria]) {
          acc[item.categoria] = [];
        }
        acc[item.categoria].push(item);
        return acc;
      }, {});


      // ordenar alfabeticamente
      resultJustos.sort(compareObjects);

      //sumo filas
      for (const [key, valor] of Object.entries(group)) {
        const element = valor;
        const coincidencia = resultJustos.filter(
          (x) => x.categoriaEXP == key || x.destino_Comercial == key,
        );
        for (let i = 0; i < coincidencia.length; i++) {
          const elementCoincidencia = coincidencia[i];
          element.push(elementCoincidencia);
        }
      }
      // array de filas
      const dataRows = [];
      let j = 0;
      for (const [key, valor] of Object.entries(group)) {
        const arrayConValores = valor.reverse();

        for (let i = 0; i < arrayConValores.length; i++) {
          const element = arrayConValores[i];
          if (element["destino_Comercial"] === "Exportación UE") {
            dataRows.push({
              id: j,
              Categoria: element["categoriaEXP"],
              Destino: element["cuota"],
              Reses: element["cabezasTot"],
              VivoProm: Math.round(element["kilos_totales"] / element["cabezasTot"]),//Math.round(element["peso"] / element["count"]),
              Precio: moneda === 'ARG' ? Math.round(element["precio_total"] / element["kilos_totales"]) : (element["precio_total"] / element["kilos_totales"]).toFixed(2),
              status: [element["status"]],
              8: element["cabezas_compradas"][0],
              9: element["cabezas_compradas"][1],
              10: element["cabezas_compradas"][2],
              11: element["cabezas_compradas"][3],
              12: element["cabezas_compradas"][4],
              tipo: element["tipo"],
            });
            j += 1;
          } else {
            dataRows.push({
              id: j,
              Categoria: element["categoriaEXP"],
              Destino:
                element["destino_Comercial"] == "Exportación TP"
                  ? "Otros Países"
                  : element["destino_Comercial"] ==
                    "Exportacion Manufactura"
                    ? "Conserva"
                    : element["destino_Comercial"],
              Reses: element["cabezasTot"],
              VivoProm: Math.round(element["kilos_totales"] / element["cabezasTot"]),
              Precio: moneda === 'ARG' ? Math.round(element["precio_total"] / element["kilos_totales"]) : (element["precio_total"] / element["kilos_totales"]).toFixed(2),
              status: [element["status"]],
              8: element["cabezas_compradas"][0],
              9: element["cabezas_compradas"][1],
              10: element["cabezas_compradas"][2],
              11: element["cabezas_compradas"][3],
              12: element["cabezas_compradas"][4],
              tipo: element["tipo"],
            });
            j += 1;
          }
        }
      }

      // Insertar subtotal Expo UE + Otros paises debajo del subtotal que corresponde, sino no.
      const otrosPaisesIndex = dataRows.findIndex(
        (obj) => obj.Categoria === "Total Otros Países",
      );
      const exportacionUEIndex = dataRows.findIndex(
        (obj) => obj.Categoria === "Total Exportación UE",
      );

      if (otrosPaisesIndex !== -1) {
        const subtotalUEOP = {};
        const otrosPaisesId = dataRows[otrosPaisesIndex].id;
        subtotalUEOP.id = otrosPaisesId + 1
        for (const [key, element] of Object.entries(totalesUEOP)) {
          subtotalUEOP.Categoria = element["categoriaEXP"];
          subtotalUEOP.Destino = "";
          subtotalUEOP.Reses = element["cabezasTot"];
          subtotalUEOP.VivoProm = Math.round(
            element["kilos_totales"] / element["cabezasTot"],
          );
          subtotalUEOP.Precio = moneda === 'ARG' ? Math.round(element["precio_total"] / element["kilos_totales"]) : (element["precio_total"] / element["kilos_totales"]).toFixed(2);
          subtotalUEOP.status = [element["status"]];
          for (let i = 8; i <= 12; i++) {
            subtotalUEOP[i] = element["cabezas_compradas"][i - 8];
          }
          subtotalUEOP.tipo = element["tipo"];
        }

        if (otrosPaisesIndex !== -1) {
          dataRows.splice(otrosPaisesIndex + 1, 0, subtotalUEOP);

          for (let i = otrosPaisesIndex + 2; i < dataRows.length; i++) {
            dataRows[i].id++;
          }
        } else if (exportacionUEIndex !== -1) {
          dataRows.splice(exportacionUEIndex + 1, 0, subtotalUEOP);

          for (let i = exportacionUEIndex + 2; i < dataRows.length; i++) {
            dataRows[i].id++;
          }
        }
      }

      // insertamos subtotal Expo UE + Otros Paises + Conserva si es que hay conserva, sino no. 
      const conservaIndex = dataRows.findIndex(
        (obj) => obj.Categoria === "Total Conserva",
      );
      if (conservaIndex !== -1) {
        let subtotalExportacion = {};
        const conservaRow = dataRows[conservaIndex];
        subtotalExportacion.Categoria = 'Total Exportación General';
        subtotalExportacion.id = conservaRow.id + 1;
        const totalConserva = { ...group['Exportacion Manufactura'].slice(-1)[0] };
        let kilos_totales = totalConserva.kilos_totales;
        let precio_total = totalConserva.precio_total;
        if (exportacionUEIndex !== -1) {
          const expoRow = dataRows[exportacionUEIndex];
          const totalExpoRow = { ...group['Exportación UE'].slice(-1)[0] };
          kilos_totales += totalExpoRow.kilos_totales;
          precio_total += totalExpoRow.precio_total;
          subtotalExportacion.Destino = '';
          subtotalExportacion.Reses = expoRow.Reses + conservaRow.Reses;
          subtotalExportacion.VivoProm = Math.round((kilos_totales) / subtotalExportacion.Reses);
          subtotalExportacion.Precio = moneda === 'ARG' ? Math.round((precio_total) / (kilos_totales)) : ((precio_total) / (kilos_totales)).toFixed(2);
          subtotalExportacion.status = [expoRow.status];
          for (let i = 8; i <= 12; i++) {
            subtotalExportacion[i] = expoRow[i] + conservaRow[i];
          }
          subtotalExportacion.tipo = expoRow.tipo;
        }
        if (otrosPaisesIndex !== -1) {
          const otrosPaisesRow = dataRows[otrosPaisesIndex];
          const totalOtrosPaises = { ...group['Exportación TP'].slice(-1)[0] };
          kilos_totales += totalOtrosPaises.kilos_totales;
          precio_total += totalOtrosPaises.precio_total;
          if (exportacionUEIndex !== -1) {
            subtotalExportacion.Reses += otrosPaisesRow.Reses;
            for (let i = 8; i <= 12; i++) {
              subtotalExportacion[i] += otrosPaisesRow[i];
            }
          } else {
            subtotalExportacion.Reses = otrosPaisesRow.Reses + conservaRow.Reses;
            subtotalExportacion.status = [otrosPaisesRow.status];
            subtotalExportacion.tipo = otrosPaisesRow.tipo;
            for (let i = 8; i <= 12; i++) {
              subtotalExportacion[i] = otrosPaisesRow[i] + conservaRow[i];
            }
          }
          subtotalExportacion.VivoProm = Math.round((kilos_totales) / subtotalExportacion.Reses);
          subtotalExportacion.Precio = moneda === 'ARG' ? Math.round((precio_total) / (kilos_totales)) : ((precio_total) / (kilos_totales)).toFixed(2);
        }
        dataRows.splice(conservaIndex + 1, 0, subtotalExportacion);
        for (let i = conservaIndex + 2; i < dataRows.length; i++) {
          dataRows[i].id++;
        }
      }

      //agregos Total general
      for (const [key, valor] of Object.entries(totales2)) {
        const len = dataRows.length;
        const element = valor;
        dataRows.push({
          id: len + 1,
          Categoria: element["categoriaEXP"],
          Destino: "",
          Reses: element["cabezasTot"],
          VivoProm: Math.round(element["kilos_totales"] / element["cabezasTot"]),
          Precio: moneda === 'ARG' ? Math.round(element["precio_total"] / element["kilos_totales"]) : (element["precio_total"] / element["kilos_totales"]).toFixed(2),
          status: [element["status"]],
          8: element["cabezas_compradas"][0],
          9: element["cabezas_compradas"][1],
          10: element["cabezas_compradas"][2],
          11: element["cabezas_compradas"][3],
          12: element["cabezas_compradas"][4],
          tipo: element["tipo"],
        });
      }

      // setPageSize(dataRows.length);
      // setDataCompras(dataRows);
      //localStorage.setItem("dataCompras", JSON.stringify(dataRows));
      return dataRows

    } else {
      // !INVERNADA
      const dataInvernada = [];
      // cargar info de semanas o dias
      if (agrup == "semana") {
        data.reduce(function (res, value) {
          if (!res[0]) {
            res[0] = {
              fecha_de_compra: [],
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "totales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0,
            };
            dataInvernada.push(res[0]);
          }
          res[0].count += 1;
          if (res[0].fecha_de_compra.includes(value.fecha_de_compra)) {
            const index = res[0].fecha_de_compra.indexOf(
              value.fecha_de_compra,
            );
            res[0].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[0].fecha_de_compra.push(value.fecha_de_compra);
            res[0].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[0].cabezasTot += parseInt(value.cabezas_compradas);
          res[0].cuota = value.cuota;
          res[0].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[0].peso += parseInt(value.peso);
          res[0].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[0].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

          return res;
        }, {});
      } else {
        data.reduce(function (res, value) {
          if (!res[0]) {
            res[0] = {
              fecha_de_compra: [],
              peso: 0,
              precio: 0,
              cabezas_compradas: [],
              cabezasTot: 0,
              count: 0,
              tipo: "totales",
              status: ["total"],
              precio_total: 0,
              kilos_totales: 0
            };
            dataInvernada.push(res[0]);
          }
          res[0].count += 1;

          if (
            res[0].fecha_de_compra.includes(
              moment(value.fecha_de_compra).startOf("isoWeek").week(),
            )
          ) {
            const index = res[0].fecha_de_compra.indexOf(
              moment(value.fecha_de_compra).startOf("isoWeek").week(),
            );
            res[0].cabezas_compradas[index] += value.cabezas_compradas;
          } else {
            res[0].fecha_de_compra.push(
              moment(value.fecha_de_compra).startOf("isoWeek").week(),
            );
            res[0].cabezas_compradas.push(value.cabezas_compradas);
          }
          res[0].cabezasTot += parseInt(value.cabezas_compradas);
          res[0].cuota = value.cuota;
          res[0].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[0].peso += parseInt(value.peso);
          res[0].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[0].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

          return res;
        }, {});
      }

      const ResultTodos = DiasJustosI(dataInvernada, start);
      const dataRows = [];
      let j = 0;
      for (let i = 0; i < ResultTodos.length; i++) {
        const element = ResultTodos[i];
        dataRows.push({
          id: j,
          Categoria: "Invernada",
          Destino: "Invernada",
          Reses: element["cabezasTot"],
          VivoProm: Math.round(element["kilos_totales"] / element["cabezasTot"]),
          Precio: moneda === 'ARG' ? Math.round(element["precio_total"] / element["kilos_totales"]) : (element["precio_total"] / element["kilos_totales"]).toFixed(2),
          status: [element["status"]],
          8: element["cabezas_compradas"][0],
          9: element["cabezas_compradas"][1],
          10: element["cabezas_compradas"][2],
          11: element["cabezas_compradas"][3],
          12: element["cabezas_compradas"][4],
          tipo: element["tipo"],
        });
        j += 1;
      }
      // setPageSize(dataRows.length);

      // setDataCompras(dataRows);
      // localStorage.setItem("dataCompras", JSON.stringify(dataRows));
      return dataRows;
    }
  }

  function calculateTotalForComprasPropiasTable(data, establecimiento_name, establecimiento_id) {
    let totalGeneral = data.reduce((acc, item) => {
      // if (item.destino === 'Exportación UE' || item.destino === 'Exportación TP') {
      //   acc += item.cantidad;
      // }
      acc.cantidad += parseInt(item.cabezas_compradas);
      acc.precio_total += parseInt(item.precio) * parseInt(item.peso) * parseInt(item.cabezas_compradas);
      acc.kilos_totales += parseInt(item.peso) * parseInt(item.cabezas_compradas);
      acc.porcentaje = 1;
      return acc;
    }, {
      destino: `Total ${establecimiento_name}`,
      cantidad: 0,
      precio_total: 0,
      kilos_totales: 0,
      precio: 0,
      peso: 0,
      porcentaje: 0,
      tipo: 'totales',
      is_total: true
    });
    totalGeneral.precio = parseFloat(totalGeneral.precio_total / totalGeneral.kilos_totales).toFixed(moneda === 'ARG' ? 0 : 2);
    totalGeneral.peso = parseFloat(totalGeneral.kilos_totales / totalGeneral.cantidad).toFixed(0);
    totalGeneral.establecimiento_id = establecimiento_id ? establecimiento_id : null;
    totalGeneral.is_propia = true;
    totalGeneral.porcentaje_expo = 1;
    return totalGeneral;
  }

  function groupComprasByDestinoCuota(comprasPropias, establecimiento_name, total_general, estableciento_id, total_expo) {
    // agrupamos por destino y cuota
    const groupedByDestinoCuotaTemp = [];
    comprasPropias.reduce((acc, item) => {
      const destino = item.destino_Comercial;
      const cuota = (item.cuota !== null && item.cuota !== '') ? item.cuota : ' ';
      if (!acc[destino]) {
        acc[destino] = [];
      }
      if (!acc[destino][cuota]) {
        acc[destino][cuota] = {
          destino: destino,
          cuota: cuota,
          peso: 0,
          precio: 0,
          cantidad: 0,
          precio_total: 0,
          kilos_totales: 0,
          is_propia: true,
          establecimiento_id: estableciento_id,
          tipo: null,
          is_total: false
        }
        groupedByDestinoCuotaTemp.push(acc[destino][cuota])
      }
      acc[destino][cuota].cantidad += parseInt(item.cabezas_compradas);
      acc[destino][cuota].precio_total += parseInt(item.precio) * parseInt(item.peso) * parseInt(item.cabezas_compradas);
      acc[destino][cuota].kilos_totales += parseInt(item.peso) * parseInt(item.cabezas_compradas);
      return acc;
    }, {});

    // calculamos total general por establecimiento (teoricamente solo expo)
    let totalGeneral = calculateTotalForComprasPropiasTable(comprasPropias, establecimiento_name, estableciento_id);

    // agregamos peso y precio promedio, y agregamos porcentaje sobre total exportacion
    let id = 0;
    groupedByDestinoCuotaTemp.forEach((item) => {
      item.peso = parseFloat(item.kilos_totales / item.cantidad).toFixed(0);
      item.precio = parseFloat(item.precio_total / item.kilos_totales).toFixed(moneda === 'ARG' ? 0 : 2);
      item.porcentaje = item.cantidad / totalGeneral.cantidad;
      item.porcentaje_expo = item.cantidad / total_expo;
      item.id = id++;
    });
    totalGeneral.id = id++;

    // usamos total_general para calcular el porcentaje
    totalGeneral.porcentaje = totalGeneral.cantidad / total_general;
    totalGeneral.porcentaje_expo = totalGeneral.cantidad / total_expo;

    groupedByDestinoCuotaTemp.push(totalGeneral);

    return groupedByDestinoCuotaTemp;
  }

  // SACAR data set
  const cargarData = async (start, end) => {
    setEspecie("");
    setDataCompras([]);
    setDatosTabla([]);
    setIsLoading2(true);
    setErrorServidor(false);
    setGroupedByDestinoCuota([]);
    //borro estados iniciales
    localStorage.removeItem("fechaInicio");
    localStorage.removeItem("fechaEnd");
    localStorage.removeItem("dataCompras");
    setdataTables([]);
    localStorage.removeItem("datatableCompras");
    localStorage.removeItem("DataFaenaAño");

    localStorage.removeItem("RowDetalle");

    // localStorage.setItem("fechaInicio", start);
    // localStorage.setItem("fechaEnd", end);

    //console.log(start)

    let fechas = dates(new Date(start));
    let Nsemana = moment(start).startOf("isoWeek").week();
    let Nyear = moment(start).year();

    setFechaStart(fechas[0]);
    setFechaEnd(fechas[4]);
    setEstadoFiltros({
      ...estadoFiltros,
      start_date: fechas[0],
      end_date: fechas[4],
    })

    localStorage.setItem("fechaInicio", fechas[0]);
    localStorage.setItem("fechaEnd", fechas[4]);

    //console.log(fechaStart)

    const principioSemana = moment(fechas[0]).format("DD/MM");
    const finalSemana = moment(fechas[4]).format("DD/MM");

    //get first and last day of month based on fechas[0]
    const firstDay = moment(fechas[0]).startOf("month").format("YYYY-MM-DD");
    const lastDay = moment(fechas[0]).endOf("month").format("YYYY-MM-DD");

    const fechaBusquedaInicio = agrup == "semana" ? fechas[0] : firstDay;
    const fechaBusquedaFin = agrup == "semana" ? fechas[4] : lastDay;

    let NMonth = moment(fechas[0]).month() + 1;

    //console.log(NMonth)

    setSearchedMonth(NMonth);
    //console.log(searchedMonth)

    //busco los indicadores
    let indicadores = await UserService.getIndicadores(
      Nsemana,
      Nyear,
      agrup,
      NMonth,
    );
    setIndicadoresIniciales(indicadores.data);

    UserService.getListComprasEstado(
      fechaBusquedaInicio,
      fechaBusquedaFin,
      agrup,
      consignatario,
      remitente,
      filtroStatus,
      filtroComprador,
      moneda,
      especie,
      destinoComercial,
      tipoCompra,
      "",
      "",
      agruparPorPeso,
    ).then(
      (response) => {
        if (response.status == 200) {
          setErrorServidor(false);
          setIsLoading2(false);
          //setFechaStart(fechas[0])
          //setFechaEnd(fechas[4])
          // setDataFaenaAño(response.data.dataFaenaMes);
          // localStorage.setItem(
          //   "DataFaenaAño",
          //   JSON.stringify(response.data.dataFaenaMes),
          // );

          //console.log(response.data)
          const Nsemana = moment(start).startOf("isoWeek").week();
          const mes = moment(start).month();
          const year = moment(start).year();
          if (agrup == "semana") {
            setDetalleBusqueda(
              "Semana " +
              Nsemana +
              " | " +
              principioSemana +
              " - " +
              finalSemana,
            );
          } else {
            setDetalleBusqueda("Mes " + (mes + 1) + " - " + year);
          }

          // loop over response.data.data and get the distint estableciento_id and establecimiento_name values
          const distinctEstablecimientos = response.data.data.reduce((acc, item) => {
            if (!acc[item.establecimiento_id]) {
              acc[item.establecimiento_id] = item.establecimiento_name;
            }
            return acc;
          }, {});

          // group data by establecimiento_id
          const groupedDataByEstablecimiento = response.data.data.reduce((acc, item) => {
            if (!acc.has(item.establecimiento_id)) {
              acc.set(item.establecimiento_id, []);
            }
            acc.get(item.establecimiento_id).push(item);
            return acc;
          }, new Map());

          // seteo compras para tabla resumen faena propia
          const gropedComprasPropiasByDestinoCuota = [];
          const totalGeneralGroupedComprasPropias = calculateTotalForComprasPropiasTable(response.data.data.filter((item) => item.is_propia === true), 'General', null);
          let subtotalesEst = 0;
          for (const [key, value] of groupedDataByEstablecimiento) {
            const total_expo = value.reduce((acc, item) => {
              if (item.destino_Comercial === 'Exportación UE' || item.destino_Comercial === 'Exportación TP') {
                acc += item.cabezas_compradas;
              }
              return acc;
            }, 0);
            const comprasPropias = value.filter((item) => item.is_propia === true);
            if (comprasPropias.length > 0) {
              const data = groupComprasByDestinoCuota(comprasPropias, distinctEstablecimientos[key], totalGeneralGroupedComprasPropias.cantidad, key, total_expo);
              gropedComprasPropiasByDestinoCuota.push(...data);
              subtotalesEst += 1;
            }
          }
          // agregamos totalGeneralGroupedComprasPropias al final
          if (subtotalesEst > 1) {
            totalGeneralGroupedComprasPropias.tipo = 'total_general';
            const total_expo = response.data.data.filter((item) => (item.destino_Comercial === 'Exportación UE' || item.destino_Comercial === 'Exportación TP')).reduce((acc, item) => {
              acc += item.cabezas_compradas;
              return acc;
            }, 0);
            totalGeneralGroupedComprasPropias.porcentaje_expo = totalGeneralGroupedComprasPropias.cantidad / total_expo;
            gropedComprasPropiasByDestinoCuota.push(totalGeneralGroupedComprasPropias);
          }
          gropedComprasPropiasByDestinoCuota.forEach((item, index) => {
            item.id = index;
          });
          setGroupedByDestinoCuota(gropedComprasPropiasByDestinoCuota);

          // obtengo info tabla indicadores
          const detalle = agruparDataDetalle(response.data?.data?.filter((x) => parseInt(x.establecimiento_id) === EST_FR));
          // Seteo info tabla indicadores
          setRowDetalle(detalle);
          localStorage.setItem("RowDetalle", JSON.stringify(detalle));


          // cargar info de semanas o dias
          const infoTabla = sumarInfoTabla(start, end);
          setDatosTabla(infoTabla);

          // agrupar datos para tabla resumen por establecimiento
          let totalRows = [];
          for (const [key, value] of groupedDataByEstablecimiento) {
            let dataRows = agruparParaTablaResumen(value, start);
            // change the Total Compras row to the name of the establecimiento
            if (dataRows.length > 0) {
              dataRows[dataRows.length - 1].Categoria = `Total ${distinctEstablecimientos[key]}`;
            }
            //agregamos establecimiento id a cada fila
            dataRows.forEach((row) => {
              row.establecimiento_id = key;
            });
            totalRows = totalRows.concat(dataRows);
          }
          let totalGeneral = Totales(response.data.data, start);
          let totalGeneral2 = DiasJustos(totalGeneral, start);
          for (const [key, valor] of Object.entries(totalGeneral2)) {
            const len = totalRows.length;
            const element = valor;
            totalRows.push({
              id: len + 1,
              Categoria: "Total Compras",
              Destino: "",
              Reses: element["cabezasTot"],
              VivoProm: Math.round(element["kilos_totales"] / element["cabezasTot"]),
              Precio: moneda === 'ARG' ? Math.round(element["precio_total"] / element["kilos_totales"]) : (element["precio_total"] / element["kilos_totales"]).toFixed(2),
              status: [element["status"]],
              8: element["cabezas_compradas"][0],
              9: element["cabezas_compradas"][1],
              10: element["cabezas_compradas"][2],
              11: element["cabezas_compradas"][3],
              12: element["cabezas_compradas"][4],
              tipo: 'total_general',
              establecimiento_id: null
            });
          }

          // actualizamos el id de forma incremental
          totalRows.forEach((row, index) => {
            row.id = index;
          });

          setPageSize(totalRows.length);
          setDataCompras(totalRows);

        }
      },
      (error) => {
        const Nsemana = moment(start).startOf("isoWeek").week();
        const mes = moment(start).month();
        const year = moment(start).year();
        if (agrup == "semana") {
          setDetalleBusqueda(
            "Semana " + Nsemana + " | " + principioSemana + " - " + finalSemana,
          );
        } else {
          setDetalleBusqueda("Mes " + (mes + 1) + " - " + year);
        }
        setErrorServidor(true);
        setIsLoading2(false);
        setRowDetalle("");
        //setFechaStart(fechas[0])
        //setFechaEnd(fechas[4])
      },
    );
    // indicadores de faena
    UserService.getFaenaStats(
      NMonth,
      Nyear
    ).then((response) => {
      if (response.status === 200) {
        setDataFaenaAño(response.data.dataFaenaMes);
        localStorage.setItem("DataFaenaAño", JSON.stringify(response.data.dataFaenaMes),);
      }
    })

  };


  const dataTableDetalleByPropias = async (start, end, row) => {
    setdataTables([]);
    const establecimiento = row.establecimiento_id;
    const dest = row.is_total === true ? "" : row.destino;
    const cuota = row.cuota ? row.cuota : "";
    const is_propia = true;
    const esp = "";
    const peso = "";
    UserService.getListComprasEstado(
      start,
      end,
      agrup,
      consignatario,
      remitente,
      filtroStatus,
      filtroComprador,
      moneda,
      esp,
      dest,
      tipoCompra,
      peso,
      cuota,
      agruparPorPeso,
      establecimiento,
      is_propia
    ).then((response) => {
      if (response.status == 200) {
        setMostrarTablaDetalle(true);
        // loop over response.data.data and get the distint estableciento_id and establecimiento_name values
        const distinctEstablecimientos = response.data.data.reduce((acc, item) => {
          if (!acc[item.establecimiento_id]) {
            acc[item.establecimiento_id] = item.establecimiento_name;
          }
          return acc;
        }, {});

        // group data by establecimiento_id
        const groupedDataByEstablecimiento = response.data.data.reduce((acc, item) => {
          if (!acc.has(item.establecimiento_id)) {
            acc.set(item.establecimiento_id, []);
          }
          acc.get(item.establecimiento_id).push(item);
          return acc;
        }, new Map());

        let dataRows = [];
        for (const [key, value] of groupedDataByEstablecimiento) {
          const establecimiento_name = distinctEstablecimientos[key];
          let sub = agruparParaTablaDetalle(value, establecimiento_name);
          dataRows = dataRows.concat(sub);
        }

        setdataTables(dataRows);
        localStorage.removeItem("datatableCompras");
        localStorage.setItem("datatableCompras", JSON.stringify(dataRows));
      }
    });
  }

  // saco info para tabla detalle
  const dataTablaDetalle = async (fechaInicial, fechaFinal, info, ls) => {
    setdataTables([]);
    //console.log(fechaInicial, fechaFinal);
    setDestinoComercial("");
    setEspecie("");
    let esp = "";
    let peso = "";
    let dest = "";
    let cuota = "";
    let establecimiento = null;
    if (info) {
      localStorage.removeItem("raza");


      localStorage.setItem("raza", ls ? ls.Categoria : info.row.Categoria);

      if (!ls) {
        localStorage.removeItem("params");
        localStorage.setItem("params", JSON.stringify(info.row));
      }

      dest = ls ? ls.Destino : info.row.Destino;
      //if (info.row.Destino == null) { dest = "Exportación UE" }
      const datosRaza = ls ? ls.Categoria : info.row.Categoria;
      const datosCategoria = ls ? ls.Destino : info.row.Destino;
      establecimiento = ls ? ls.establecimiento_id : info.row.establecimiento_id;
      //console.log(info.row)

      // falta el cambio de nombre de conserva a "no se" y el caso cuando estamos hablando de totales que viene como string el valor "info". deberia hacer un split
      if (datosRaza.trimEnd() === "Total Exportacion UE + Otros Paises") {
        dest = "UEOP";
        setDestinoComercial("UEOP");
      } else if (datosRaza.trimEnd() === "Total Exportación General") {
        dest = "UEOPCO";
        setDestinoComercial("UEOPCO");
      } else if (
        datosCategoria == "Hilton" ||
        datosRaza.split(" ").includes("UE")
      ) {
        dest = "Exportación UE";
        if (datosCategoria !== undefined) {
          cuota = datosCategoria;
        }
        setDestinoComercial("Exportación UE");
      } else if (datosCategoria == "No Hilton") {
        dest = "Exportación UE";
        if (datosCategoria !== undefined) {
          cuota = datosCategoria;
        }
        setDestinoComercial("Exportación UE");
      } else if (datosCategoria == "Cuota 481") {
        dest = "Exportación UE";
        if (datosCategoria !== undefined) {
          cuota = datosCategoria;
        }
        setDestinoComercial("Exportación UE");
      } else if (
        datosCategoria == "Conserva" ||
        datosRaza.split(" ").includes("Conserva")
      ) {
        dest = "Exportacion Manufactura";
        setDestinoComercial("Exportacion Manufactura");
      } else if (
        datosCategoria == "Otros Países" ||
        datosRaza.split(" ").includes("Países")
      ) {
        dest = "Exportación TP";
        setDestinoComercial("Exportación TP");
      } else if (
        datosCategoria == "Consumo" ||
        datosRaza.split(" ").includes("Consumo")
      ) {
        dest = "Consumo";
        setDestinoComercial("Consumo");
      } else if (
        datosCategoria == "Invernada" ||
        datosRaza.split(" ").includes("Invernada")
      ) {
        dest = "";
        setDestinoComercial("");
      } else if (
        datosCategoria == "" &&
        !datosRaza.split(" ").includes("Total")
      ) {
        dest = "Exportación UE";
        setDestinoComercial("Exportación UE");
      } else {
        setDestinoComercial(datosCategoria);
      }

      if (
        datosRaza.includes("Novillo") ||
        datosRaza.split(" ").includes("NO")
      ) {
        setEspecie("NO");
        esp = "NO";
        if (datosRaza.includes("<510Kg")) {
          peso = 509;
        } else if (datosRaza.includes(">510Kg")) {
          peso = 510;
        }
      } else if (
        datosRaza.includes("Vaca") ||
        datosRaza.split(" ").includes("VA")
      ) {
        setEspecie("VA");
        esp = "VA";
      } else if (
        datosRaza.includes("Vaca-hembra") ||
        datosRaza.split(" ").includes("VQ")
      ) {
        setEspecie("VA");
        esp = "VA";
      } else if (
        datosRaza.includes("Macho") ||
        datosRaza.split(" ").includes("MEJ")
      ) {
        setEspecie("MEJ");
        esp = "MEJ";
      } else if (
        datosRaza.includes("Vaquillona") ||
        datosRaza.split(" ").includes("VQ")
      ) {
        setEspecie("VQ");
        esp = "VQ";
      } else if (
        datosRaza.includes("Toro") ||
        datosRaza.split(" ").includes("TOR")
      ) {
        setEspecie("TOR");
        esp = "TOR";
      } else if (
        datosRaza.includes("Invernada") ||
        datosRaza.split(" ").includes("Invernada")
      ) {
        setEspecie("");
      } else if (
        datosRaza.includes("Novillito") ||
        datosRaza.split(" ").includes("NT")
      ) {
        setEspecie("NT");
        esp = "NT";
      }
    }

    localStorage.setItem("establecimiento", establecimiento);
    // setear filtros
    setEstadoFiltros({
      ...estadoFiltros,
      start_date: fechaInicial,
      end_date: fechaFinal,
      agrupacion: agrup,
      consignatario: consignatario,
      remitente: remitente,
      status: filtroStatus,
      comprador: filtroComprador,
      moneda: moneda,
      especie: esp,
      destinoComercial: dest,
      tipoCompra: tipoCompra,
      peso: peso,
      cuota: cuota,
      agruparPorPeso: agruparPorPeso,
    })


    UserService.getListComprasEstado(
      fechaInicial,
      fechaFinal,
      agrup,
      consignatario,
      remitente,
      filtroStatus,
      filtroComprador,
      moneda,
      esp,
      dest,
      tipoCompra,
      peso,
      cuota,
      agruparPorPeso,
      establecimiento
    ).then((response) => {
      if (response.status == 200) {
        setMostrarTablaDetalle(true);
        // loop over response.data.data and get the distint estableciento_id and establecimiento_name values
        const distinctEstablecimientos = response.data.data.reduce((acc, item) => {
          if (!acc[item.establecimiento_id]) {
            acc[item.establecimiento_id] = item.establecimiento_name;
          }
          return acc;
        }, {});

        // group data by establecimiento_id
        const groupedDataByEstablecimiento = response.data.data.reduce((acc, item) => {
          if (!acc.has(item.establecimiento_id)) {
            acc.set(item.establecimiento_id, []);
          }
          acc.get(item.establecimiento_id).push(item);
          return acc;
        }, new Map());

        let dataRows = [];
        for (const [key, value] of groupedDataByEstablecimiento) {
          const establecimiento_name = distinctEstablecimientos[key];
          let sub = agruparParaTablaDetalle(value, establecimiento_name);
          dataRows = dataRows.concat(sub);
        }

        setdataTables(dataRows);
        localStorage.removeItem("datatableCompras");
        localStorage.setItem("datatableCompras", JSON.stringify(dataRows));
        setDestinoComercial("");
        setEspecie("");
      }
    });
  };

  const agruparParaTablaDetalle = (data, estableciento_id) => {
    const retultTotales = [];
    const resultSubtotales = [];
    if (tipoCompra == "Faena") {
      data.reduce(function (res, value) {
        var key = value.destino_Comercial;
        if (!res[key]) {
          // TOTALES DE LA TABLA DETALLE
          res[key] = {
            id: undefined,
            remitente:
              value.destino_Comercial == "Exportación TP"
                ? "Otros Paises"
                : value.destino_Comercial == "Exportación UE"
                  ? "Union Europea"
                  : value.destino_Comercial,
            destino_Comercial: value.destino_Comercial,
            cuota: 0,
            peso: 0,
            precio: 0,
            cabezas_compradas: 0,
            count: 0,
            tipo: "totales",
            precio_total: 0,
            kilos_totales: 0,
          };
          retultTotales.push(res[key]);
        }
        res[key].count += 1;
        res[key].cabezas_compradas += parseInt(value.cabezas_compradas);
        res[key].cuota = ""; //value.cuota
        res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
        res[key].peso += parseInt(value.peso);
        res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
        res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

        return res;
      }, {});

      // calcular subtotales para Novillo y Novillito Exportacion
      data.reduce(function (res, value) {
        if (value.destino_Comercial == "Exportación UE") {
          var key = value.especie;
          if (!res[key]) {
            res[key] = {
              id: undefined,
              remitente: "SubtotalUE " + value.especie,
              destino_Comercial: "Exportación UE",
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: 0,
              count: 0,
              tipo: "subtotales",
              precio_total: 0,
              kilos_totales: 0,
            };
            resultSubtotales.push(res[key]);
          }
          res[key].count += 1;
          res[key].cabezas_compradas += parseInt(value.cabezas_compradas);
          res[key].cuota = ""; //value.cuota
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

          return res;
        }
      }, {})

      // Calcular los promedios y redondear a cero decimales
      retultTotales.forEach(function (item) {
        item.precio = moneda === 'ARG' ? Math.round(item.precio_total / item.kilos_totales) : (item.precio_total / item.kilos_totales).toFixed(2);
        item.peso = Math.round(item.kilos_totales / item.cabezas_compradas);
      });
      resultSubtotales.forEach(function (item) {
        item.precio = moneda === 'ARG' ? Math.round(item.precio_total / item.kilos_totales) : (item.precio_total / item.kilos_totales).toFixed(2);
        item.peso = Math.round(item.kilos_totales / item.cabezas_compradas);
      });
    }

    const group = data.reduce((acc, item) => {
      if (!acc[item.destino_Comercial]) {
        acc[item.destino_Comercial] = [];
      }

      acc[item.destino_Comercial].push(item);
      return acc;
    }, {});

    for (const [key, valor] of Object.entries(group)) {
      if (key == "Exportación UE") {
        const element = valor;
        let previousEspecie = null;
        for (let i = 0; i < element.length; i++) {
          const currentEspecie = element[i].especie;
          if (currentEspecie !== previousEspecie) {
            const subtotal = resultSubtotales.find((s) => s.remitente === "SubtotalUE " + previousEspecie);
            if (subtotal) {
              element.splice(i, 0, subtotal);
            }
            previousEspecie = currentEspecie;
          }
          if (i === element.length - 1) {
            const subtotal = resultSubtotales.find((s) => s.remitente === "SubtotalUE " + currentEspecie);
            if (subtotal) {
              element.splice(i + 1, 0, subtotal);
            }
            break;
          }
        }


      }
    }

    const dataRows = [];
    for (const [key, valor] of Object.entries(group)) {
      const element = valor;
      const coincidencia = retultTotales.filter(
        (x) => x.destino_Comercial == key,
      );
      for (let i = 0; i < coincidencia.length; i++) {
        const elementCoincidencia = coincidencia[i];
        element.push(elementCoincidencia);
      }
    }
    for (const [key, valor] of Object.entries(group)) {
      const element = valor;
      for (let i = 0; i < element.length; i++) {
        dataRows.push(element[i]);
      }
    }

    // CALCULO TOTAL GENERAL
    let totalGeneral = [];
    data.reduce(function (res, value) {
      var key = 'total_general';
      if (!res[key]) {
        // TOTALES DE LA TABLA DETALLE
        res[key] = {
          id: undefined,
          remitente: `Total ${estableciento_id}`,
          destino_Comercial: value.destino_Comercial,
          cuota: 0,
          peso: 0,
          precio: 0,
          cabezas_compradas: 0,
          count: 0,
          tipo: "total_general",
          precio_total: 0,
          kilos_totales: 0,
        };
        totalGeneral.push(res[key]);
      }
      res[key].count += 1;
      res[key].cabezas_compradas += parseInt(value.cabezas_compradas);
      res[key].cuota = ""; //value.cuota
      res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
      res[key].peso += parseInt(value.peso);
      res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
      res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);

      return res;
    }, {});

    totalGeneral.forEach(function (item) {
      item.precio = moneda === 'ARG' ? Math.round(item.precio_total / item.kilos_totales) : (item.precio_total / item.kilos_totales).toFixed(2);
      item.peso = Math.round(item.kilos_totales / item.cabezas_compradas);
    });

    dataRows.push(totalGeneral[0]);

    return dataRows;
    //scrollToTableDetalle();
  }

  // funcion para semana

  const dataTablaDetalleFECHA = async (fechaInicial, fechaFinal, info) => {
    setdataTables([]);

    const fechaF = moment(fechaInicial, "YYYY-MM-DD")
      .add(7, "days")
      .format("YYYY-MM-DD");

    UserService.getListComprasEstado(
      fechaInicial,
      fechaF,
      "semana",
      consignatario,
      remitente,
      filtroStatus,
      filtroComprador,
      moneda,
      especie,
      destinoComercial,
      tipoCompra,
      "",
      "",
      agruparPorPeso,
    ).then((response) => {
      if (response.status == 200) {
        setMostrarTablaDetalle(true);
        const retultTotales = [];
        if (tipoCompra == "Faena") {
          response.data.data.reduce(function (res, value) {
            var key = value.destino_Comercial;
            if (!res[key]) {
              res[key] = {
                id: undefined,
                tipo_de_compra: "Total " + value.destino_Comercial,
                destino_Comercial: value.destino_Comercial,
                cuota: 0,
                peso: 0,
                precio: 0,
                cabezas_compradas: 0,
                count: 0,
                tipo: "totales",
                precio_total: 0,
                kilos_totales: 0,
              };
              retultTotales.push(res[key]);
            }
            res[key].count += 1;
            res[key].cabezas_compradas += parseInt(value.cabezas_compradas);
            res[key].cuota = value.cuota;
            res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
            res[key].peso += parseInt(value.peso);
            res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
            res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);


            return res;
          }, {});
        }

        const group = response.data.data.reduce((acc, item) => {
          if (!acc[item.destino_Comercial]) {
            acc[item.destino_Comercial] = [];
          }

          acc[item.destino_Comercial].push(item);
          return acc;
        }, {});

        const dataRows = [];
        for (const [key, valor] of Object.entries(group)) {
          const element = valor;
          const coincidencia = retultTotales.filter(
            (x) => x.destino_Comercial == key,
          );
          for (let i = 0; i < coincidencia.length; i++) {
            const elementCoincidencia = coincidencia[i];
            element.push(elementCoincidencia);
          }
        }
        for (const [key, valor] of Object.entries(group)) {
          const element = valor;
          for (let i = 0; i < element.length; i++) {
            dataRows.push(element[i]);
          }
        }
        setdataTables(dataRows);
        localStorage.removeItem("datatableCompras");
        localStorage.setItem("datatableCompras", JSON.stringify(dataRows));
        setDestinoComercial("");
        scrollToTableDetalle();
      }
    });
  };
  // funcion para sacar info de listado de compras nuevamente
  const listCompras = () => {
    setdataTables([]);
    UserService.getListComprasEstado(
      fechaStart,
      fechaEnd,
      agrup,
      consignatario,
      remitente,
      filtroStatus,
      filtroComprador,
      moneda,
      especie,
      destinoComercial,
      tipoCompra,
      "",
      "",
      agruparPorPeso,
    ).then((response) => {
      if (response.status == 200) {
        const retultTotales = [];
        response.data.data.reduce(function (res, value) {
          var key = value.destino_Comercial;
          if (!res[key]) {
            res[key] = {
              id: undefined,
              tipo_de_compra: "Total " + value.destino_Comercial,
              destino_Comercial: value.destino_Comercial,
              cuota: 0,
              peso: 0,
              precio: 0,
              cabezas_compradas: 0,
              count: 0,
              tipo: "totales",
              precio_total: 0,
              kilos_totales: 0,
            };
            retultTotales.push(res[key]);
          }
          res[key].count += 1;
          res[key].cabezas_compradas += parseInt(value.cabezas_compradas);
          res[key].cuota = value.cuota;
          res[key].precio += moneda === 'ARG' ? parseInt(value.precio) : value.precio;
          res[key].peso += parseInt(value.peso);
          res[key].precio_total += moneda === 'ARG' ? parseInt(value.precio) * parseInt(value.peso) * parseInt(value.cabezas_compradas) : value.precio * parseInt(value.peso) * parseInt(value.cabezas_compradas);
          res[key].kilos_totales += parseInt(value.peso) * parseInt(value.cabezas_compradas);


          // Calculo de Promedios
          // res[key].precio /= res[key].count;
          // res[key].peso /= res[key].count;

          return res;
        }, {});
        const group = response.data.data.reduce((acc, item) => {
          if (!acc[item.destino_Comercial]) {
            acc[item.destino_Comercial] = [];
          }

          acc[item.destino_Comercial].push(item);
          return acc;
        }, {});

        const dataRows = [];
        for (const [key, valor] of Object.entries(group)) {
          const element = valor;
          const coincidencia = retultTotales.filter(
            (x) => x.destino_Comercial == key,
          );
          for (let i = 0; i < coincidencia.length; i++) {
            const elementCoincidencia = coincidencia[i];
            element.push(elementCoincidencia);
          }
        }
        for (const [key, valor] of Object.entries(group)) {
          const element = valor;
          for (let i = 0; i < element.length; i++) {
            dataRows.push(element[i]);
          }
        }

        setdataTables(dataRows);
        localStorage.removeItem("datatableCompras");
        localStorage.setItem("datatableCompras", JSON.stringify(dataRows));
      }
    });
  };

  //status de compra
  const setStatus = (e, id) => {
    UserService.putStatusCompra(id, e.target.value, user.id);
  };

  // Filtro button alligment
  const handleAlignment = (e, i) => {
    if (e.target.value !== null) {
      let temp = alignment.map((i) => i);
      temp[i] = e.target.value;
      setAlignment(temp);
      //console.log(temp)
    }
  };

  // Filtro comprador
  const handlefiltroComprador = (event) => {
    const {
      target: { value },
    } = event;
    const valor = value.filter((x) => x != "");
    setFiltroComprador(typeof valor === "string" ? valor.split(",") : valor);
    localStorage.removeItem("comprador");
    localStorage.setItem(
      "comprador",
      typeof valor === "string" ? value.split(",") : valor,
    );
  };

  // Filtro status
  const handlefiltroStatus = (event) => {
    const {
      target: { value },
    } = event;
    const valor = value.filter((x) => x != "");
    setFiltroStatus(typeof valor === "string" ? value.split(",") : valor);
    localStorage.removeItem("status");
    localStorage.setItem(
      "status",
      typeof valor === "string" ? value.split(",") : valor,
    );
  };

  const sendEmail = async (dataIndex, dataGeneral) => {
    setEmailEnviados(true);

    // saco todos los index de la informacion
    let dataIndices = dataIndex.data;
    let dataMails = [];
    for (let i = 0; i < dataIndices.length; i++) {
      const index = dataIndices[i].index;
      //let idcompra = dataGeneral[index].data[0].props.children
      let idcompra = dataGeneral[index].data[dataGeneral[index].data.length - 2];

      /*     let consig = dataGeneral[index].data[4].props.children.props.children
          let remit = dataGeneral[index].data[5].props.children.props.children
     */
      dataMails.push([idcompra]);
    }
    // UserService.emailSend(dataMails, switchEmail, mensajeEmail)
    // setopenConfirmMail(false)
    // setSwitchEmail(false)
    // setMensajeEmail('')
    // setMensaje('')
    try {
      const responses = await UserService.emailSend(
        dataMails,
        switchEmail,
        mensajeEmail,
        incluyeBotones,
      );
      const respuestas = [];
      responses.forEach((respuesta) => {
        respuestas.push([
          respuesta.data.idCompra,
          respuesta.status,
          respuesta.data.email,
          respuesta.data.message,
        ]);
      });
      respuestas.sort((a, b) => a[0] - b[0]);
      setEmailResponses(respuestas);
    } catch (error) {
      console.error(error);
    } finally {
      setMostrandoRespuestasEmail(true);
      //setopenConfirmMail(false);
      setSwitchEmail(false);
      setMensajeEmail("");
      setMensaje("");
    }
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : color;

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : color;

  const updateSelectableRowsHideCheckboxes = (event) => {
    setSelectableRowsHideCheckboxes(event.target.checked);
  };

  //header tabla
  const DataGridTitle = () => {
    return (
      <Grid
        className={classes.containerBACK}
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Grid container alignItems="center" lg={9} md={12} sm={12} xs={12}>
          <Grid
            className={classes.item}
            item
            alignContent="center"
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <Button
              size="small"
              variant="text"
              color={theme.palette.tables.headerText}
              style={{
                color: theme.palette.tables.headerText,
                backgroundColor: theme.palette.tables.header,
                "& .MuiSvgIcon-root": {
                  color: theme.palette.tables.headerText + " !important",
                },
              }}
              onClick={() => {
                setMostrarTablaDetalle(false);
                if (agrup == "semana") {
                  cargarData(
                    moment(fechaStart, "YYYY-MM-DD")
                      .add(-7, "days")
                      .format("YYYY-MM-DD"),
                  );
                } else {
                  cargarData(
                    moment(fechaStart, "YYYY-MM-DD")
                      .add(-1, "month")
                      .format("YYYY-MM-DD"),
                  );
                }
              }}
            >
              <IconButton size="small" color="primary">
                <ArrowBackIosIcon
                  filled
                  fontSize="small"
                  sx={{ color: theme.palette.tables.headerText }}
                />
              </IconButton>
            </Button>
          </Grid>
          <Grid
            className={classes.item}
            alignItems="center"
            item
            lg={8}
            md={8}
            sm={8}
            xs={8}
          >
            <Typography
              align="center"
              style={{ color: theme.palette.tables.headerText }}
            >
              {detalleBusqueda != "" ? detalleBusqueda : "Semana "}
            </Typography>
          </Grid>
          <Grid className={classes.item} item lg={1} md={1} sm={1} xs={1}>
            <Button
              size="small"
              variant="text"
              color="primary"
              style={{
                color: theme.palette.tables.headerText,
                backgroundColor: theme.palette.tables.header,
              }}
              onClick={() => {
                setMostrarTablaDetalle(false);
                if (agrup == "semana") {
                  cargarData(
                    moment(fechaStart, "YYYY-MM-DD")
                      .add(7, "days")
                      .format("YYYY-MM-DD"),
                  );
                } else {
                  cargarData(
                    moment(fechaStart, "YYYY-MM-DD")
                      .add(1, "month")
                      .format("YYYY-MM-DD"),
                  );
                }
              }}
            >
              <IconButton size="small">
                <ArrowForwardIosIcon
                  fontSize="small"
                  sx={{ color: theme.palette.tables.headerText }}
                />
              </IconButton>
            </Button>
          </Grid>
          {isSmallScreen && (
            <Grid item sm={2} xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormGroup>
                <FormControlLabel control={
                  <Tooltip title={`Mostrar ${agrup}`}>
                    <Switch style={{ color: theme.palette.primary.main }} size="small" checked={showDays} onChange={() => { setShowDays(!showDays) }} />
                  </Tooltip>
                } />
              </FormGroup>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          className={classes.cabezas}
          alignItems="center"
          lg={1}
          md={0}
          sm={0}
          xs={0}
        >
          <Typography style={{ color: theme.palette.tables.headerText }}>
            Cabezas
          </Typography>



        </Grid>
        {(user.permissions.compras_comprasAgrup_can_download && !isSmallScreen) && (
          <Grid
            container
            className={classes.cabezas}
            alignItems="center"
            style={{ justifyContent: 'flex-end', display: 'flex' }}
            lg={2}
            md={0}
            sm={0}
            xs={0}
          >
            <Tooltip title="Descargar a excel">
              <IconButton>
                <CloudDownloadIcon
                  onClick={getComprasAgrupadasExcel} //handleExport
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="small"
                ></CloudDownloadIcon>
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Descargar a pdf">
            <IconButton>
              <PrintIcon
                onClick={getComprasAgrupadasPdf} //handleExport
                sx={{ color: theme.palette.primary.main }}
                fontSize="small"
              ></PrintIcon>
            </IconButton>
        </Tooltip> */}
          </Grid>
        )}
      </Grid>
    );
  };

  const columns = [
    {
      field: 1,
      field: "Categoria",
      headerName: "Categoria",
      flex: 2,
      minWidth: 120,
      colSpan: ({ row }) => {
        if (row.tipo === "totales" || row.tipo === "subtotales" || row.tipo === 'total_general' || row.tipo === 'subtotalesUE') {
          return 2;
        }
      },
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      // fechas en tabla REsumen
      renderCell: (params) => (
        <Link
          className={classes.customLink}
          replace
          onClick={() => {
            if (agrup === "semana") {
              setMostrarFechaFaena(true);
              localStorage.removeItem("fechaBusqueda");
              dataTablaDetalle(fechaStart, fechaEnd, params);
              let fechaInicioSemana = localStorage.getItem("fechaInicio");
              cargarData(fechaInicioSemana);
            } else {
              const start = localStorage.getItem("fechaInicio");
              const end = moment(start, "YYYY-MM-DD").add(1, "month").format("YYYY-MM-DD");
              setMostrarFechaFaena(true);
              dataTablaDetalle(start, end, params);
            }

          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "Destino",
      headerName: "Destino",
      flex: 1,
      minWidth: 70,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
    },
    {
      field: "Reses",
      headerName: "Reses",
      flex: 1,
      minWidth: 70,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      align: "right",
    },
    {
      field: "VivoProm",
      headerName: "Kg/Cab",
      flex: 1,
      minWidth: 70,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      align: "right",
    },
    {
      field: "Precio",
      headerName:
        tipoCompra === "Faena" && moneda === "ARG"
          ? "$/KgG"
          : tipoCompra === "Faena" && moneda !== "ARG"
            ? "U$D/KgG"
            : tipoCompra === "Invernada" && moneda === "ARG"
              ? "$/KgV"
              : "U$D/KgV",
      flex: 1,
      minWidth: 70,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      hide: !user.permissions.compras_prices_can_view,
      align: "right",
      renderCell: ({ row }) => (
        <div>{currencyFormatter.format(row.Precio)}</div>
      )
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
      minWidth: 70,
      hide: true,
    },
    {
      field: "tipo",
      headerName: "totales",
      flex: 4,
      minWidth: 70,
      hide: true,
      resizable: true,
    },
  ];

  if ((isSmallScreen && showDays) || !isSmallScreen) {
    for (const [key, valor] of Object.entries(datosTabla)) {
      // add hide property to columns
      columns.push(valor);
    }
  }

  /*   if(!isSmallScreen){
      columns.push({ field: 1,field:'Categoria', headerName: 'Categoria', flex: 2, colSpan: ({ row }) => {
        if (row.tipo === 'totales') {
          return 2;
        } },disableColumnMenu:true, disableReorder:true,sortable:false,
    
        renderCell: (params) => (
          <Link color='black' underline='always' replace onClick={()=>{dataTablaDetalle(fechaStart,fechaEnd, params.value)}} >{params.value}</Link>
        )
      },
      { field:'Destino', headerName: 'Destino',flex: 2, disableColumnMenu:true, disableReorder:true,sortable:false },
      { field:'Reses', headerName: 'Reses', flex: 1, disableColumnMenu:true, disableReorder:true,sortable:false   },
      { field:'VivoProm', headerName: 'Vivo Prom', flex: 1, disableColumnMenu:true, disableReorder:true,sortable:false  },
      { field:'Precio', headerName: 'Precio', flex: 1.2, disableColumnMenu:true, disableReorder:true,sortable:false  },
      { field:'status' ,headerName: 'status', flex: 1 , hide: true },
      {field:'tipo', headerName: 'totales', flex: 4 , hide: true ,resizable:true},)
   
    }else{
      columns.push({ field:'Categoria', headerName: 'Categoria', width: 150, colSpan: ({ row }) => {
        if (row.tipo === 'totales') {
          return 2;
        } },disableColumnMenu:true, disableReorder:true,sortable:false,
    
        renderCell: (params) => (
          <Link color='black' underline='always' replace onClick={()=>{dataTablaDetalle(fechaStart,fechaEnd, params.value)}} >{params.value}</Link>
        )
      },
      { field:'Destino', headerName: 'Destino', width: 80 , disableColumnMenu:true, disableReorder:false,sortable:false },
      { field:'Reses', headerName: 'Reses', width: 80, disableColumnMenu:true, disableReorder:true,sortable:false   },
      { field:'VivoProm', headerName: 'Vivo Prom', width: 80, disableColumnMenu:true, disableReorder:true,sortable:false  },
      { field:'Precio', headerName: 'Precio', width: 80, disableColumnMenu:true, disableReorder:true,sortable:false  },
      { field:'status' ,headerName: 'status', width: 80 , hide: true },
      {field:'tipo', headerName: 'totales', width: 80, hide: true },)
    }
   */
  const columnsDetalle = [
    {
      field: "Destino",
      headerName: "Destino",
      flex: 2,
      hconservaeaderClassName: "header",
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
    },
    {
      field: "Cuota",
      headerName: "Cuota",
      flex: 2,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
    },
    {
      field: "Cantidad",
      headerName: "Objetivo",
      flex: 2,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      editable: user.permissions.compras_indicadores_can_edit,
      align: "center",
    },

    {
      field: "CantidadReal",
      headerName: "Actual",
      flex: 2,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      hide: false,
      align: "center",
    },
  ];

  const RowsDetalle = [];
  for (let i = 0; i < rowDetalle.length; i++) {
    const element = rowDetalle[i];
    const destinoComercial =
      element.Destino == "Exportación TP"
        ? "O. Países"
        : element.Destino == "Exportacion Manufactura"
          ? "Conserva"
          : element.Destino == "Exportación UE"
            ? "E. UE"
            : element.Destino == "" && tipoCompra === "Invernada"
              ? "Invernada"
              : element.Destino;
    const valor = indicadoresIniciales.find((item) =>
      tipoCompra === "Faena"
        ? item.cuota === element.Cuota && item.destino === destinoComercial
        : item.destino === destinoComercial,
    );
    //console.log(element.Cuota)
    RowsDetalle.push({
      id: i,
      Destino:
        element.Destino == "Exportación TP"
          ? "O. Países"
          : element.Destino == "Exportacion Manufactura"
            ? "Conserva"
            : element.Destino == "Exportación UE"
              ? "E. UE"
              : element.Destino == "" && tipoCompra === "Invernada"
                ? "Invernada"
                : element.Destino,
      Cuota: element.Cuota == 0 ? "" : element.Cuota,
      Cantidad: valor != undefined ? valor.valor : "",
      CantidadReal: element.CantidadReal,
    });
  }

  /*   Cantidad: (localStorage.getItem(element.Cuota+'/'+(element.Destino=='Exportación TP'?'O. Países':element.Destino=='Exportacion Manufactura'?'Conserva':element.Destino=='Exportación UE'?'E. UE':element.Destino))?localStorage.getItem(element.Cuota+'/'+(element.Destino=='Exportación TP'?'O. Países':element.Destino=='Exportacion Manufactura'?'Conserva':element.Destino=='Exportación UE'?'E. UE':element.Destino)):'')
   */
  const colFaena = [
    {
      field: "Faena",
      headerName: "Faena",
      flex: 2,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
    },
    {
      field: "Cantidad",
      headerName: "Cantidad",
      flex: 2,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      align: "center",
    },
  ];
  const rowFaena = [
    { id: 1, Faena: "Mes: " + moment(fechaStart).format('MMMM'), Cantidad: 0 },
    { id: 2, Faena: "Año: " + moment(fechaStart).format('YYYY'), Cantidad: 0 },
  ];
  for (let i = 0; i < dataFaenaAño.length; i++) {
    const element = dataFaenaAño[i];
    if (element) {
      rowFaena[i]["Cantidad"] = element.cabezasTot;
    }
  }
  const rows = [];

  // cargar data a la tabla
  for (const [key, valor] of Object.entries(dataCompras)) {
    rows.push(valor);
  }
  //console.log(rows)

  let datatableData = [];
  let datatabledataSmallScreen = [];
  for (const [key, value] of Object.entries(dataTables)) {
    //columns={["id_compra","Fecha de Compra", "Fecha de Faena","Consignatario","Remitente",'tipo',"Especie","Raza","Cabezas Compradas","Precio",'obs','vivo Prom','plazo','comisioniasta',{
    //status, "Comprador","Actualizado","Planta",
    const fechaCompraFaena = value['tipo_de_compra'] === 'Invernada' ? value["fecha_de_compra"] : value["fecha_de_faena"];

    if (user.permissions.compras_prices_can_view) {
      // ultima modificacion
      const localDateTime = new Date(value["fecha_ultima_modificacion"]);
      const fechaDeCompra = value["fecha_de_compra"] ? moment(value["fecha_de_compra"]).format("DD/MM/YY") : "";

      // Fecha de compra o faena

      datatableData.push([
        value["id"],
        value["tipo_de_compra"],
        fechaCompraFaena
          ? moment(fechaCompraFaena).format("DD/MM/YY")
          : "",
        value["consignatario"] != undefined &&
          typeof value["consignatario"] != "string" &&
          value["consignatario"].includes("-")
          ? value["consignatario"].split("-")[1]
          : typeof value["consignatario"] === "string" &&
            value["consignatario"].includes("-")
            ? value["consignatario"].split("-")[1]
            : value["consignatario"] !== undefined
              ? value["consignatario"]
              : "",
        value["remitente"] != undefined &&
          typeof value["remitente"] != "string" &&
          value["remitente"].includes("-")
          ? value["remitente"].split("-")[1]
          : typeof value["remitente"] === "string" &&
            value["remitente"].includes("-")
            ? value["remitente"].split("-")[1]
            : value["remitente"] !== undefined
              ? value["remitente"]
              : "",
        value["cuota"],
        value["especie"],
        value["raza"],
        value["cabezas_compradas"],
        value["precio"],
        value["observaciones"],
        Math.round(value["peso"]),
        value["plazo"],
        value["comisionista"] != undefined &&
          typeof value["comisionista"] != "string"
          ? value["comisionista"].split("-")[1]
          : typeof value["comisionista"] === "string"
            ? value["comisionista"]
            : "",
        value["status"],
        value["status"],
        value["CompradorName"],
        value["programa"],
        !isNaN(localDateTime.getTime())
          ? moment(localDateTime).format("DD/MM/YY HH:mm")
          : "",
        value["lastModifedBy"],
        value["establecimiento_name"],
        value["id"],
      ]);

      datatabledataSmallScreen.push([
        fechaCompraFaena
          ? moment(fechaCompraFaena).format("DD/MM/YY")
          : "",
        value["tipo_de_compra"],
        value["status"],
        value["status"],
        value["remitente"] != undefined &&
          typeof value["remitente"] != "string" &&
          value["remitente"].includes("-")
          ? value["remitente"].split("-")[1]
          : typeof value["remitente"] === "string" &&
            value["remitente"].includes("-")
            ? value["remitente"].split("-")[1]
            : value["remitente"] !== undefined
              ? value["remitente"]
              : "",
        value["consignatario"] != undefined &&
          typeof value["consignatario"] != "string" &&
          value["consignatario"].includes("-")
          ? value["consignatario"].split("-")[1]
          : typeof value["consignatario"] === "string" &&
            value["consignatario"].includes("-")
            ? value["consignatario"].split("-")[1]
            : value["consignatario"] !== undefined
              ? value["consignatario"]
              : "",
        value["CompradorName"],
        value["cabezas_compradas"],
        moneda === 'ARG' ? Math.round(value["precio"]) : parseFloat(value["precio"]).toFixed(2),
        Math.round(value["peso"]),
        value["cuota"],
        value["especie"],
        value["raza"],
        value["observaciones"],
        value["plazo"],
        value["comisionista"] != undefined &&
          typeof value["comisionista"] != "string" &&
          value["comisionista"].includes("-")
          ? value["comisionista"].split("-")[1]
          : typeof value["comisionista"] === "string" &&
            value["comisionista"].includes("-")
            ? value["comisionista"].split("-")[1]
            : value["comisionista"] !== undefined
              ? value["comisionista"]
              : "",
        value["programa"],
        value["feedlot"] != undefined &&
          typeof value["feedlot"] != "string" &&
          value["feedlot"].includes("-")
          ? value["feedlot"].split("-")[0]
          : typeof value["feedlot"] === "string" &&
            value["feedlot"].includes("-")
            ? value["feedlot"].split("-")[0]
            : value["feedlot"] !== undefined
              ? value["feedlot"]
              : "",
        value["is_propia"],
        value["is_nop"],
        fechaDeCompra,
        !isNaN(localDateTime.getTime())
          ? moment(localDateTime).format("DD/MM/YY HH:mm")
          : "",
        value["lastModifedBy"],
        value["establecimiento_name"],
        value["id"],
        value["id"],
      ]);
    } else {
      datatableData.push([
        value["id"],
        value["tipo_de_compra"],
        fechaCompraFaena
          ? moment(fechaCompraFaena).format("DD/MM/YY")
          : "",
        value["consignatario"] != undefined &&
          typeof value["consignatario"] != "string" &&
          value["consignatario"].includes("-")
          ? value["consignatario"].split("-")[1]
          : typeof value["consignatario"] === "string" &&
            value["consignatario"].includes("-")
            ? value["consignatario"].split("-")[1]
            : value["consignatario"] !== undefined
              ? value["consignatario"]
              : "",
        value["remitente"] != undefined &&
          typeof value["remitente"] != "string" &&
          value["remitente"].includes("-")
          ? value["remitente"].split("-")[1]
          : typeof value["remitente"] === "string" &&
            value["remitente"].includes("-")
            ? value["remitente"].split("-")[1]
            : value["remitente"] !== undefined
              ? value["remitente"]
              : "",
        value["destino_Comercial"],
        value["cuota"],
        value["especie"],
        value["raza"],
        value["cabezas_compradas"],
        value["peso"],
        value["observaciones"],
        value["programa"],
        value["establecimiento_name"]
      ]);
    }
  }

  const optionSmall = {
    filter: false,
    fixedHeader: true,
    responsive: "standard",
    search: true,
    viewColumns: false,
    print: false,
    download: false,
    sort: false,
    pagination: false,
    selectableRows: (user.permissions.compras_emails_can_send) ? "" : "none",
    draggableColumns: true,
    tableBodyHeight: datatabledataSmallScreen.length >= tableBodyHeight / 22 ? tableBodyHeight.toString() + "px" : ((datatabledataSmallScreen.length + 1) * 22 + 132).toString() + "px",
    selectableRowsHideCheckboxes: false,
    // onRowsDelete: handleRowClick,
    // rowsSelected: rowsSelected,

    /*    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
       // this.setState({ rowsSelected: rowsSelected });
        setRowsSelected(rowsSelected)
      }, */

    customToolbar: (displayData) => {
      return (
        <>
          <Tooltip title="Descargar a excel">
            <IconButton onClick={getComprasExcel}>
              <CloudDownloadIcon></CloudDownloadIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Descargar a pdf">
            <IconButton onClick={getComprasPdf}>
              <PrintIcon></PrintIcon>
            </IconButton>
          </Tooltip>
        </>
      );
    },

    setRowProps: (row, dataIndex, rowIndex) => {
      let remit = undefined
      try {
        remit = row[4].props.children.props.children.toString();
      } catch (error) {
        remit = undefined;
      }
      const subtotal = remit !== undefined ? remit.includes("SubtotalUE") : false;
      const total_general = remit !== undefined ? remit.includes("Total") : false;
      return {
        className: clsx({
          [classes.subtotales]: subtotal,
          [classes.totales]: row[23] === undefined && !subtotal && !total_general,
          [classes.total_general]: total_general,
          [classes.maxRowHeight]: true,
        }),
      };
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      user.permissions.compras_emails_can_send ? (
        <div>
          Enviar email de notificación
          <IconButton>
            <EmailIcon
              onClick={() => {
                handleOpenConfirmMail(selectedRows, displayData);
              }}
              filled
              fontSize="small"
            />
          </IconButton>
        </div>
      ) : (
        ""
      ),

    isRowSelectable: (dataIndex, selectedRows) => {
      if (datatabledataSmallScreen[dataIndex][0]) {
        return true;
      }
    },
  };

  // opciones
  const option = {
    filter: false,
    fixedHeader: true,
    responsive: "standard",
    search: true,
    viewColumns: false,
    selectableRows: 'none',
    print: false,
    download: false,
    sort: false,
    pagination: false,
    draggableColumns: true,
    tableBodyHeight: "400px",
    selectableRowsHideCheckboxes: false,
    // onRowsDelete: handleRowClick,
    // rowsSelected: rowsSelected,

    /*    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
       // this.setState({ rowsSelected: rowsSelected });
        setRowsSelected(rowsSelected)
      }, */

    customToolbar: (displayData) => {
      return (
        <>
          <Tooltip title="Descargar a excel">
            <IconButton onClick={getComprasExcel}>
              <CloudDownloadIcon></CloudDownloadIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Descargar a pdf">
            <IconButton onClick={getComprasPdf}>
              <PrintIcon></PrintIcon>
            </IconButton>
          </Tooltip>
        </>
      );
    },

    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.totales]: row[0] === undefined,
          [classes.maxRowHeight]: true,
        }),
      };
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      user.permissions.compras_emails_can_send ? (
        <div>
          Enviar email de notificación
          <IconButton>
            <EmailIcon
              onClick={() => handleOpenConfirmMail(selectedRows, displayData)}
              filled
              fontSize="small"
            />
          </IconButton>
        </div>
      ) : (
        ""
      ),

    isRowSelectable: (dataIndex, selectedRows) => {
      if (user.permissions.compras_emails_can_send) {
        if (datatabledataSmallScreen[dataIndex][0]) {
          return true;
        }
      }

      return false;
    },
  };

  const handleExport = () => {
    // Get the current rows and columns of the DataGrid
    const regex = /<Link[^>]*>([^<]*)<\/Link>/;
    const data = rows.map((row) =>
      columns
        .filter((column) => column.field !== 'status' && column.field !== 'tipo')
        .map((column) => row[column.field])
        .join(',')
    );
    const header1 = columns
      .filter((column) => column.field !== 'status' && column.field !== 'tipo')
      .map((column) => column.headerName)
      .slice(0, 5)
    const header2 = columns
      .filter((column) => column.field > 7)
      .map((column) => column.headerName.props.children)

    const header = [...header1, ...header2].join(',')
    const csv = [header, ...data].join('\n');

    // Create a Blob object from the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    // Create a download link for the Blob object
    const url = URL.createObjectURL(blob);

    // Simulate a click on the download link to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the URL object to free up memory
    URL.revokeObjectURL(url);
  };

  const generalConfig = {
    align: "center",
    disableColumnMenu: true,
    disableReorder: true,
    flex: 1,
    sortable: false,
    minWidth: 70,
  }

  const columnsByDestinoCuota = [
    {
      field: 'destino',
      headerName: 'Destino',
      colSpan: ({ row }) => {
        if (row.tipo === "totales" || row.tipo === 'total_general') {
          return 2;
        }
      },
      ...generalConfig,
      minWidth: 120,
      align: 'left',
      renderCell: ({ row, value }) => (
        <Link
          className={classes.customLink}
          replace
          onClick={() => {
            setMostrarFechaFaena(true);
            localStorage.removeItem("fechaBusqueda");
            dataTableDetalleByPropias(fechaStart, fechaEnd, row);
            let fechaInicioSemana = localStorage.getItem("fechaInicio");
            //cargarData(fechaInicioSemana);
          }}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'cuota',
      headerName: 'Cuota',
      ...generalConfig
    },
    {
      field: 'cantidad',
      headerName: 'Cabezas',
      ...generalConfig
    },
    {
      field: 'peso',
      headerName: 'Kg/Cab',
      ...generalConfig
    },
    {
      field: 'precio',
      headerName: '$/KgG',
      ...generalConfig,
      renderCell: ({ row }) => (
        <div>{currencyFormatter.format(row.precio)}</div>
      ),
    },
    {
      field: 'porcentaje_expo',
      headerName: '% Cab/Exp',
      description: "Porcentaje de cabezas propias respecto al total de cabezas de exportación, por establecimiento",
      renderCell: ({ row }) => {
        return (
          <div>{Number(row.porcentaje_expo).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</div>
        )
      },
      ...generalConfig,
      minWidth: 100,
    },
    {
      field: 'porcentaje',
      headerName: '% Cab/Est',
      description: "Porcentaje de cabezas respecto al total de cabezas propias, por establecimiento",
      renderCell: ({ row }) => {
        return (
          <div>{Number(row.porcentaje).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</div>
        )
      },
      ...generalConfig,
      minWidth: 100,
    }
  ]

  return (
    <div>
      {/* className={`${isSmallScreen ? classes.zoomOut : ''}`} */}
      {/* <PageTitle title="Recepciones Programadas" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <Grid item container alignItems="center" spacing={2}>
            <Grid item lg={2} md={12} sm={12} xs={12}>
              <ResponsiveDatePickers
                value={fechaStart}
                fechaInicial={fechaStart}
                fechaFinal={fechaEnd}
                setValorFecha={setDate}
              ></ResponsiveDatePickers>
            </Grid>

            {/* <Grid container lg={2} md={6} sm={12} xs={12} spacing={2}> */}



            <Grid
              item
              lg={2}
              md={6}
              sm={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <ToggleButtonGroup
                value={alignment[0]}
                exclusive
                fullWidth
                onChange={(e) => handleAlignment(e, 0)}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  borderColor={theme.palette.tables.tableBorderColor}
                  textColor={theme.palette.toggleButtons.color}
                  selectedColor={theme.palette.toggleButtons.background}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    setAgrup("semana");
                  }}
                >
                  {" "}
                  Semana
                </ToggleButton>
                <ToggleButton
                  value="right"
                  aria-label="left aligned"
                  borderColor={theme.palette.tables.tableBorderColor}
                  textColor={theme.palette.toggleButtons.color}
                  selectedColor={theme.palette.toggleButtons.background}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    setAgrup("mes");
                  }}
                >
                  Mes{" "}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              lg={2}
              md={6}
              sm={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <ToggleButtonGroup
                value={alignment[1]}
                exclusive
                fullWidth
                onChange={(e) => handleAlignment(e, 1)}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  borderColor={theme.palette.tables.tableBorderColor}
                  textColor={theme.palette.toggleButtons.color}
                  selectedColor={theme.palette.toggleButtons.background}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    setTipoCompra("Faena");
                    localStorage.setItem("tCompra", "Faena");
                  }}
                >
                  {" "}
                  Faena{" "}
                </ToggleButton>
                <ToggleButton
                  value="right"
                  aria-label="left aligned"
                  borderColor={theme.palette.tables.tableBorderColor}
                  textColor={theme.palette.toggleButtons.color}
                  selectedColor={theme.palette.toggleButtons.background}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    setTipoCompra("Invernada");
                    localStorage.setItem("tCompra", "Invernada");
                  }}
                >
                  Invernada{" "}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid
              item
              lg={2}
              md={6}
              sm={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <ToggleButtonGroup
                value={alignment[2]}
                exclusive
                fullWidth
                onChange={(e) => handleAlignment(e, 2)}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  borderColor={theme.palette.tables.tableBorderColor}
                  textColor={theme.palette.toggleButtons.color}
                  selectedColor={theme.palette.toggleButtons.background}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    setMoneda("ARG");
                  }}
                >
                  {" "}
                  ARG{" "}
                </ToggleButton>
                <ToggleButton
                  value="right"
                  aria-label="left aligned"
                  borderColor={theme.palette.tables.tableBorderColor}
                  textColor={theme.palette.toggleButtons.color}
                  selectedColor={theme.palette.toggleButtons.background}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    setMoneda("USD ");
                  }}
                >
                  {" "}
                  USD
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>



            {/* </Grid> */}

            {/* <Grid item container spacing={2} lg={12} md={6} sm={12} xs={12} alignItems="center"> */}





            {showFilters && (
              <>
                <Grid item lg={3} md={12} sm={12} xs={12}></Grid>
                <Grid item lg={2} md={6} sm={12} xs={12}>
                  <InputLabel id="type">Status Compra</InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    multiple
                    id="Status Compra"
                    value={filtroStatus}
                    label="Status Compra"
                    onChange={handlefiltroStatus}
                    fullWidth
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {despStatusCompra.map((val) => {
                      return (
                        <MenuItem key={val.status} value={val.status}>
                          <Checkbox
                            color="primary"
                            checked={filtroStatus.indexOf(val.status) > -1}
                          />

                          <ListItemText primary={val.status} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item lg={2} md={6} sm={12} xs={12}>
                  <InputLabel id="type">Comprador</InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    id="Comprador"
                    value={filtroComprador}
                    label="Comprador"
                    onChange={handlefiltroComprador}
                    fullWidth
                    multiple
                    renderValue={(selected) => selected.join(",")}
                  >
                    {despComprador.map((val) => {
                      return (
                        <MenuItem key={val.email} value={val.email}>
                          <Checkbox
                            color="primary"
                            checked={
                              filtroComprador.indexOf(val.email) > -1
                            }
                          />
                          <ListItemText
                            primary={val.username + " " + val.last_name}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid item lg={2} md={6} sm={12} xs={12}>
                  <InputLabel id="type">Consignatario</InputLabel>
                  {/* <TextField 
                    type="text"
                    variant="outlined"
                    onChange={(e, value) =>{ handleSearchChange(e, value);setOrigen('consignatario')}}
                    /> */}
                  <Autocomplete
                    disablePortal
                    noOptionsText={"Sin opciones"}
                    value={consignatario}
                    id="combo-box-demo"
                    variant="primary"
                    options={autocompleteConsig}
                    onChange={(e, value) => {
                      if (value && value.cuit != undefined) {
                        if (value.cuit !== "") {
                          setConsignatario(
                            value.cuit.toString() +
                            "-" +
                            value.label.toString(),
                          );
                        } else {
                          setConsignatario(value.label.toString());
                        }
                        localStorage.setItem(
                          "consig",
                          value.cuit.toString() +
                          "-" +
                          value.label.toString(),
                        );
                      } else {
                        setConsignatario("");
                        localStorage.removeItem("consig");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={2} md={6} sm={12} xs={12}>
                  <InputLabel id="combo-box-demo">Remitente</InputLabel>
                  <Autocomplete
                    disablePortal
                    noOptionsText={"Sin opciones"}
                    value={remitente}
                    id="combo-box-demo"
                    options={autocompleteRemitente}
                    onChange={(e, value) => {
                      if (value && value.cuit != undefined) {
                        if (value.cuit !== "") {
                          setRemitente(
                            value.cuit.toString() +
                            "-" +
                            value.label.toString(),
                          );
                        } else {
                          setRemitente(value.label.toString());
                        }
                        localStorage.removeItem("remit");
                        localStorage.setItem(
                          "remit",
                          value.cuit.toString() +
                          "-" +
                          value.label.toString(),
                        );
                      } else {
                        setRemitente("");
                        localStorage.removeItem("remit");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  lg={1}
                  md={6}
                  sm={12}
                  xs={12}
                  alignItems="center"
                  justify="center"
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Desagrupado por Peso">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            style={{ color: theme.palette.primary.main }}
                            onClick={() => {
                              setAgruparPorPeso((value) => !value);
                            }}
                            checked={agruparPorPeso}
                          />
                        }
                        label="Por Peso"
                      />
                    </Tooltip>
                  </div>
                </Grid>
              </>
            )}


            <Grid
              item
              container
              lg={3}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              justify={isSmallScreen ? "center" : "left"}
              spacing={12}
            >
              {isLoading2 ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >

                  <Tooltip title={showFilters ? "Ocultar Filtros" : "Mostrar Filtros"}>
                    <IconButton onClick={handleFilters}>
                      <Badge badgeContent={badgeCount} color="secondary">
                        {!showFilters ? (
                          <FilterListIcon
                            sx={{ color: theme.palette.primary.main }}
                            fontSize="large"
                          ></FilterListIcon>

                        ) : (
                          <CloseIcon
                            sx={{ color: theme.palette.primary.main }}
                            fontSize="large"
                          >
                          </CloseIcon>
                        )}
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  {/* <Button

                      className={classes.buttonSubmit}
                      onClick={() => cargarData(fechaStart, fechaEnd)}
                      variant="contained"
                      color="primary"
                      startIcon={<SearchIcon />}
                    //fullWidth
                    >
                    </Button> */}
                  {/* <IconButton onClick={() => cargarData(fechaStart, fechaEnd)} variant="contained">
                      <SearchIcon sx={{ color: theme.palette.primary.main }} fontSize="large"></SearchIcon>
                    </IconButton> */}
                  {user.permissions.compras_indicadores_can_view && (
                    <Tooltip title="Mostrar Indicadores">
                      <IconButton>
                        <EqualizerIcon
                          onClick={handleShowIndicadores}
                          sx={{ color: theme.palette.primary.main }}
                          fontSize="large"
                        ></EqualizerIcon>
                      </IconButton>
                    </Tooltip>
                  )}

                  <CustomSearchButton
                    fechaStart={fechaStart}
                    fechaEnd={fechaEnd}
                    onClick={cargarData}
                    color={theme.palette.primary.main}
                  />
                </div>
              )}
              {errorServidor && (
                <div>
                  <Fade in={errorServidor}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      No hay informacion en el servidor
                    </Typography>
                  </Fade>
                </div>
              )}
            </Grid>
            {/* <Grid item lg={2} md={6} sm={12} xs={12} alignItems="center" justify="center">
              <Fade in={errorServidor}>
                <Typography color="secondary" className={classes.errorMessage}>
                  No hay informacion en el servidor
                </Typography>
              </Fade>
            </Grid> */}
            {/* </Grid> */}
          </Grid>

          <Grid container>
            <Grid
              container
              className={classes.containerBACK2}
              lg={9}
              xs={12}
            >

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    padding: 0,
                    "& .header": {
                      /*   maxHeight:20,
                      minHeight:20 */
                    },
                    "& .TOTALES": {
                      backgroundColor: () => {
                        return theme.palette.totals.total; //'#0064c7'
                      },
                      color: theme.palette.text.primary,
                      fontWeight: "bold",
                      fontSize: theme.typography.fontSize + 1,
                      borderBottom: '3px solid ' + theme.palette.tables.tableBorderColor + ' !important',
                    },
                    "& .TOTALGENERAL > a": {
                      color: theme.palette.primary.main,
                    },
                    "& .TOTALGENERAL": {
                      backgroundColor: () => {
                        return theme.palette.totals.general; //'#0064c7'
                      },
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      fontSize: theme.typography.fontSize + 2,
                    },
                    "& .SUBTOTALES": {
                      backgroundColor: () => {
                        return theme.palette.totals.subtotal; //'#004b96'
                      },
                      color: theme.palette.text.primary,
                      fontWeight: "bold",
                    },
                    "& .SUBTOTALESUE > a": {
                      color: theme.palette.primary.main,
                    },
                    "& .SUBTOTALESUE": {
                      backgroundColor: () => {
                        return theme.palette.totals.ue; //'#004b96'
                      },
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      fontSize: theme.typography.fontSize + 1,
                      borderTop: '2px solid ' + theme.palette.tables.tableBorderColor + ' !important',
                    },
                    "& .aconfirmar": {
                      backgroundColor: "red",
                      maxHeight: 10,
                      color: "white",
                    },
                    "& .tramite": {
                      backgroundColor: "yellow",
                      maxHeight: 10,
                      color: "black",
                    },
                    "& .confirmada": {
                      backgroundColor: "rgb(63, 195, 128)",
                      maxHeight: 10,
                    },
                    "& .MuiDataGrid-cell": {
                      maxHeight: 14,
                      minHeight: 20,
                    },
                    "& .separador": {
                      borderRight: '4px solid ' + theme.palette.tables.tableBorderColor
                    },
                    "& .centrado > a": {
                      marginLeft: '15px !important',
                    }
                  }}
                >
                  {/* TABLA DE SUBTOTALES!!! */}

                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    components={{
                      NoRowsOverlay: () => (
                        <Stack
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          No hay Datos
                        </Stack>
                      ),
                      Toolbar: DataGridTitle,
                      // Footer: () => (
                      //   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      //     {/* <Button variant="outlined" color="primary" size="small" onClick={handleExport}>
                      //       Exportar datos
                      //     </Button> */}
                      //     <Tooltip title="Descargar a csv">
                      //     <IconButton>
                      //       <CloudDownloadIcon
                      //         onClick={handleExport}
                      //         sx={{ color: theme.palette.primary.main }}
                      //         fontSize="small"
                      //       ></CloudDownloadIcon>
                      //     </IconButton>
                      //     </Tooltip>
                      //   </div>
                      // )

                    }}
                    onPageSizeChange={(newPageSize) =>
                      setPageSize(newPageSize)
                    }
                    rowsPerPageOptions={[pageSize]}
                    autoHeight
                    rowHeight={25}
                    disableSelectionOnClick
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    sx={{

                      m: "0px",
                      color: "inherit",
                      "& .MuiDataGrid-cell": {
                        border: 1,
                        borderColor: theme.palette.tables.tableBorderColor,
                        borderRight: 0,
                        borderTop: 0,
                        // add more css for customization
                      },
                      "& .MuiDataGrid-columnHeaderTitleContainer": {
                        justifyContent: "center",
                        borderColor: theme.palette.tables.tableBorderColor,
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        borderBottom:
                          "4px solid " +
                          theme.palette.tables.tableBorderColor,
                        fontSize: 15,
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop:
                          "1px solid " +
                          theme.palette.tables.tableBorderColor,
                        maxHeight: "0px",
                        minHeight: "0px",
                      },
                      border:
                        "2px solid " +
                        theme.palette.tables.tableBorderColor,
                    }}
                    getCellClassName={(params) => {
                      // `super-app-theme--${params.row.Categoria}`
                      let finalClass = "";
                      if (params.row.tipo == "totales") {
                        finalClass = "TOTALES";
                        if (params.field === "Precio") {
                          finalClass = finalClass + " separador";
                        }
                        if (params.field === "Categoria") {
                          finalClass = finalClass + " centrado";
                        }
                        return finalClass;
                      }

                      if (params.row.tipo == "subtotales") {
                        finalClass = "SUBTOTALES";
                        if (params.field === "Precio") {
                          finalClass = finalClass + " separador";
                        }
                        return finalClass;
                      }

                      if (params.row.tipo == "subtotalesUE") {
                        finalClass = "SUBTOTALESUE";
                        if (params.field === "Precio") {
                          finalClass = finalClass + " separador";
                        }
                        return finalClass;
                      }

                      if (params.row.tipo == "total_general") {
                        finalClass = "TOTALGENERAL";
                        if (params.field === "Precio") {
                          finalClass = finalClass + " separador";
                        }
                        return finalClass;
                      }

                      if (params.field === "Precio") {
                        return "separador";
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {user.permissions.compras_indicadores_can_view && showIndicadores && (
              <Grid
                className={classes.containerIndicadores}
                container
                lg={3}
                md={12}
                sm={12}
                xs={12}
                spacing={0}
              >
                <Grid item lg={12} md={4} sm={4} xs={12}>
                  <Box
                    sx={{
                      width: '100%',
                      marginBottom: '12px'
                    }}>
                    <PreciosEstimadosTable
                      year={moment(fechaStart).year()}
                      week={moment(fechaStart).startOf("isoWeek").week()}
                      tipo_compra={tipoCompra}
                      openSnackbar={handleOpenSnackbar} />
                  </Box>
                </Grid>
                <Grid item lg={12} md={4} sm={4} xs={12}>
                  {/* <Grid item>
                          <Typography>Tabla Indicadores</Typography>
                        </Grid> */}

                  <Box
                    sx={{
                      width: "100%",
                      marginBottom: "12px",
                      "& .TOTALES": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.primary.main,
                            theme.palette.primary,
                          ),
                        "&:hover": {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.primary.main,
                              theme.palette.primary,
                            ),
                        },
                      },
                      "& .rojo": {
                        backgroundColor: theme.palette.spotlight.red,
                        color: theme.palette.text.primary,
                      },
                      "& .amarillo": {
                        backgroundColor: theme.palette.spotlight.yellow,
                        color: theme.palette.text.primary,
                      },
                      "& .verde": {
                        backgroundColor: theme.palette.spotlight.green,
                      },
                    }}
                  >
                    <DataGrid
                      rows={RowsDetalle}
                      columns={columnsDetalle}
                      onEstadisticas
                      autoHeight
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      hideFooter={true}
                      rowHeight={25}
                      sx={{
                        color: "inherit",
                        "& .MuiDataGrid-cell": {
                          border: 1,
                          borderColor:
                            theme.palette.tables.tableBorderColor,
                          borderRight: 0,
                          borderTop: 0,
                          // add more css for customization
                        },
                        "& .css-vcjdx3": {
                          backgroundColor: theme.palette.tables.header,
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                          justifyContent: "center",
                          borderColor:
                            theme.palette.tables.tableBorderColor,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom:
                            "4px solid " +
                            theme.palette.tables.tableBorderColor,
                          fontSize: 15,
                          backgroundColor: theme.palette.tables.header,
                          color: theme.palette.tables.headerText,
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop:
                            "1px solid " +
                            theme.palette.tables.tableBorderColor,
                          maxHeight: "0px",
                          minHeight: "0px",
                        },
                        border:
                          "2px solid " +
                          theme.palette.tables.tableBorderColor,
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            No hay Datos
                          </Stack>
                        ),
                      }}
                      //editar fila
                      onCellEditStop={(params, event) => {
                        const value = [
                          params.row.Cuota,
                          event.target.value,
                        ];
                        const Nsemana = moment(fechaStart)
                          .startOf("isoWeek")
                          .week();
                        const Nyear = moment(fechaStart).year();
                        const indicador = indicadoresIniciales.find(
                          (item) =>
                            item.cuota === params.row.Cuota &&
                            item.destino === params.row.Destino,
                        );
                        if (indicador == undefined) {
                          UserService.postIndicadores(
                            params.row.Destino,
                            Nsemana,
                            params.row.Cuota,
                            event.target.value,
                            Nyear,
                            agrup,
                            searchedMonth,
                          );
                        } else {
                          UserService.putIndicadores(
                            params.row.Destino,
                            Nsemana,
                            params.row.Cuota,
                            event.target.value,
                            Nyear,
                            agrup,
                            searchedMonth,
                          );
                        }

                        if (
                          params.reason ===
                          GridCellEditStopReasons.cellFocusOut
                        ) {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      disableSelectionOnClick
                      getCellClassName={(params) => {
                        // `super-app-theme--${params.row.Categoria}`
                        if (params.field === "Cantidad") {
                          if (params.row.Cantidad == "") {
                            return "";
                          }

                          if (
                            params.row.Cantidad / params.row.CantidadReal >
                            1 &&
                            params.row.Cantidad / params.row.CantidadReal <=
                            1.5
                          ) {
                            return "amarillo";
                          }
                          if (
                            params.row.Cantidad / params.row.CantidadReal >
                            1.5
                          ) {
                            return "rojo";
                          }
                          if (
                            params.row.Cantidad / params.row.CantidadReal <=
                            1
                          ) {
                            return "verde";
                          }
                        } else {
                          return "";
                        }
                      }}
                    />
                  </Box>
                </Grid>
                {/* <Grid item>
                          <Typography>Tabla Indicadores Faena</Typography>
                        </Grid> */}
                <Grid item lg={12} md={4} sm={4} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      //marginBottom: "10px",
                      "& .TOTALES": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.primary.main,
                            theme.palette.primary,
                          ),
                        "&:hover": {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.primary.main,
                              theme.palette.primary,
                            ),
                        },
                      },
                      "& .aconfirmar": {
                        backgroundColor: "red",
                      },
                      "& .tramite": {
                        backgroundColor: "yellow",
                      },
                      "& .confirmada": {
                        backgroundColor: "rgb(63, 195, 128)",
                      },
                    }}
                  >
                    <DataGrid
                      rows={rowFaena}
                      columns={colFaena}
                      onEstadisticas
                      hideFooter={true}
                      rowHeight={25}
                      autoHeight
                      sx={{
                        color: "inherit",
                        "& .MuiDataGrid-cell": {
                          border: 1,
                          borderColor:
                            theme.palette.tables.tableBorderColor,
                          borderRight: 0,
                          borderTop: 0,
                          // add more css for customization
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                          justifyContent: "center",
                          borderColor:
                            theme.palette.tables.tableBorderColor,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom:
                            "4px solid " +
                            theme.palette.tables.tableBorderColor,
                          fontSize: 15,
                          backgroundColor: theme.palette.tables.header,
                          color: theme.palette.tables.headerText,
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop:
                            "1px solid " +
                            theme.palette.tables.tableBorderColor,
                          maxHeight: "0px",
                          minHeight: "0px",
                        },
                        border:
                          "2px solid " +
                          theme.palette.tables.tableBorderColor,
                      }}
                      disableSelectionOnClick
                      hideFooterPagination
                      hideFooterSelectedRowCount
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            {tipoCompra === "Faena" && (
              <Grid container lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Box sx={{
                    marginTop: '12px',
                    "& .TOTALES": {
                      backgroundColor: () => {
                        return theme.palette.totals.total; //'#0064c7'
                      },
                      color: theme.palette.text.primary,
                      fontWeight: "bold",
                      fontSize: theme.typography.fontSize + 1,
                      borderBottom: '3px solid ' + theme.palette.tables.tableBorderColor + ' !important',
                    },
                    "& .TOTALGENERAL > a": {
                      color: theme.palette.primary.main,
                    },
                    "& .TOTALGENERAL": {
                      backgroundColor: () => {
                        return theme.palette.totals.general; //'#0064c7'
                      },
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      fontSize: theme.typography.fontSize + 2,
                    },
                  }}>
                    <DataGrid
                      rows={groupedByDestinoCuota}
                      columns={columnsByDestinoCuota}
                      autoHeight
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      disableSelectionOnClick
                      hideFooter={true}
                      rowHeight={25}
                      getCellClassName={({ row }) => {
                        if (row.tipo === 'total_general') {
                          return 'TOTALGENERAL'
                        }
                        if (row.tipo === 'totales') {
                          return 'TOTALES'
                        }

                      }}
                      sx={{
                        m: "0px",
                        color: "inherit",
                        "& .MuiDataGrid-cell": {
                          border: 1,
                          borderColor: theme.palette.tables.tableBorderColor,
                          borderRight: 0,
                          borderTop: 0,
                          // add more css for customization
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                          justifyContent: "center",
                          borderColor: theme.palette.tables.tableBorderColor,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom:
                            "4px solid " +
                            theme.palette.tables.tableBorderColor,
                          fontSize: 15,
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop:
                            "1px solid " +
                            theme.palette.tables.tableBorderColor,
                          maxHeight: "0px",
                          minHeight: "0px",
                        },
                        border:
                          "2px solid " +
                          theme.palette.tables.tableBorderColor,
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {'No hay datos'}
                          </Stack>
                        ),
                        Toolbar: () => (
                          <div style={{ display: 'flex', justifyContent: 'center', color: theme.palette.tables.headerText }} className={classes.containerBACK}>
                            <Typography>Resumen Hacienda Propia</Typography>
                          </div>
                        )
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid
              className={classes.container}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {(user.permissions.compras_can_create) && (
                <>
                  <Button
                    size="big"
                    variant="contained"
                    color="primary"
                    href="#/app/nuevasCompras"
                    className={classes.buttonSubmit}
                    startIcon={<AddShoppingCartIcon style={{ color: theme.palette.primary.main }} />}
                  >
                    Nueva Compra
                  </Button>{" "}
                </>
              )}
              {mostrarTablaDetalle && (
                <div ref={tableDetalleRef}>
                  {user.permissions.compras_prices_can_view ? (

                    <MUIDataTable
                      data={datatabledataSmallScreen}
                      options={optionSmall}
                      title={!mostrarFechaFaena ? (
                        <span>
                          Compras con Fecha de Faena:{" "}
                          <strong>{moment(fechaBusqueda).format("DD/MM/YY")}</strong>
                        </span>
                      ) : (
                        ""
                      )}
                      columns={[
                        {
                          name: "Faena",

                          options: {
                            filter: true,
                            //minWidth: 50,

                            display: mostrarFechaFaena,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                                minWidth: "40px",
                              },
                            }),
                            setCellHeaderProps: () => ({
                              style: {
                                textAlign: "center !important",
                                alignItems: "center !important",
                                justifyContent: "center",
                                color: theme.palette.tables.headerText,
                              },
                            }),

                            /* sort: false,
                            empty: false, */
                            /*  setCellHeaderProps: () => ({ style: { minWidth: "120px"}}),
                             setCellProps: () => ({ style: { minWidth: "120px"}}), */

                            customBodyRender: (
                              valor,
                              dataIndex,
                              rowIndex,
                            ) => {
                              if (user.permissions.compras_can_edit) {
                                const compra_id = dataIndex.rowData.slice(-1)[0];
                                const is_editable = dataTables.find((item) => item.id === compra_id)?.editable ?? true; 
                                if (valor != undefined && is_editable) {
                                  return (
                                    <Link
                                      variant="text"
                                      sx={{
                                        color: theme.palette.totals.subtotal,
                                      }}
                                      component={LinKDom}
                                      to={`/app/editCompra/${dataIndex.rowData.slice(-1)}`}
                                    >
                                      {valor}
                                    </Link>
                                  );
                                }
                              } else {
                                return (
                                  <div>{valor}</div>
                                )
                              }
                            },
                          },
                        },
                        {
                          name: "Tipo",
                          options: {
                            setCellProps: () => {
                              if (isSmallScreen) {
                                return {
                                  style: {
                                    maxWidth: "85px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                };
                              } else {
                                return {
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                };
                              }
                            },
                            maxWidth: 55,
                            display: false,
                          },
                        },
                        {
                          name: "",
                          options: {
                            filter: true,
                            display: true,
                            setCellProps: () => ({
                              textAlign: "center",
                              alignItems: "center",
                            }),

                            customBodyRender: (
                              valor,
                              dataIndex,
                              updateValue,
                            ) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {valor == "SinConfirmar" ? (
                                    <CircleRoundedIcon
                                      className={classes.sin_confirmar}
                                    />
                                  ) : valor == "tramite" ? (
                                    <CircleRoundedIcon
                                      className={classes.tramite}
                                    />
                                  ) : valor == "confirmado" ? (
                                    <CircleRoundedIcon
                                      className={classes.confirmado}
                                    />
                                  ) : valor == "arribado" ? (
                                    <CircleRoundedIcon
                                      className={classes.arribado}
                                    />
                                  ) : valor == "postergaCarga" ? (
                                    <CircleRoundedIcon
                                      className={classes.postergado}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            },
                          },
                        },
                        {
                          name: "Status",
                          options: {
                            filter: true,
                            /* sort: false,
                            empty: false, */

                            customBodyRender: (
                              valor,
                              dataIndex,
                              updateValue,
                            ) => {
                              if (valor != undefined) {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={valor}
                                        disabled={!user.permissions.compras_can_edit}
                                        label="Age"
                                        //disabled={rol == 10 ? true : false}
                                        disableUnderline
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        onChange={(e) => {
                                          updateValue(e.target.value);
                                          dataIndex.rowData[2] =
                                            e.target.value;
                                          setStatus(
                                            e,
                                            dataIndex.rowData.slice(-1),
                                          );
                                        }}
                                      >
                                        <MenuItem value={"SinConfirmar"}>
                                          {!isSmallScreen
                                            ? "Sin Confirmar"
                                            : "SC"}
                                        </MenuItem>
                                        <MenuItem value={"tramite"}>
                                          {!isSmallScreen
                                            ? "Trámite"
                                            : "TRA"}
                                        </MenuItem>
                                        <MenuItem value={"arribado"}>
                                          {!isSmallScreen
                                            ? "Arribado"
                                            : "ARR"}
                                        </MenuItem>
                                        <MenuItem value={"confirmado"}>
                                          {!isSmallScreen
                                            ? "Confirmado"
                                            : "CO"}
                                        </MenuItem>
                                        <MenuItem value={"postergaCarga"}>
                                          {!isSmallScreen
                                            ? "Posterca Carga"
                                            : "PC"}
                                        </MenuItem>
                                      </Select>
                                      {/* ) : (
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={valor}
                                              disableUnderline
                                              label="Age"
                                              onChange={(e) => {
                                                setStatus(
                                                  e,
                                                  dataIndex.rowData[21],
                                                );
                                              }}
                                            >
                                              <MenuItem value={"confirmado"}>
                                                {!isSmallScreen
                                                  ? "Confirmado"
                                                  : "CO"}
                                              </MenuItem>
                                            </Select> */}
                                    </div>
                                  </>
                                );
                              }
                            },
                          },
                        },
                        {
                          name: !isSmallScreen ? "Remitente" : "Remit",
                          options: {
                            filter: true,
                            /* sort: false,
                            empty: false, */
                            setCellHeaderProps: () => {

                              return {
                                style: {
                                  maxWidth: isSmallScreen ? "105px" : "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              };

                            },
                            setCellProps: () => {

                              return {
                                style: {
                                  maxWidth: isSmallScreen ? "105px" : "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              };

                            },

                            customBodyRender: (
                              valor,
                              dataIndex,
                              rowIndex,
                            ) => {
                              if (dataIndex.rowData.slice(-1) != undefined) {
                                return (
                                  <div className={classes.contained}>
                                    <Link
                                      className={classes.customLink}
                                      onClick={() => {
                                        openModal(valor);
                                      }}
                                    >
                                      {valor}
                                    </Link>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={classes.contained}>
                                    {valor}
                                  </div>
                                );
                              }
                            },
                          },
                        },
                        {
                          name: !isSmallScreen ? "Consignatario" : "Consig",
                          options: {
                            filter: true,
                            /* sort: false,
                            empty: false, */
                            setCellHeaderProps: () => {
                              return {
                                style: {
                                  maxWidth: isSmallScreen ? "105px" : "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: theme.palette.tables.headerText,
                                },
                              };

                            },
                            setCellProps: () => {
                              return {
                                style: {
                                  maxWidth: isSmallScreen ? "105px" : "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              };

                            },

                            customBodyRender: (
                              valor,
                              dataIndex,
                              rowIndex,
                            ) => {
                              return (
                                <div className={classes.contained}>
                                  <Link
                                    className={classes.customLink}
                                    onClick={() => {
                                      openModal(valor);
                                    }}
                                  >
                                    {valor}
                                  </Link>
                                </div>
                              );
                            },
                          },
                        },
                        {
                          name: !isSmallScreen ? "Comprador" : "Compr",
                          options: {
                            setCellProps: () => {
                              if (isSmallScreen) {
                                return {
                                  style: {
                                    maxWidth: "85px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                };
                              } else {
                                return {
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                };
                              }
                            },
                            customBodyRender: (
                              valor,
                              dataIndex,
                              rowIndex,
                            ) => {
                              // quitamos la!mostrarFechaFaena && 
                              if (user.permissions.compras_can_edit) {
                                const compra_id = dataIndex.rowData.slice(-1)[0];
                                const is_editable = dataTables.find((item) => item.id === compra_id)?.editable ?? true; 
                                if (valor != undefined && is_editable) {
                                  return (
                                    <Link
                                      variant="text"
                                      sx={{
                                        color: theme.palette.totals.subtotal,
                                      }}
                                      component={LinKDom}

                                      to={`/app/editCompra/${dataIndex.rowData.slice(-1)}`}
                                    >
                                      {valor}
                                    </Link>
                                  );
                                }
                              } else {
                                return (
                                  <div>{valor}</div>)
                              }
                            },
                          },
                        },
                        {
                          name: !isSmallScreen ? "Reses" : "Res",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name:
                            (tipoCompra == "Faena" && moneda == "ARG")
                              ? "$/KgG"
                              : (tipoCompra == "Faena" && moneda != "ARG")
                                ? "U$D/KgG"
                                : (tipoCompra == "Invernada" &&
                                  moneda == "ARG")
                                  ? "$/KgV"
                                  : "U$D/KgV",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            customBodyRender: (value, dataIndex) => {
                              return (
                                <div>
                                  {currencyFormatter.format(value)}
                                </div>
                              );
                            }
                          },
                        },
                        {
                          name: "Kg/Cab",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Cuota",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellProps: () => {
                              if (isSmallScreen) {
                                return {
                                  style: {
                                    maxWidth: "85px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                };
                              } else {
                                return {
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                };
                              }
                            },
                          },
                        },
                        {
                          name: "Esp",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Raza",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Obs",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellHeaderProps: () => {
                              if (isSmallScreen) {
                                return {
                                  style: {
                                    maxWidth: "105px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  },
                                };
                              }
                            },
                            setCellProps: () => {

                              return {
                                style: {
                                  maxWidth: isSmallScreen ? "105px" : "140px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              };

                            },
                          },
                        },
                        {
                          name: isSmallScreen ? "Pla" : "Plazo",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: !isSmallScreen ? "Comisionista" : "Comis",
                          options: {
                            filter: true,
                            /* sort: false,
                            empty: false, */
                            setCellHeaderProps: () => ({
                              style: {
                                maxWidth: isSmallScreen ? "105px" : "140px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }),
                            setCellProps: () => ({
                              style: {
                                maxWidth: isSmallScreen ? "105px" : "140px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }),

                            customBodyRender: (
                              valor,
                              dataIndex,
                              rowIndex,
                            ) => {
                              return (
                                <div className={classes.contained}>
                                  <Link
                                    className={classes.customLink}
                                    onClick={() => {
                                      openModal(valor);
                                    }}
                                  >
                                    {valor}
                                  </Link>
                                </div>
                              );
                            },
                          },
                        },
                        {
                          name: "Prog",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Feedlot",
                          options: {
                            display: true,//tipoCompra == "Invernada" ? true : false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Propia",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            customBodyRender: (value, dataIndex) => {

                              if (value == true) {
                                return (
                                  <>
                                    <CheckCircleIcon className={classes.verde} size="small" />
                                  </>
                                )
                              } else {
                                return (
                                  <>
                                  </>
                                )
                              }
                            }
                          }
                        },
                        {
                          name: "NOP",
                          options: {
                            display: tipoCompra != "Invernada" ? true : false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            customBodyRender: (value, dataIndex) => {

                              if (value == true) {
                                return (
                                  <>
                                    <CheckCircleIcon className={classes.verde} size="small" />
                                  </>
                                )
                              } else {
                                return (
                                  <>
                                  </>
                                )
                              }
                            }
                          }
                        },
                        {
                          name: "Fecha Compra",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                                maxWidth: "85px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }),
                          },
                        },
                        {
                          name: "Ultima Modificacion",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                                maxWidth: "85px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }),
                          },
                        },
                        {
                          name: "Modificado Por",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Est.",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },
                        {
                          name: "Id",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Acciones",
                          options: {
                            filter: true,
                            display: user.permissions.compras_can_edit || user.permissions.compras_can_delete,
                            /* sort: false,
                            empty: false, */
                            setCellHeaderProps: () => ({
                              style: { minWidth: "60px" },
                            }),
                            setCellProps: () => ({
                              style: {
                                minWidth: "60px",
                                justifyContent: "center",
                              },
                            }),

                            customBodyRender: (
                              value,
                              dataIndex,
                              rowIndex,
                            ) => {
                              const compra_id = dataIndex.rowData.slice(-1)[0];
                              const is_editable = dataTables.find((item) => item.id === compra_id)?.editable ?? true; 
                              if (dataIndex.rowData[22] != undefined) {
                                // compruebo que no sea el usuario logueado
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {
                                        (user.permissions.compras_can_edit && is_editable) && (
                                          // <ThemeProvider theme={styledIcon}>
                                          <IconButton
                                            aria-label="edit"
                                            size="medium"
                                            style={{
                                              color:
                                                theme.palette.primary.main,
                                            }}
                                            color="primary"
                                            component={LinKDom}
                                            to={`/app/editCompra/${dataIndex.rowData.slice(-1)}`}
                                          >
                                            <EditIcon
                                              color="primary"
                                              fontSize="small"
                                              style={{
                                                color:
                                                  theme.palette.primary
                                                    .main,
                                              }}
                                            />
                                          </IconButton>
                                        )
                                        // </ThemeProvider>
                                      }
                                      {
                                        (user.permissions.compras_can_delete) && (
                                          // <ThemeProvider theme={styledIcon}>
                                          <IconButton
                                            aria-label="edit"
                                            size="medium"
                                            style={{
                                              color:
                                                theme.palette.primary.main,
                                            }}
                                            color="primary"
                                            onClick={() =>
                                              handleOpen(value)
                                            }
                                          >
                                            <DeleteIcon
                                              color="primary"
                                              fontSize="small"
                                              style={{
                                                color:
                                                  theme.palette.primary
                                                    .main,
                                              }}
                                            />
                                          </IconButton>
                                        )
                                        // </ThemeProvider>
                                      }
                                    </div>
                                    <Modal
                                      hideBackdrop
                                      open={openBorrar}
                                      onClose={handleCloseBorrar}
                                      aria-labelledby="child-modal-title"
                                      aria-describedby="child-modal-description"
                                    >
                                      <Box className={classes.Modal}>
                                        <h2 id="child-modal-description">
                                          {mensaje}
                                        </h2>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="large"
                                          className={classes.buttonModal}
                                          onClick={() =>
                                            borrarCompra(props.history)
                                          }
                                        >
                                          Confirmar
                                        </Button>

                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="large"
                                          onClick={handleCloseBorrar}
                                          className={classes.buttonModal}
                                        >
                                          Cancelar
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </>
                                );
                              }
                            },
                          },
                        },
                      ]}
                    ></MUIDataTable>
                  ) : (
                    <MUIDataTable
                      title={!mostrarFechaFaena ? (
                        <span>
                          Compras con Fecha de Faena:{" "}
                          <strong>{moment(fechaBusqueda).format("DD/MM/YY")}</strong>
                        </span>
                      ) : (
                        ""
                      )}
                      data={datatableData}
                      options={option}//{Object.assign(option, optionsNormal2)}
                      columns={[
                        {
                          name: "Id",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Tipo Compra",
                          options: {
                            display: false,
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Faena",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Consignatario",
                          options: {


                            minWidth: 30,
                          },
                        },
                        {
                          name: "Remitente",
                          options: {


                            minWidth: 30,
                          },
                        },
                        {
                          name: "Destino",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Cuota",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Esp.",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Raza",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Reses",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Peso",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Obs",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Programa",
                          options: {

                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                            minWidth: 30,
                          },
                        },
                        {
                          name: "Est.",
                          options: {
                            setCellProps: () => ({
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                              },
                            }),
                          },
                        },

                      ]}
                    ></MUIDataTable>
                  )}
                </div>
              )}
            </Grid>
            <Modal
              hideBackdrop
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box className={classes.Modal}>
                <h2 id="child-modal-description">
                  Alias: {alias}
                  <br></br>
                  Cuit: {cuit}
                  <br></br>
                  BP: {bp}
                  <br></br>
                  Email: {email}
                </h2>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleClose}
                  className={classes.buttonModal}
                >
                  Cerrar
                </Button>
              </Box>
            </Modal>
            <Modal
              hideBackdrop
              open={openConfirmMail}
              onClose={handleCloseopenConfirmMail}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              {ModalEmail()}
              {/* <Box className={classes.ModalEmail}>
                <div>
                  <h2 id="child-modal-description">
                    {mensaje}

                  </h2>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={switchEmail}
                          onChange={handleSwitchEmail}
                          inputProps={{ 'aria-label': 'Enviar Email Personalizado' }}
                        />}
                      label="Email Personalizado"
                    />
                  </FormGroup>
                </div>
                {!switchEmail && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}>
                    <Typography variant="body2" color="secondary">{"Por default se enviara un email para confirmar/postegar la compra"}</Typography>
                  </div>
                )}
                {switchEmail && (
                  <div style={{ marginTop: '5px' }}>

                    <TextareaAutosize
                      rowsMin={3}
                      value={mensajeEmail}
                      onChange={handleMensajeEmail}
                      aria-label="Mensaje"
                      className={classes.textarea}
                      placeholder="Ingrese el mensaje a enviar"
                      style={{ width: '100%' }}
                    />

                  </div>
                )}
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size='large'
                    className={classes.buttonModal}
                    onClick={() => sendEmail(IndexRows, dataEmails)}
                  >Confirmar
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    size='large'
                    onClick={handleCloseopenConfirmMail}
                    className={classes.buttonModal}
                  >Cancelar</Button>
                </div>
              </Box> */}
            </Modal>
          </Grid>
          {/* </Widget> */}
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  a:{
    padding: '2px 3px',
    margin: '3px',
    fontSize: '0.7rem'
  },
  confirm:{
    margin: '15px',
    
  },
  title:{
    margin:'50px'
  },
 
  contained:{
    maxWidth:'100%',
  },
  Modal:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    padding:'10px',

    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: 1,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign:'center',
  },
  buttonModal:{
    margin:'10px'
  },
  buttonModalCancelar: {
    margin:'10px',
    color: theme.palette.success.main
  },
  buttonModalCompra: {
    margin:'10px',
    color: theme.palette.error.main
  },
  
  rojo:{
    color: theme.palette.spotlight.red,
    padding:0
  },
 
  rojoCircle: {
    background: theme.palette.spotlight.red,
    color:'white',
    padding:0
  },
  verde: {
    color: theme.palette.spotlight.green,
    padding:0

  },
  yellow: {
    color: theme.palette.spotlight.yellow,
    padding:0

  },
  orange: {
    color: theme.palette.dashboard.tres,
    padding:0

  },
  centeredHeader: {
    '& span': {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight:'bold',
    },
  },
  centeredHeaderO: {
    backgroundColor: 'orange',

    '& span': {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight:'bold',
    },
  },
  centeredHeaderG: {
    backgroundColor: 'green',

    '& span': {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight:'bold',
    },
    verde: {
      color: 'green',
      padding:0,
      textAlign:'center',
                    alignItems:'center'
  
    },
    yellow:{
      color: 'yellow',
      padding:0,
      textAlign:'center',
                    alignItems:'center'
    },
    red:{
      color: 'red',
      padding:0,

    },
  },
  nuevaCompraButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '@media (max-width: 992px)': {
      justifyContent: 'center',
    }
  },
  searchButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '@media (max-width: 992px)': {
      justifyContent: 'center',
    }
  }
}));

import React, { useState, useContext, useEffect, memo } from "react";
import {
  Grid,
  Button,
  TextField,
  InputLabel,
  Typography,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import useStyles from "./styles";
import moment from "moment";
import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";

const FormAjustes = memo(function FormAjustes({ datosCompra, ...props }) {
  //var user = JSON.parse(localStorage.getItem("userData"));
  const { user } = useContext(UserStateContext);
  var classes = useStyles();
  let canEditPrecio = user.permissions.compras_can_edit_precio_invernada === true;

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState("");

  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState("");
  var [fechaVencimiento, setFechaVencimiento] = useState(
    moment().add(1, "year").format("YYYY-MM-DD"),
  );

  // local
  var [consignatario, setConsignatario] = useState('');
  var [contacto, setContacto] = useState(() => {
    if (user.cuit != null) {
      return user.cuit;
    } else {
      return "";
    }
  });
  var [origen, setOrigen] = useState(() => {
    if (user.razonS != null) {
      return user.razonS;
    } else {
      return "";
    }
  });
  var [flete, setFlete] = useState("");
  var [fechaCarga, setFechaCarga] = useState("");
  var [feedlot, setFeedlot] = useState("");
  var [cantidad, setCantidad] = useState("");
  var [kilosAprox, setKilosAprox] = useState('');
  var [mermaReal, setMermaReal] = useState('');
  var [kilosAjustados, setKilosAjustados] = useState('');
  var [mermaAjustada, setMermaAjustada] = useState("");
  var [fechaAjuste, setFechaAjuste] = useState(moment().format("YYYY-MM-DD"));

  var [kilosBalanza, setKilosBalanza] = useState('');
  var [kilosNetos, setKilosNeto] = useState('');

  var [precio, setPrecio] = useState("0");
  var [precioActualizado, setPrecioActualizado] = useState("");

  var [desplRenspa, setDesplRenspa] = useState([]);
  var [edit, setEdit] = useState(false);
  var [ID, setID] = useState();
  var [idDatos, setIDDatos] = useState();

  //manejo de tiempos del servidor
  let [errorServidor, setErrorServidor] = useState(null);

  let [isLoading2, setIsLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  let [desplegables, setDespletables] = useState([]);
  let [sumarImg, setSumarImg] = React.useState(false);
  let [Image, setImage] = React.useState([]);

  // para capturar ok/error en la respuesta
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openFeedbackPrecio, setOpenFeedbackPrecio] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [editable, setEditable] = useState(false);
  const [gotResponse, setGotResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "headers_received") {
      setImage(file);
      //await UserService.postImagen(fotos)
      //setOpen(false)
      setSumarImg(false);
    }
  };

  const isDisabled = () => {
    return (
      kilosAjustados === null ||
      !gotResponse
    )
  }

  const kilosAjustadosYMermaAjust = (val) => {
    setKilosAjustados(val);
    if (val > 0) {
      setMermaAjustada(
        parseFloat(((val - kilosBalanza) / val) * 100).toFixed(2),
      );
    } else {
      setMermaAjustada("");
    }
  };

  //funcion para guardar doc
  const guardarDocumento = async () => {
    setResponseError(false);
    setLoading(true);
    setError("");
    setResponseMessage("");

    //const merma_ajustada = edit == false ? parseFloat(mermaAjustada).toFixed(2) : mermaAjustada;
    var parametros = [
      ID,
      parseFloat(mermaReal).toFixed(2),
      kilosAjustados,
      parseFloat(mermaAjustada).toFixed(2),
      fechaAjuste,
    ];
    try {
      let response;
      if (edit == false) {
        response = await UserService.postDatosAdjustesInv(...parametros);
      } else {
        parametros.unshift(idDatos)
        response = await UserService.putDatosAdjustesInv(...parametros);
      }
      if (response.status == 201 || response.status == 200) {
        //history.push("/app/feedLot");
        props.servidor(user);
        props.handleHasBeenEdited();
        props.cerrar();

      } else {
        throw new Error(response?.data?.Compra[0] || "Error al guardar los datos");
      }
    } catch (error) {
      setResponseError(true);
      setResponseMessage(error?.message || "Error al guardar los datos");
    } finally {
      setLoading(false);
      setOpenFeedback(true);
    }
  };


  const getDataInvernada = async () => {
    setLoading(true)
    try {
      let response = await UserService.getCompraInvernadaDetail(props.compraId)
      if (response.status === 404) {
        return null
      } else if (response.status !== 200) {
        throw new Error("Error al obtener los datos de la compra")
      }
      return response.data
    } catch (error) {
      setErrorServidor(true)
      setError("Error al obtener los datos de la compra")
    } finally {
      setLoading(false)
    }
  }

  const calculateMermaReal = (kilos_balanza, kilos_ticket, desbaste) => {
    if (kilos_balanza !== null) {
      const kilosTicketAjustados = Math.round(kilos_ticket * (1 - desbaste / 100))
      return parseFloat((kilosTicketAjustados - kilos_balanza) / kilosTicketAjustados).toFixed(4)
    }
    return 0
  }

  const calculateMermaAjustada = () => {
    if (kilosAjustados !== null) {
      return parseFloat((kilosAjustados - kilosBalanza) / kilosAjustados).toFixed(4)
    }
    return 0
  }

  useEffect(() => {
    setID(props.compraId)
    getDataInvernada().then(data => {
      setGotResponse(true)
      if (data) {
        setConsignatario(data.consignatario)
        setContacto(data.contacto_de_carga_id?.username)
        setOrigen(data.lugar_de_carga)
        setFlete(data.flete)
        setFechaCarga(data.fecha_de_carga)
        setFeedlot(data.feedlot_id?.nombre)
        setCantidad(data.cabezas_compradas)
        setKilosAprox(data.peso)
        setPrecio(data.precio)
        if (data?.invernada) {
          setEdit(true)
          setIDDatos(data.invernada?.id)
          setKilosAjustados(data.invernada?.kilos_ajustados)
          setKilosBalanza(data.invernada?.kilos_balanza)
          setKilosNeto(data.invernada?.kilos_neto)
          setMermaReal(calculateMermaReal(data.invernada?.kilos_balanza, data.invernada?.kilos_ticket, data.desbaste))

          if (data.invernada?.fecha_ajuste) {
            setFechaAjuste(data.invernada?.fecha_ajuste)
          }
        }
      }
    })

  }, []);

  useEffect(() => {
    if (kilosAjustados > 0) {
      setMermaAjustada(calculateMermaAjustada())
    }
  },[kilosAjustados])


  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  const handleCloseFeedbackPrecio = () => {
    setOpenFeedbackPrecio(false);
  };

  const actualizarPrecio = async (precio) => {
    setError("");
    setIsLoading2(true);
    setResponseError(false);
    if (precio !== "") {
      await UserService.putPrecioAjustesInv(ID, precio).then(
        (response) => {
          if (response.status == 200) {
            //history.push("/app/feedLot");
            setPrecio(precio);
            setResponseMessage("Precio actualizado con éxito")
          }
          if (response.status == 403) {
            setErrorServidor(true);
            setResponseError(true);
            setError(<p>Hubo un error al modificar el precio</p>);
          }
        },
        (error) => {
          setErrorServidor(true);
          setError(<p>Por favor complete todos los campos</p>);
        },
      );
    } else {
      setErrorServidor(true);
      setResponseError(true);
      setError(<p>Por favor, ingrese un precio válido</p>);
    }

    setIsLoading2(false);
    setOpenFeedbackPrecio(true);
  };

  return (
    <>
      <div className={classes.marginNormal}>
        <Fade in={errorServidor}>
          <Typography color="secondary" className={classes.errorMessage}>
            {error}
          </Typography>
        </Fade>
      </div>

      <div className={classes.form}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Ajustes
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Consignatario"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={consignatario}
                onChange={(e) => setConsignatario(e.target.value)}
                margin="normal"
                placeholder="Consignatario"
                variant="outlined"
                label="Consignatario"
                type="text"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Contacto"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={contacto}
                onChange={(e) => setContacto(e.target.value)}
                margin="normal"
                placeholder="Contacto"
                variant="outlined"
                label="Contacto"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Origen"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={origen}
                onChange={(e) => setOrigen(e.target.value)}
                margin="normal"
                placeholder="Origen"
                variant="outlined"
                label="Origen"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Flete"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={flete}
                onChange={(e) => setFlete(e.target.value)}
                margin="normal"
                placeholder="Flete"
                variant="outlined"
                label="Flete"
                type="text"
                disabled
                fullWidth
              />
            </Grid>

            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="F. Carga"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={fechaCarga}
                onChange={(e) => setFechaCarga(e.target.value)}
                margin="normal"
                placeholder="F. Carga"
                variant="outlined"
                label="F. Carga"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Feedlot"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={feedlot}
                onChange={(e) => setFeedlot(e.target.value)}
                margin="normal"
                placeholder="Feedlot"
                variant="outlined"
                label="Feedlot"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Cantidad"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
                margin="normal"
                placeholder="Cantidad"
                variant="outlined"
                label="Cantidad"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Kilos Aprox"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={kilosAprox}
                onChange={(e) => setKilosAprox(e.target.value)}
                margin="normal"
                placeholder="Kilos Aprox"
                variant="outlined"
                label="Kilos Aprox"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Merma Real"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                value={`${(mermaReal * 100).toFixed(2)}%`}
                margin="normal"
                placeholder="Merma Real"
                variant="outlined"
                label="Merma Real"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Kilos Ajustados"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                value={kilosAjustados}
                onChange={(e) => kilosAjustadosYMermaAjust(e.target.value)}
                margin="normal"
                placeholder={`Kilos Ajustados. Los netos cargados fueron ${kilosNetos}`}
                variant="outlined"
                label="Kilos Ajustados"
                type="numeric"
                error={kilosAjustados === null}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Merma Ajustada"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                value={`${(mermaAjustada * 100).toFixed(2)}%`}
                margin="normal"
                placeholder="Merma Ajustada"
                variant="outlined"
                label="Merma Ajustada"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel id="type">Fecha Ajuste</InputLabel>
              <ResponsiveDatePickers
                className={classes.textField}
                value={fechaAjuste}
                error={false}
                inputFormat="dd-MMMM-yyyyy"
                setValorFecha={(e) => {
                  setFechaAjuste(e);
                }}
              ></ResponsiveDatePickers>
            </Grid>
            <Grid item lg={6} xs={12} sm={12}>
              <TextField
                id="Precio Inicial"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                classes={{ disabled: classes.disabled }}
                InputLabelProps={{ shrink: true }}
                value={precio}
                margin="normal"
                placeholder="Precio Inicial"
                variant="outlined"
                label="Precio Inicial"
                type="text"
                disabled
                fullWidth
              />
            </Grid>
            {canEditPrecio && (
              <>
                <Grid item lg={2} xs={6} sm={6}>
                  <TextField
                    id="Modificar Precio"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setPrecioActualizado(e.target.value)}
                    margin="normal"
                    placeholder="Modificar Precio"
                    variant="outlined"
                    label="Modificar Precio"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  lg={2}
                  xs={6}
                  sm={4}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    marginTop: "5px",
                  }}
                >
                  <>
                    {isLoading2 ? (
                      <CircularProgress
                        size={20}
                        style={{
                          marginLeft: "90px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          actualizarPrecio(precioActualizado);
                          document.getElementById("Modificar Precio").value = ""; // Limpiar el campo de entrada
                          setPrecioActualizado("")
                        }}
                        variant="outlined"
                        color="primary"
                        className={classes.createAccountButton}
                      >
                        Actualizar&nbsp;Precio
                      </Button>
                    )}
                  </>
                </Grid>
              </>
            )}
            <Snackbar
              open={openFeedbackPrecio}
              autoHideDuration={2000}
              onClose={handleCloseFeedbackPrecio}
            >
              <Alert
                elevation={6}
                variant="filled"
                severity={responseError ? "error" : "success"}
              >
                <AlertTitle>
                  {responseError
                    ? error
                    : "Precio actualizado con éxito"}
                </AlertTitle>
              </Alert>
            </Snackbar>
            {/* <Grid item lg={12} xs={12} sm={12}>
                   <Dropzone
                    classNames={classes.dropZone}
                    styles={{ dropzone: { overflow: 'hidden', margin:'10px 0px'}, submitButton: {padding:'15px',
                    fontSize: '1.2em',}   }}
                    /* getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    onSubmit={handleSubmit}
                    PreviewComponent={Preview} 
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}


                    accept="image/*,.pdf"
                    inputContent='Arrastra tus archivos o hace click aqui'
                   /*  submitButtonContent="Guardar Datos"
                    inputWithFilesContent='Sumar otro archivo' 
                      /> 
                  </Grid>  */}
          </Grid>

          <Grid
            alignContent="center"
            justify="center"
            container
            spacing={1}
            lg={12}
            xs={12}
            sm={12}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Grid item lg={3} xs={12} sm={12}>
              {loading ? (
                <CircularProgress
                  size={20}
                  style={{
                    marginLeft: "90px",
                    alignItems: "center",
                    display: "flex",
                  }}
                />
              ) : (
                <Button
                  fullWidth
                  onClick={() => guardarDocumento(props.history)}
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={
                    isDisabled()
                  }
                  className={classes.createAccountButton}
                //component={Link} to={`/app/gestionDoc`}
                >
                  GUARDAR
                </Button>
              )}
            </Grid>
            <Grid item lg={3} xs={12} sm={12}>
              <Button
                className={classes.createAccountButton}
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                //component={Link}
                //to={`/app/feedLot`}
                onClick={props.cerrar}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={openFeedback}
        autoHideDuration={2000}
        onClose={handleCloseFeedback}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={responseError ? "error" : "success"}
        >
          <AlertTitle>
            {responseMessage}
          </AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
})
export default withRouter(FormAjustes);

import React, { useState, useContext, useEffect, memo } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Fade,
} from "@material-ui/core";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import moment from "moment";
import Dropzone from 'react-dropzone-uploader'
import { useHistory, withRouter } from 'react-router';
import { Preview } from "../variables/variables";
import { useTheme } from "@material-ui/styles";
import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import './styilo.css'
const FormInvOrigen = memo(function FormInvOrigen({ datosCompra, ...props }) {
  //var user = JSON.parse((localStorage.getItem('userData')))
  const history = useHistory();
  var classes = useStyles();
  //var rol = localStorage.getItem('permissions')
  const theme = useTheme();
  const { user } = useContext(UserStateContext)
  const permissions = user.permissions;
  const isInterno = user.type === 'Rioplatense'

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');


  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState(moment().format('YYYY-MM-DD'))
  var [fechaVencimiento, setFechaVencimiento] = useState(moment().add(1, 'year').format('YYYY-MM-DD'))


  // local
  var [consignatario, setConsignatario] = useState('');
  var [contacto, setContacto] = useState(() => { if (user.cuit != null) { return user.cuit } else { return '' } });
  var [origen, setOrigen] = useState(() => { if (user.razonS != null) { return user.razonS } else { return '' } });
  var [flete, setFlete] = useState("");
  var [fechaCarga, setFechaCarga] = useState("");
  var [feedlot, setFeedlot] = useState("");
  var [cantidad, setCantidad] = useState("");
  var [kilosAprox, setKilosAprox] = useState("");
  var [kilosTicket, setKilosTicket] = useState('');
  var [kilosNeto, setKilosNeto] = useState("");
  var [cantidadCargada, setCantidadCargada] = useState('');
  var [promedio, setPromedio] = useState();
  var [horarioPesada, setHorarioPesada] = useState(moment().format('HH:mm'));

  var [desbaste, setDesbaste] = useState();

  var [desplRenspa, setDesplRenspa] = useState([])
  var [edit, setEdit] = useState(false);
  var [ID, setID] = useState();

  var [idDatos, setIDDatos] = useState();

  //manejo de tiempos del servidor
  let [errorServidor, setErrorServidor] = useState(null);

  let [isLoading2, setIsLoading2] = useState(false);
  let [desplegables, setDespletables] = useState([]);
  let [sumarImg, setSumarImg] = React.useState(false);
  let [Image, setImage] = React.useState([]);

  // feedback
  const [openFeedback, setOpenFeedback] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
    if (!responseError && !isInterno) {
        props.servidor(user)
        props.cerrar()
      }
  }

  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))
    setFechaVencimiento(moment(event).add(1, 'year').format("YYYY-MM-DD"))
  }

  //get Renspa
  const cantidadYPromedio = (val) => {
    setCantidadCargada(val);
    if (kilosNeto != undefined) {
      setPromedio(parseFloat(kilosNeto / val).toFixed(2))


    }
  }

  const kilosPromedio = (val) => {
    setKilosTicket(val); setKilosNeto(Math.round(val * (1 - desbaste)).toFixed(2));
    if (cantidadCargada != undefined) {
      setPromedio(parseFloat(val * (1 - desbaste) / cantidadCargada).toFixed(2))

    }
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'headers_received') {
      toast(`${meta.name} Subido!`, 'color:#05f105;font-size:1.2rem')
      remove()
      setImage(file)
      //await UserService.postImagen(fotos)
      //setOpen(false)
      setSumarImg(false)
    }
    else if (status === 'aborted') {
      toast(`${meta.name}, Error en la subida del archivo...`, 'color:red;font-size:1.2rem')
    }

  }

  //funcion para guardar doc
  const guardarDocumento = async () => {
    setErrorServidor(false)
    setError('')
    setResponseMessage('')
    setResponseError(false)
    setIsLoading2(true)
    if (edit == false) {
      await UserService.postDatosOrigenInv(ID, kilosTicket, kilosNeto, cantidadCargada, parseFloat(promedio).toFixed(2), Image, horarioPesada).then(
        response => {
          if (response.status == 201 || response.status == 200) {
            setResponseMessage("Datos guardados correctamente");
            setOpenFeedback(true);
            props.handleHasBeenEdited()
          }
          if (response.status != 201 && response.status != 200) {
            setErrorServidor(true)
            setError(<p>Ya hay cargado un documento con el CUIT-RENSPA todavia esta vigente</p>)
            setOpenFeedback(true);
            setResponseError(true);
            setResponseMessage("Ocurrio un error al guardar los datos, por favor intente nuevamente");
          }
        },
        error => {
          //console.log(error)
          setErrorServidor(true)
          setError(<p>Por favor complete todos los campos</p>)
          setOpenFeedback(true);
          setResponseError(true);
          setResponseMessage("Ocurrio un error al guardar los datos, por favor intente nuevamente");
        }
      )
      setIsLoading2(false)
    } else {
      await UserService.putDatosOrigenInv(idDatos, ID, kilosTicket, kilosNeto, cantidadCargada, parseFloat(promedio).toFixed(2), Image, horarioPesada).then(
        response => {
          if (response.status == 200) {
            setResponseMessage("Datos guardados correctamente");
            setOpenFeedback(true);
            props.handleHasBeenEdited()
          }
        },
        error => {
          setErrorServidor(true)
          setError(<p>Por favor complete todos los campos</p>)
          setOpenFeedback(true);
          setResponseError(true);
          setResponseMessage("Oucrrio un error al guardar los datos, por favor intente nuevamente");
        }
      )
      setIsLoading2(false)
    }
  }

  const toast = (innerHTML, clase) => {
    const el = document.getElementById('toast')
    el.innerHTML = innerHTML
    el.style = clase
  }

  const getDataInvernada = async () => {
    try {
      let response = await UserService.getCompraInvernadaDetail(props.compraId)
      if (response.status === 404) {
        return null
      } else if (response.status !== 200) {
        throw new Error("Error al obtener los datos de la compra")
      }
      return response.data
    } catch (error) {
      setErrorServidor(true)
      setError("Error al obtener los datos de la compra")
    }
  }

  useEffect(() => {
    setID(props.compraId)
    getDataInvernada().then(data => {
      if (data) {
        setConsignatario(data.consignatario)
        setContacto(data.contacto_de_carga_id?.username)
        setOrigen(data.lugar_de_carga)
        setFlete(data.flete)
        setFechaCarga(data.fecha_de_carga)
        setFeedlot(data.feedlot_id?.nombre)
        setCantidad(data.cabezas_compradas)
        setKilosAprox(data.peso)
        setDesbaste(data.desbaste / 100)
        if (data?.invernada) {
          setEdit(true)
          setIDDatos(data.invernada?.id)
          setKilosTicket(data.invernada?.kilos_ticket)
          setKilosNeto(data.invernada?.kilos_neto)
          setCantidadCargada(data.invernada?.cantidad_cargada)
          setPromedio(data.invernada?.promOrigen)

          if (data.invernada?.horario_pesada_origen) {
            setHorarioPesada(data.invernada?.horario_pesada_origen)
          }
        }
      }
    })

  }, []);

  return (
    <>

      <div className={classes.marginNormal}>
        <Fade in={errorServidor}>
          <Typography color="secondary" className={classes.errorMessage}>
            {error}
          </Typography>
        </Fade>
      </div>

      <div className={classes.form}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Datos de Origen
            </Typography>


          </Grid>


          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Consignatario" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={consignatario}
                onChange={e => setConsignatario(e.target.value)}
                margin="normal"
                placeholder="Consignatario"
                variant="outlined"
                label="Consignatario"
                type="text"
                fullWidth
                disabled

              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Contacto" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={contacto}
                onChange={e => setContacto(e.target.value)}
                margin="normal"
                placeholder="Contacto"
                variant="outlined"
                label="Contacto"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Origen" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={origen}
                onChange={e => setOrigen(e.target.value)}
                margin="normal"
                placeholder="Origen"
                variant="outlined"
                label="Origen"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Flete" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={flete}
                onChange={e => setFlete(e.target.value)}
                margin="normal"
                placeholder="Flete"
                variant="outlined"
                label="Flete"
                type="text"
                disabled
                fullWidth />
            </Grid>

            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="F. Carga" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={fechaCarga}
                onChange={e => setFechaCarga(e.target.value)}
                margin="normal"
                placeholder="F. Carga"
                variant="outlined"
                label="F. Carga"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Feedlot" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={feedlot}
                onChange={e => setFeedlot(e.target.value)}
                margin="normal"
                placeholder="Feedlot"
                variant="outlined"
                label="Feedlot"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Cantidad" InputProps={{
                inputProps: { min: 0 },
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={cantidad}
                onChange={e => setCantidad(e.target.value)}
                margin="normal"
                placeholder="Cantidad"
                variant="outlined"
                label="Cantidad"
                type="number"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Kilos Aprox" InputProps={{
                inputProps: { min: 0 },
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={kilosAprox}
                onChange={e => setKilosAprox(e.target.value)}
                margin="normal"
                placeholder="Kilos Aprox"
                variant="outlined"
                label="Kilos Aprox"
                type="number"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Kilos Ticket" InputProps={{
                inputProps: { min: 0 },

                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                onWheel={event => event.target.blur()}
                value={kilosTicket}
                onChange={e => { if (e.target.value >= 0) { kilosPromedio(e.target.value) } }}
                margin="normal"
                placeholder="Kilos Ticket"
                variant="outlined"
                label="Kilos Ticket"
                type="number"
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Kilos Neto" InputProps={{
                inputProps: { min: 0 },
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                InputLabelProps={{ shrink: true }}

                value={kilosNeto}
                onChange={e => setKilosNeto(Math.round(e.target.value).toFixed(2))}
                margin="normal"
                placeholder="Kilos Neto"
                variant="outlined"
                label="Kilos Neto"
                type="number"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Cantidad Cargada" InputProps={{
                inputProps: { min: 0 },
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                onWheel={event => event.target.blur()}
                value={cantidadCargada}
                onChange={e => { if (e.target.value >= 0) { cantidadYPromedio(e.target.value) } }}
                margin="normal"
                placeholder="Cantidad Cargada"
                variant="outlined"
                label="Cantidad Cargada"
                type="number"
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Prom." InputProps={{
                inputProps: { min: 0 },
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                InputLabelProps={{ shrink: true }}
                value={promedio}
                onChange={e => setPromedio(e.target.value)}
                margin="normal"
                //placeholder="Prom."
                variant="outlined"
                label="Prom."
                type="number"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              <div id="toast"></div>

              <Dropzone
                classNames={classes.dropZone}
                styles={{
                  dropzone: {
                    color: theme.palette.primary.main,
                    overflow: 'hidden',
                    margin: '10px 0px',
                    borderColor: theme.palette.tables.tableBorderColor
                  },
                  submitButton: {
                    padding: '15px',
                    fontSize: '1.2em',
                  },
                  preview: {
                    color: theme.palette.primary.main
                  },
                  inputLabel: {
                    color: theme.palette.primary.main
                  }
                }}
                /* getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                onSubmit={handleSubmit}*/
                PreviewComponent={Preview}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}


                accept="image/*,.pdf"
                inputContent='Arrastra tus archivos o hace click aqui'
              /*  submitButtonContent="Guardar Datos"
               inputWithFilesContent='Sumar otro archivo' */
              />
            </Grid>

            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Horario Pesada"


                value={horarioPesada}
                onChange={e => setHorarioPesada(e.target.value)}
                margin="normal"

                color="secondary"
                placeholder="Horario Pesada"
                variant="outlined"
                label="Horario Pesada"
                type="time"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth />

            </Grid>





          </Grid>

          <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{ justifyContent: 'center', display: 'flex' }} justify="center">
            <Grid item lg={3} xs={12} sm={12}>
              <Button
                fullWidth
                onClick={() => guardarDocumento(props.history)}
                //UserService.generatePDF()
                //guardarDocumento(props.history)


                /*(fecha_de_alta,fecha_de_vencimiento,cuitProveedor,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
                   capacitacionPersonal,fecha_de_capacitacionPersonal,
                   manga,corralCierre,rampa,manejoDeAnimales,descripcionManejoAnimales,manejodeCarga,descripcionManejoCarga, email, doc_url) 
               }   */
                size="large"
                variant="contained"
                color="primary"
                disabled={kilosTicket?.length === 0 ||
                  cantidadCargada?.length === 0 
                }

                className={classes.createAccountButton}
              //component={Link} to={`/app/gestionDoc`}
              >

                GUARDAR
              </Button>
            </Grid>
            <Grid item lg={3} xs={12} sm={12}>
              <Button
                className={classes.createAccountButton}
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                //component={Link} to={`/app/Feedlot`}
                onClick={props.cerrar}>


                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={openFeedback}
        autoHideDuration={2000}
        onClose={handleCloseFeedback}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={responseError ? "error" : "success"}
        >
          <AlertTitle>
            {responseMessage}
          </AlertTitle>
        </Alert>
      </Snackbar>

    </>
  );
})
export default withRouter(FormInvOrigen);
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import { Alert, Collapse, useMediaQuery, AlertTitle, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./style.css";
import PercentIcon from "@mui/icons-material/Percent";

import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import ContactoCarga from "../../components/ContactoCarga/ContactoCarga";
import { set } from "date-fns";
import EstadisticasTable from "../../components/estadisticasTable/EstadisticasTable";
import jwt_decodde from "jwt-decode";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTheme } from "@material-ui/styles";
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '@mui/material/FormGroup';

import { styled } from "@mui/material/styles";


const ToggleButton = styled(MuiToggleButton)(
  ({ textColor, selectedColor, borderColor, disabledColor }) => ({
    color: "inherit",
    borderColor: borderColor,
    borderRadius: "15px",
    textTransform: "capitalize",
    maxHeight: "60px",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: textColor,
      backgroundColor: selectedColor,
    },
    '&.Mui-disabled': {
      color: disabledColor,
      borderColor: borderColor,
    }
  }),
);

const EST_FR = 1001;

function NuevasCompras(props) {
  var classes = useStyles();
  var theme = useTheme();
  const params = new URLSearchParams(props.location.search);

  var isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //generals
  var [error, setError] = useState("");

  //usuario
  //var user = JSON.parse((localStorage.getItem('userData')))
  const { user } = useContext(UserStateContext);

  // error
  var [errorPeso, setErrorPeso] = useState(false);
  var [errorPrecio, setErrorPrecio] = useState(false);
  var [precioTimeOut, setPrecioTimeOut] = useState(null);

  // local
  var [tipoCompra, settipoCompra] = useState(() => {
    let tipoDeCompra = params.get('tipo');
    if (tipoDeCompra) {
      return tipoDeCompra;
    }
    return "Faena";
  }
  );
  var [fechaCompra, setFechaCompra] = useState(moment().format("YYYY-MM-DD"));
  var [fechaCarga, setFechaCarga] = useState(null);
  var [fechaNegocio, setFechaNegocio] = useState(null);
  var [fechaFaena, setFechaFaena] = useState("");
  var [comprador, setComprador] = useState(() => {
    if (user.rol == 1 || user.rol == 2) {
      return user.email;
    } else {
      return "";
    }
  });
  var [planta, setPlanta] = useState("1");
  var [status, setStatus] = useState("SinConfirmar");
  var [estadisticas, setEstadisticas] = useState(false);
  var [consignatario, setConsignatario] = useState("");
  var [remitente, setRemitente] = useState("");
  var [destinoComercial, setDestinoComercial] = useState("");
  var [cuota, setCuota] = useState("");
  var [raza, setRaza] = useState("");
  var [especie, setEspecie] = useState("");
  var [programa, setPrograma] = useState("");
  var [cuotaInvernada, setCuotaInvernada] = useState(0);
  var [comisionista, setComisionista] = useState("");
  var [procedencia, setProcedencia] = useState("");
  var [observaciones, setObservaciones] = useState("");

  // invernada
  var [contactoDeCarga, setContactoDeCarga] = useState(null);
  var [lugarDeCarga, setLugarDeCarga] = useState(null);
  var [feedlot, setFeedlot] = useState(null);
  var [flete, setFlete] = useState("");
  const [condicionPago, setCondicionPago] = useState("");
  //mensaje de alerta
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  // Mostrar o no el campo para indicar el rinde
  var [conRinde, setConRinde] = useState(false);
  var [rinde, setRinde] = useState(100);
  const [rindeError, setRindeError] = useState(false);

  //campos con numero
  var [cabezasComp, setCabezasComp] = useState("");
  var [precio, setPrecio] = useState("");
  var [peso, setPeso] = useState("");
  var [comision, setComision] = useState(null);
  var [plazo, setPlazo] = useState("");
  var [plazoArray, setPlazoArray] = useState(Array(cuotaInvernada));

  var [Desbaste, setDesbaste] = useState(null);
  var [km, setKm] = useState("");
  var [JaulaS, setJaulaS] = useState("");
  var [JaulaD, setJaulaD] = useState("");
  var [cantidad, setCantidad] = useState(0);
  const [precioFlete, setPrecioFlete] = useState("");
  const [gastosAdicionales, setGastosAdicionales] = useState(0);

  // cambio de formulario
  const [hide, setHide] = useState(false);

  //valoresCheck
  const [checkDestino, setCheckDestino] = useState([]);
  const [checkCuota, setCheckCuota] = useState([]);
  const [checkRaza, setCheckRaza] = useState([]);
  const [checkEspecie, setCheckEspecie] = useState([]);
  const [checkPrograma, setCheckPrograma] = useState([]);

  // valores desplegables
  const [desplegables, setDespletables] = useState([]);
  const [condicionesPago, setCondicionesPago] = useState([]);
  const [contactoDC, setContactoDC] = useState([]);
  const [feedlotDesp, setFeedlotDesp] = useState([]);
  const [CompradoresDeslp, setCompradoresDeslp] = useState([]);
  const [campos, setCampos] = useState([]);
  //state consignatarios
  const [mostrarConsig, setMostrarConsig] = useState(true);
  const [mostrarRemitente, setMostrarRemitente] = useState(true);
  const [mostrarComisionista, setMostrarComisionista] = useState(true);
  const [mostrarFlete, setMostrarFlete] = useState(true);
  var [isLoading2, setIsLoading2] = useState(false);

  // esconder cuota segun tipo
  var [UE, setUE] = useState(false);
  // data Estadiscita
  var [estadisticasData, setEstadisticasData] = useState([]);
  var [hayEstadisticas, setHayEstadisticas] = useState(false);

  const [openWarningEmail, setOpenWarningEmail] = useState(false);

  const [isPropia, setIsPropia] = useState(false);
  var [isNop, setIsNop] = useState(false);
  var [showNop, setShowNop] = useState(false);
  const [campo, setCampo] = useState(null);
  const [isRecria, setIsRecria] = useState(false);
  const [isFeedlot, setIsFeedlot] = useState(true);

  const [establecimientos, setEstablecimientos] = useState([]);
  const [selectedEstablecimiento, setSelectedEstablecimiento] = useState(EST_FR);

  const [compraCreadaId, setCompraCreadaId] = useState(null);


  /////metodos para cambio de check y visualizacion de datos
  const [alignment, setAlignment] = React.useState("Faena");
  const [alignmentStatus, setAligmentStatus] = React.useState("SinConfirmar");
  const [alignmentDestino, setAligmentDestino] = React.useState("");
  const [alignmentCuota, setAligmentCuota] = React.useState("");
  const [alignmentRaza, setAligmentRaza] = React.useState("");
  const [alignmentEspecie, setAligmentEspecie] = React.useState("");
  const [alignmentPrograma, setAligmentPrograma] = React.useState(
    "",
  );
  //modal
  const [mensaje, setMensaje] = useState("");
  const [hqf, setHQF] = useState(() => {
    if (cuota == "Hilton" || destinoComercial != "Exportación UE") {
      return false;
    }
  });

  const [verHQF, setVerHqf] = useState(false);

  //color toogle
  var [errorToggleStatus, setErrorToggleStatus] = useState(
    theme.palette.error.dark,
  );
  var [errorToggleDestino, setErrorToggleDestino] = useState(
    theme.palette.error.dark,
  );
  var [errorToggleCuota, setErrorToggleCuota] = useState(
    theme.palette.error.dark,
  );
  var [errorToggleRaza, setErrorToggleRaza] = useState(
    theme.palette.error.dark,
  );
  var [errorToggleEspecie, setErrorToggleEspecie] = useState(
    theme.palette.error.dark,
  );
  var [errorTogglePrograma, setErrorTogglePrograma] = useState(
    theme.palette.error.dark,
  );

  const [timeoutId, setTimeoutId] = useState(null);

  //mensaje de alerta
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = (value) => {
    // fila de la los datos para dsp buscar el ID

    setOpenModal(true);
  };

  const handleChangeTypeFeedlot = (event) => {
    setIsFeedlot(event.target.checked);
    if (event.target.checked === true) {
      setIsRecria(false);
    }
  }
  const handleChangeTypeRecria = (event) => {
    setIsRecria(event.target.checked);
    if (event.target.checked === true) {
      setIsFeedlot(false);
    }
  }

  const handleChangeCampo = (event) => {
    setCampo(event.target.value);
  }

  // chequear el campo precio despues de 500 ms de que se deje de escribir
  const handlePrecioChange = (value) => {
    setPrecio(value);
    clearTimeout(precioTimeOut);
    if (value < 800 && value != 0) {
      const precioTimer = setTimeout(() => {
        if (value < 800) {
          setErrorPrecio(true);
          setPrecio(800);
        }
      }, 1500);
      setPrecioTimeOut(precioTimer);
    }
  };

  const getCondicionesDePago = () => {
    UserService.getCondicionesPago()
      .then((response) => {
        if (response.status === 200) {
          setCondicionesPago(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // useEffect para mostrar u ocultar Nop
  useEffect(() => {
    if (programa === "ORGANIC") {
      setShowNop(true);
    } else {
      setShowNop(false);
      setIsNop(false);
    }
  }, [programa]);

  // useEffect para invernada
  useEffect(() => {
    if (tipoCompra === 'Invernada') {
      setIsPropia(true);
      setHide(true);
      clearState("Invernada");
      if (condicionesPago.length === 0) {
        getCondicionesDePago();
      }
    } else {
      setIsPropia(false);
      setHide(false);
      clearState("Faena");
      setFechaNegocio(null);
    }
  }, [tipoCompra]);

  function handleBlur() {
    // Clear the timeout when the component loses focus
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  function handleFechaFaenaChange(e) {
    setFechaFaena(moment(e).format("YYYY-MM-DD"));

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to check the date after 500ms
    const newTimeoutId = setTimeout(() => {
      if (moment(e).isValid() && moment(e).isBefore(moment(), 'day')) {
        setOpen(true);
      }
    }, 500);

    // Save the timeout ID so we can clear it later if needed
    setTimeoutId(newTimeoutId);
  }

  const handleClose = () => setOpenModal(false);
  //funcion para desabilitar el hqf
  const cuotaResult = () => {
    if (cuota == "Hilton" || destinoComercial != "Exportación UE") {
      return true;
    } else {
      return false;
    }
  };

  // desabilitar programa
  const disabledPrograma = (programa) => {
    if (programa === 'GRAIN FED') {
      if (destinoComercial === 'Exportación UE' && cuota === 'Hilton' && tipoCompra === 'Faena') {
        return true;
      } else if (!['Exportación UE', 'Exportación TP'].includes(destinoComercial) && destinoComercial.length > 1) {
        return true;
      }
      return false;
    }
    else if (programa === 'NATURAL' || programa === 'ORGANIC') {
      if (destinoComercial === 'Exportación UE' && ['Cuota 481', 'No Hilton'].includes(cuota) && cuota.length > 1) {
        return true;
      } else if (!['Exportación UE', 'Exportación TP'].includes(destinoComercial) && destinoComercial.length > 1) {
        return true;
      }
      return false;
    }
    return false;
  }

  // buscar las estadiscias
  const getEstadisticas = (value) => {
    UserService.getEstadisticas(value, especie).then((response) => {
      if ((response.status = 200)) {
        setEstadisticasData(response.data);
        setHayEstadisticas(true);
      } else {
        setHayEstadisticas(false);
      }
    });
  };

  // Checkear Si tiene Documentacion!
  const checkGeneral = (value) => {
    UserService.getcheckGeneral(value).then((response) => {
      if ((response.status = 200)) {
        if (response.data.data.length == 0) {
          setOpenWarning(true);
        }
      }
    });
  };
  // si selecciona UE mostrar cuota
  const UEcambio = (value) => {
    if (
      (value == "Exportación UE" && cuota != "Hilton") ||
      programa == "GRAIN FEED"
    ) {
      setUE(true);
    } else {
      setUE(false);
    }
  };

  function handleClickStatus(event) {
    if (event.target.value === status) {
      setStatus("");
      setStatus("");
    } else {
      setStatus(event.target.value);
    }
  }
  function handleClickDestino(event) {
    setPrograma("");
    setAligmentPrograma("");
    setErrorTogglePrograma("red");
    setVerHqf(false);
    setHQF(false);

    if (event.target.value == "Exportación UE") {
      setUE(true);
      //if(cuota!='Hilton' && programa=='GRAIN FEED'){setVerHqf(true)}
    } else {
      setAligmentDestino("");
      setErrorToggleDestino("red");
      setAligmentCuota();

      //setVerHqf(true)

      setUE(false);
      setCuota("");
    }
    if (
      event.target.value === destinoComercial &&
      event.target.value == "Exportación UE"
    ) {
      setDestinoComercial("");
      setErrorToggleDestino("red");

      setUE(false);
      setCuota("");
      setErrorToggleCuota("red");
      setAligmentDestino("");
      setErrorToggleDestino("red");

      //setVerHqf(true)
    } else {
      setDestinoComercial(event.target.value);
      setAligmentDestino(event.target.value);
      setErrorToggleDestino(theme.palette.primary.main);

      setCuota("");
      setErrorToggleCuota("red");

      //setVerHqf(true)
    }
    // cambio estado de hqf
    if (event.target.value != "Exportación UE") {
      setHQF(false);
      setVerHqf(false);
    }
  }

  function handleClickCuota(event) {
    setPrograma("");
    setAligmentPrograma("");
    setErrorTogglePrograma("red");

    setVerHqf(false);
    setHQF(false);
    if (event.target.value === cuota) {
      setCuota("");
      setAligmentCuota("");
      //setVerHqf(true)
      setErrorToggleCuota("red");
    } else {
      setCuota(event.target.value);
      setAligmentCuota(event.target.value);
      setErrorToggleCuota(theme.palette.primary.main);

      //setVerHqf(true)
    }

    if (event.target.value == "Hilton") {
      setHQF(false);
      setVerHqf(false);
    }
  }

  function handleClickRaza(event) {
    if (event.target.value === raza) {
      setRaza("");
      setAligmentRaza("");
      setErrorToggleRaza("red");
    } else {
      setRaza(event.target.value);
      setAligmentRaza(event.target.value);
      setErrorToggleRaza(theme.palette.primary.main);
    }
  }
  function handleClickEspecie(event) {
    if (event.target.value === especie) {
      setEspecie("");
      setAligmentEspecie("");
      setErrorToggleEspecie("red");
    } else {
      setEspecie(event.target.value);
      setAligmentEspecie(event.target.value);
      setErrorToggleEspecie(theme.palette.primary.main);
    }
  }

  function handleClickPrograma(event) {
    if (event.target.value === programa) {
      setPrograma("");
      setAligmentPrograma("");
      setErrorTogglePrograma("red");
    } else {
      setAligmentPrograma(event.target.value);
      setPrograma(event.target.value);
      setErrorTogglePrograma(theme.palette.primary.main);
    }

    if (
      event.target.value == "GRAIN FED" &&
      cuota != "Hilton" &&
      destinoComercial == "Exportación UE"
    ) {
      setVerHqf(true);
    } else {
      setVerHqf(false);
      setHQF(false);
    }
  }

  const compradorEst = () => {
    if (user.rol == 2) {
      return true;
    } else {
      return false;
    }
  };

  // segun compra faena o invernada.
  const handleTipoCompra = (e) => {
    if (e.target.value != tipoCompra) {
      settipoCompra(e.target.value);
      setAlignment(e.target.value);
    }
  };

  // renovar state
  const clearState = (tc) => {
    //    setFechaCompra('');
    if (tc == "Invernada") {
      setFechaFaena(null);
    } else {
      setFechaFaena("");
    }
    setFechaCarga(null);
    setPlanta("1");
    setStatus("SinConfirmar");
    setEstadisticas(false);

    setConsignatario("");
    setRemitente("");
    setDestinoComercial("");
    setCuota("");
    setUE(false);

    setRaza("");
    setEspecie("");
    setPrograma("");
    setCuotaInvernada("");
    setComisionista("");
    setProcedencia("");
    setObservaciones("");
    setContactoDeCarga("");
    setLugarDeCarga("");
    setFeedlot("");
    setFlete("");
    //campos con numero
    if (tc == "Faena") {
      setDesbaste(null);
      setKm(0);
      setJaulaS(0);
      setJaulaD(0);
      setPrecioFlete(0);
    }

    setCabezasComp("");
    setPrecio("");
    setPeso("");
    setComision(null);
    setPlazo("");
    setCantidad(0);
    setRinde(100);

    setConRinde(false);
    setRindeError(false);
    setHayEstadisticas(false);
  };
  // busco data de los checklist
  const fetchDataCheck = async () => {
    const dataCheck = await UserService.getDestinoComercial();
    setCheckDestino(dataCheck[0]);
    setCheckRaza(dataCheck[1]);
    //console.log(dataCheck[1])
    setCheckCuota(dataCheck[2]);
    setCheckEspecie(dataCheck[3]);
    setCheckPrograma(dataCheck[4]);
  };
  // cambio array de plazo
  const onchangeInput = (val, index) => {
    let temp = plazoArray.map((i) => i);
    temp[index] = val.target.value;

    setPlazoArray(temp);
  };
  // funcion para generar select de proveedores.
  const generarSelect = (data) => {
    return data.map((link) => (
      <MenuItem value={link.cuit_proveedor + "-" + link.razon_social}>
        {link.razon_social}
      </MenuItem>
    ));
  };
  // deshabilito boton para GUARDAR COMPRA.
  const deshabilitarPOST = (tipo) => {
    if (tipo == "Faena") {
      return (
        fechaCompra?.length == 0 ||
        fechaFaena?.length == 0 ||
        comprador?.length == 0 ||
        remitente?.length == 0 ||
        destinoComercial?.length == 0 ||
        raza?.length == 0 ||
        especie?.length == 0 ||
        programa?.length == 0 ||
        cabezasComp?.length == 0 ||
        precio?.length == 0 ||
        peso?.length == 0 ||
        plazo?.length == 0 ||
        rindeError ||
        (destinoComercial == "Exportación UE" && cuota?.length == 0) ||
        status?.length == 0
      );
    } else {
      return (
        fechaCompra?.length == 0 ||
        comprador?.length == 0 ||
        contactoDeCarga?.length == 0 ||
        cabezasComp?.length == 0 ||
        peso?.length == 0 ||
        precio?.length == 0 ||
        cuotaInvernada?.length == 0 ||
        Desbaste?.length == 0 ||
        plazoArray?.length != cuotaInvernada ||
        plazoArray?.includes("") ||
        //(flete.length > 0 && precioFlete == 0) ||
        (flete?.length > 0 && (km === 0 || km === "")) ||
        condicionPago === ""
      );
    }
  };
  // agregar contacto ******************************************* agregar componenete solo de mostrar contacto de compra.
  const [mostrarContacto, setMostrarContacto] = useState(false);
  const [nombre, setNombre] = useState();
  const [apellido, setApellido] = useState();
  const [telefono, setTelefono] = useState();
  const [openFeedbackContacto, setOpenFeedbackContacto] = useState(false);
  const [responseErrorContacto, setResponseErrorContacto] = useState(false);

  // para capturar ok/error en la respuesta
  const [openFeedback, setOpenFeedback] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const handleChangeFeedlot = (event) => {
    setFeedlot(event.target.value);
    let availableCampos = campos[event.target.value];
    if (availableCampos && availableCampos.length === 1) {
      setCampo(availableCampos[0].id);
    } else {
      setCampo(null);
    }
  }


  const handleCloseFeedback = () => {
    setOpenFeedback(false);
    if (!responseError) {
      //props.history.push(`/app/estadoCompras`);
      props.history.goBack();
    }
  }

  const handleCloseFeedbackContacto = () => {
    setOpenFeedbackContacto(false);
  }

  const handleCancelar = () => {
    //props.history.push(`/app/estadoCompras`);
    props.history.goBack();
  }

  // funcion para guardar compra y nuevo contacto
  const guardarCompra = async (history) => {
    setIsLoading2(true);
    setResponseError(false);
    setCompraCreadaId(null);
    let response;

    // if (mostrarContacto == true) {
    //   // guardar info de contacto de carga.
    //   UserService.postContactoDeCarga(
    //     nombre,
    //     apellido,
    //     telefono,
    //     history,
    //     setError,
    //   );
    // }
    try {
      if (tipoCompra == "Faena") {
        response = await UserService.postCompra(
          tipoCompra,
          fechaCompra,
          fechaNegocio,
          fechaCarga,
          fechaFaena,
          comprador,
          planta,
          status,
          consignatario,
          remitente,
          destinoComercial,
          cuota,
          cuotaInvernada,
          raza,
          especie,
          programa,
          programa,
          contactoDeCarga,
          lugarDeCarga,
          feedlot,
          cantidad,
          Desbaste,
          0, // km
          flete,
          JaulaS,
          JaulaD,
          cabezasComp,
          precio,
          peso,
          plazo,
          comisionista,
          comision,
          procedencia,
          observaciones,
          hqf,
          user.id,
          rinde,
          isPropia,
          isNop,
          precioFlete !== "" ? precioFlete : 0,
          gastosAdicionales !== "" ? gastosAdicionales : 0,
          null,
          null,
          false,
          false,
          selectedEstablecimiento
        )
      } else {
        response = await UserService.postCompra(
          tipoCompra,
          fechaCompra,
          fechaNegocio,
          fechaCarga,
          fechaFaena,
          comprador,
          planta,
          status,
          consignatario,
          remitente,
          destinoComercial,
          cuota,
          cuotaInvernada,
          raza,
          especie,
          programa,
          programa,
          contactoDeCarga,
          lugarDeCarga,
          feedlot,
          cantidad,
          Desbaste,
          km === "" ? 0 : km,
          flete,
          JaulaS,
          JaulaD,
          cabezasComp,
          precio,
          peso,
          plazoArray.toString(),
          comisionista,
          comision,
          procedencia,
          observaciones,
          hqf,
          user.id,
          rinde,
          true,  // isPropia
          isNop,
          precioFlete !== "" ? precioFlete : 0,
          gastosAdicionales !== "" ? gastosAdicionales : 0,
          condicionPago,
          campo,
          isRecria,
          isFeedlot,
          selectedEstablecimiento
        )
      }
      if (response.status !== 201) {
        setResponseError(true);
      } else {
        let rs = await response.json();
        setCompraCreadaId(rs.id);
      }
    } catch (error) {
      console.log(error)
      setResponseError(true);
    }
    setIsLoading2(false);
    setOpenFeedback(true);
  };

  useEffect(() => {
    // busco data de checkbox
    fetchDataCheck();
    // busco listado de proveedores
    UserService.getListProveedor().then(
      (response) => {
        if (response.status == 200) {
          const dataTropaConsig = response.data.users.map((a, index) => ({
            cuit: a.cuit,
            label: a.razonS + ' - ' + a.cuit,
            razon_social: a.razonS,
            email: a.email,
            id: index
          }));
          setDespletables(dataTropaConsig);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );

    UserService.getListFeedlot().then(
      (response) => {
        if (response.status == 200) {
          let camposPerFeedlot = {};
          const dataTropaConsig = response.data.users.map((a) => ({
            id: a.id,
            nombre: a.nombre
          }));
          const camp = response.data.users.map((a) => {
            if (a.campos && a.campos.length > 0) {
              camposPerFeedlot[a.id] = a.campos.map((b) => (b))
            }
          })
          setFeedlotDesp(dataTropaConsig);
          setCampos(camposPerFeedlot);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );
    // busco listado de contactos de carga
    UserService.getListContactoDeCarga().then(
      (response) => {
        if (response.status == 200) {
          setContactoDC(response.data.data);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );

    // busco listado de contactos de carga
    /*  UserService.getFeedLot().then(
       response => {
       if(response.status==200){
         setFeedlotDesp(response.data)
       }
       },
       error => {if(error.response.status==401){}}
       ); */

    UserService.getListcompradores().then(
      (response) => {
        if (response.status == 200) {
          setCompradoresDeslp(response.data.data);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );

    UserService.getEstablecimientos().then(
      response => {
        if (response.status == 200) {
          setEstablecimientos(response.data);
        }
      },
    );
  }, []);

  return (
    <>
      {/* <PageTitle title="Nueva Compra" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div>
              <Typography color="secondary" className={classes.errorMessage}>
                {error}
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid container item lg={8} md={12} sm={12} xs={12} spacing={2}>
                <Grid
                  contaimer
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                  spacing={2}
                >
                  <Grid item lg={3} md={3} xs={6} sm={6} spacing={2}>
                    <InputLabel id="type">Tipo de Compra</InputLabel>
                    {/* <RadioGroup aria-label="tipo" row name="tipo"  value={tipoCompra} onChange={handleTipoCompra} >
                      <FormControlLabel value="Faena" control={<Radio icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="Faena" />
                      <FormControlLabel value="Invernada" control={<Radio icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="Invernada" />
                    </RadioGroup>   */}
                    <ToggleButtonGroup
                      value={tipoCompra}
                      size={isSmallScreen ? "small" : "medium"}
                      exclusive
                      onChange={handleTipoCompra}
                    >
                      <ToggleButton
                        value="Faena"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.primary.main}
                        selectedColor={theme.palette.background.custom}
                        classes={{ selected: classes.selected }}
                      >
                        Faena
                      </ToggleButton>
                      <ToggleButton
                        value="Invernada"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.primary.main}
                        selectedColor={theme.palette.background.custom}
                        classes={{ selected: classes.selected }}
                      >
                        Invernada
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {!isSmallScreen && (
                    <Grid item lg={3} md={3} xs={12} sm={12} justifyContent="center" style={{ marginLeft: isSmallScreen ? '10px' : '0px' }}>
                      <InputLabel id="type">Comprador*</InputLabel>
                      <Select
                        labelId="type"
                        variant="outlined"
                        id="sector"
                        size={isSmallScreen ? "small" : "medium"}
                        value={comprador}
                        error={comprador == "" ? true : false}
                        label="Comprador *"
                        onChange={(e) => {
                          setComprador(e.target.value);
                        }}
                        fullWidth
                        required
                        disabled={compradorEst()}
                      >
                        {CompradoresDeslp.map((link) => {
                          return (
                            <MenuItem value={link.email}>
                              {link.username} {link.last_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  )}
                </Grid>
                {isSmallScreen && (
                  <Grid container item lg={12} md={12} xs={12} sm={12} justifyContent="center">
                    <InputLabel id="type">Comprador*</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      id="sector"
                      size={isSmallScreen ? "small" : "medium"}
                      value={comprador}
                      error={comprador == "" ? true : false}
                      label="Comprador *"
                      onChange={(e) => {
                        setComprador(e.target.value);
                      }}
                      fullWidth
                      required
                      disabled={compradorEst()}
                    >
                      {CompradoresDeslp.map((link) => {
                        return (
                          <MenuItem value={link.email}>
                            {link.username} {link.last_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                )}
                <Grid item alignItems="center" lg={3} md={6} xs={6} sm={6}>
                  <InputLabel id="fechaCompra">{isSmallScreen ? "Fecha Compra*" : "Fecha de Compra*"}</InputLabel>

                  <ResponsiveDatePickers
                    value={fechaCompra}
                    tipo={"Faena"}
                    setValorFecha={(e) => {
                      setFechaCompra(moment(e).format("YYYY-MM-DD"));
                    }}
                  ></ResponsiveDatePickers>
                </Grid>
                {hide && (
                  <Grid item alignItems="center" lg={3} md={6} xs={6} sm={6}>
                    <InputLabel id="fechaCarga">Fecha de Carga</InputLabel>

                    <ResponsiveDatePickers
                      value={fechaCarga}
                      tipo={"Carga"}
                      setValorFecha={(e) => {
                        setFechaCarga(moment(e).format("YYYY-MM-DD"));
                        setFechaNegocio(moment(e).format("YYYY-MM-DD"));
                      }}
                      dontDisabledWeekend={true}
                    ></ResponsiveDatePickers>
                  </Grid>
                )}
                {!hide && (
                  <Grid item alignItems="center" lg={3} md={6} xs={6} sm={6}>
                    <InputLabel id="fechaCompra">{isSmallScreen ? "Fecha Faena*" : "Fecha de Faena*"}</InputLabel>

                    <ResponsiveDatePickers
                      value={fechaFaena}
                      tipo={"Faena"}
                      setValorFecha={handleFechaFaenaChange}
                      onBlur={handleBlur}
                    ></ResponsiveDatePickers>
                  </Grid>
                )}
                {hide && (
                  <Grid item alignItems="center" lg={3} md={6} xs={6} sm={6}>
                    <InputLabel id="type">{isSmallScreen ? "Fecha Negocio" : "Fecha de Negocio"}</InputLabel>
                    <ResponsiveDatePickers
                      tipo={"Negocio"}
                      value={fechaNegocio}
                      setValorFecha={(e) => {
                        setFechaNegocio(moment(e).format("YYYY-MM-DD"));
                      }}
                      dontDisabledWeekend={true}
                    ></ResponsiveDatePickers>
                  </Grid>
                )}
                {open && (
                  <Grid item lg={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Collapse in={open}>
                        <Alert
                          variant="outlined"
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mb: 2, color: "inherit" }}
                        >
                          Estas eligiendo una fecha anterior a la de hoy en Fecha de
                          Faena!
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}

                {false && (
                  <Grid item lg={12} xs={12} sm={6}>
                    <TextField
                      id="planta"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={planta}
                      onChange={(e) => setPlanta(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="planta"
                      disabled
                      placeholder="Razón Social"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                )}

                {hide && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <TextField
                        id="lugarDeCarga"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={lugarDeCarga}
                        onChange={(e) => setLugarDeCarga(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Lugar de Carga"
                        placeholder="Lugar de Carga"
                        type="text"
                        required={!hide}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container item lg={12} md={12} xs={12} sm={12}>

                  <Grid item lg={6} md={6} xs={12} sm={12}>
                    <InputLabel id="type">Establecimiento</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      id="establecimiento"
                      value={selectedEstablecimiento}
                      label="Establecimiento"
                      onChange={(e) => { setSelectedEstablecimiento(e.target.value) }}
                      fullWidth
                    >
                      {establecimientos.map(link => {
                        return (
                          <MenuItem value={link.id} key={link.id}>
                            {link.nombre}
                          </MenuItem>
                        )
                      }

                      )}
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {(feedlot !== "" && feedlot !== null) && (
                      <Tooltip title={'Limpiar seleccion'}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setSelectedEstablecimiento(null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>

                <Grid container item lg={12} md={12} xs={12} sm={12}>
                  <Grid item lg={6} md={6} xs={10} sm={10}>
                    <InputLabel id="type">Feedlot</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      id="Feedlot"
                      value={feedlot}
                      label="Feedlot"
                      onChange={handleChangeFeedlot}
                      fullWidth
                    >
                      {feedlotDesp.map((link) => {
                        return (
                          <MenuItem value={link.id} key={link.id}>
                            {link.nombre}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {(feedlot !== "" && feedlot !== null) && (
                      <Tooltip title={'Limpiar seleccion'}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setFeedlot(null);
                            setCampo(null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                {(hide && feedlot) && (
                  <Grid container item lg={12} md={12} xs={12} sm={12} gap={2}>
                    <Grid item lg={6} md={6} xs={10} sm={10}>
                      <InputLabel id="campo-selector">Campo</InputLabel>
                      <Select
                        labelId="campo-selector"
                        id="campo-selector"
                        value={campo}
                        label="Campo"
                        onChange={handleChangeCampo}
                        variant="outlined"
                        fullWidth
                      >
                        {campos[feedlot]?.map((campo) => (
                          <MenuItem key={campo.id} value={campo.id}>
                            {campo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    {(campo !== "" && campo !== null) && (
                      <>
                        <Grid item lg={1} md={1} sm={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title={'Limpiar seleccion'}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                setCampo(null);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox checked={isFeedlot} onChange={handleChangeTypeFeedlot} name="is_feedlot" />}
                              label="Feedlot"
                              style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                            />
                            <FormControlLabel
                              control={<Checkbox checked={isRecria} onChange={handleChangeTypeRecria} name="is_recria" />}
                              label="Recria"
                              style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                            />
                          </FormGroup>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <InputLabel id="type">Status*</InputLabel>
                  {/*  <RadioGroup aria-label="tipo" row name="tipo" value={status} onChange={(e)=>{setStatus(e.target.value)}}>
                      <FormControlLabel value="SinConfirmar" control={<Radio  onClick={handleClickStatus}icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="Sin Confirmar" />
                      <FormControlLabel value="tramite" control={<Radio onClick={handleClickStatus}icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="En trámite" />
                      <FormControlLabel value="confirmado" control={<Radio onClick={handleClickStatus}icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="Confirmado" />
                      <FormControlLabel value="arribado" control={<Radio onClick={handleClickStatus}icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="Arribado" />
                    </RadioGroup>   */}
                  <ToggleButtonGroup
                    value={alignmentStatus}
                    exclusive
                    size={isSmallScreen ? 'small' : 'medium'}
                    error={status == "" ? true : false}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setAligmentStatus(e.target.value);
                      setErrorToggleStatus(theme.palette.background.custom);
                    }}
                  >
                    <ToggleButton
                      value="SinConfirmar"
                      aria-label="left aligned"
                      errorColor="red"
                      borderColor={
                        status === ""
                          ? theme.palette.error.dark
                          : theme.palette.tables.tableBorderColor
                      }
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                    >
                      SC
                    </ToggleButton>
                    <ToggleButton
                      value="tramite"
                      aria-label="left aligned"
                      errorColor="red"
                      borderColor={
                        status === ""
                          ? theme.palette.error.dark
                          : theme.palette.tables.tableBorderColor
                      }
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                    >
                      ET
                    </ToggleButton>
                    <ToggleButton
                      value="confirmado"
                      aria-label="left aligned"
                      errorColor="red"
                      borderColor={
                        status === ""
                          ? theme.palette.error.dark
                          : theme.palette.tables.tableBorderColor
                      }
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                    >
                      CO
                    </ToggleButton>
                    <ToggleButton
                      value="arribado"
                      aria-label="left aligned"
                      errorColor="red"
                      borderColor={
                        status === ""
                          ? theme.palette.error.dark
                          : theme.palette.tables.tableBorderColor
                      }
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                    >
                      ADO
                    </ToggleButton>
                    <ToggleButton
                      value="postergaCarga"
                      aria-label="left aligned"
                      errorColor="red"
                      borderColor={
                        status === ""
                          ? theme.palette.error.dark
                          : theme.palette.tables.tableBorderColor
                      }
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                    >
                      PC
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormControlLabel
                    control={<Checkbox checked={isPropia} onChange={(event) => { setIsPropia(event.target.checked) }} name="is_propia" />}
                    label="Es Propia"
                    style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                  />
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                >
                  {mostrarRemitente ? (
                    <Grid item lg={6} md={10} xs={10} sm={10}>
                      <InputLabel id="Remitente">Remitente*</InputLabel>

                      <Autocomplete
                        //disablePortal
                        size={isSmallScreen ? 'small' : 'medium'}
                        noOptionsText={"Sin opciones"}
                        id="combo-box-demo"
                        options={desplegables}
                        onChange={(e, value) => {
                          if (value && value.cuit != undefined) {
                            setRemitente(
                              value.cuit.toString() +
                              "-" +
                              value.razon_social.toString(),
                            );
                            setEstadisticas(false);
                            setHayEstadisticas(false);
                            setEstadisticasData([]);
                            getEstadisticas(value.cuit.toString());
                            //checkGeneral(value.cuit.toString());
                            value.email == null && setOpenWarningEmail(true);
                          } else {
                            setRemitente("");
                            getEstadisticas();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            variant="outlined"
                            error={(remitente == "" && tipoCompra == "Faena") ? true : false}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item lg={6} md={10} xs={10} sm={10}>
                        <TextField
                          id="RemitenteInput"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={remitente}
                          onChange={(e) => {
                            setRemitente(e.target.value);
                          }}
                          margin="normal"
                          placeholder="Nuevo Remitente"
                          variant="outlined"
                          size={isSmallScreen ? 'small' : 'medium'}
                          label="Nuevo Remitente"
                          type="text"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  <FormControlLabel
                    value="consgi"
                    control={<AddCircleIcon />}
                    className={classes.control2}
                    label=""
                    onClick={() => {
                      setMostrarRemitente((value) => !value);
                      setRemitente("");
                    }}
                  />

                  <IconButton
                    className={classes.radio}
                    onClick={() => {
                      mostrarRemitente && setEstadisticas((value) => !value);
                    }}
                  >
                    <InsertChartIcon />
                  </IconButton>
                </Grid>
                {isMediumScreen && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {estadisticas && hayEstadisticas && (
                      <EstadisticasTable
                        onEstadisticas={estadisticasData}
                      ></EstadisticasTable>
                    )}
                  </Grid>
                )}
                {estadisticas && !hayEstadisticas && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Collapse in={estadisticas}>
                        <Alert
                          variant="outlined"
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setEstadisticas(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mb: 2, color: "inherit" }}
                        >
                          No hay estadisticas disponibles para el proveedor
                          seleccionado
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                {openWarning && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Collapse in={openWarning}>
                        <Alert
                          variant="outlined"
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenWarning(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mb: 2, color: "inherit" }}
                        >
                          Este Proveedor no cargo su Certificado General!
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                {openWarningEmail && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Collapse in={openWarningEmail}>
                        <Alert
                          variant="outlined"
                          severity="warning"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenWarningEmail(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mb: 2, color: "inherit" }}
                        >
                          Este Proveedor no tiene un email cargado
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                >
                  {mostrarConsig ? (
                    <Grid item lg={6} md={10} xs={10} sm={10}>
                      <InputLabel id="type">Consignatario</InputLabel>
                      <Autocomplete
                        disablePortal
                        noOptionsText={"Sin opciones"}
                        id="combo-box-demo"
                        size={isSmallScreen ? 'small' : 'medium'}
                        options={desplegables}
                        onChange={(e, value) => {
                          if (value && value.cuit != undefined) {
                            setConsignatario(
                              value.cuit.toString() +
                              "-" +
                              value.razon_social.toString(),
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={6} md={10} xs={10} sm={10}>
                      <TextField
                        id="ConsignatarioInput"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={consignatario}
                        onChange={(e) => setConsignatario(e.target.value)}
                        margin="normal"
                        placeholder="Nuevo Consignatario"
                        variant="outlined"
                        size={isSmallScreen ? 'small' : 'medium'}
                        label="Nuevo Consignatario"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item lg={2} md={2} xs={2} sm={2}>
                    <FormControlLabel
                      value="consgi"
                      control={<AddCircleIcon />}
                      labelPlacement="end"
                      label=""
                      className={classes.control}
                      onClick={() => {
                        setMostrarConsig((value) => !value);
                        setConsignatario("");
                      }}
                    />
                  </Grid>
                </Grid>
                {hide && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ display: "flex" }}
                  >
                    <Grid item lg={6} md={10} xs={10} sm={10}>
                      <InputLabel id="type">Contacto de Carga*</InputLabel>
                      <Select
                        labelId="type"
                        variant="outlined"
                        id="ContactoDeCarga"
                        value={contactoDeCarga}
                        label="Contacto de Carga*"
                        error={contactoDeCarga == "" ? true : false}
                        onChange={(e) => {
                          setContactoDeCarga(e.target.value);
                        }}
                        fullWidth
                        disabled={mostrarContacto}
                      >
                        {contactoDC.map((link) => {
                          return (
                            <MenuItem value={link.id}>
                              {link.username} {link.last_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>

                    <Grid item lg={2} md={2} xs={2} sm={2}>
                      <FormControlLabel
                        value="consgi"
                        control={<AddCircleIcon />}
                        className={classes.control}
                        label=""
                        labelPlacement="end"
                        onClick={() => {
                          setMostrarContacto((value) => !value);
                          setContactoDeCarga(null);
                        }}
                      />
                    </Grid>
                    {mostrarContacto && (
                      <ContactoCarga
                        setOpenFeedbackContacto={setOpenFeedbackContacto}
                        setResponseErrorContacto={setResponseErrorContacto}
                        setContacto={setContactoDeCarga}
                      ></ContactoCarga>
                    )}
                  </Grid>
                )}

                {!hide && (
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <InputLabel id="type">Destino Comercial*</InputLabel>
                    <ToggleButtonGroup
                      value={alignmentDestino}
                      exclusive
                      size={isSmallScreen ? 'small' : 'medium'}
                      onChange={handleClickDestino}
                      required
                    >
                      {checkDestino.map((link) => {
                        if (link != "*" && link != null) {
                          return (
                            <ToggleButton
                              value={link}
                              aria-label="left aligned"
                              borderColor={
                                destinoComercial === ""
                                  ? theme.palette.error.dark
                                  : theme.palette.tables.tableBorderColor
                              }
                              textColor={theme.palette.primary.main}
                              selectedColor={theme.palette.background.custom}
                              classes={{ selected: classes.selected }}
                            >
                              {link == 'Exportación UE' ? 'UE' : link == 'Exportacion Manufactura' ? 'Conserva' : link == 'Exportación TP' ? 'OP' : link}
                            </ToggleButton>
                          );
                        }
                      })}
                    </ToggleButtonGroup>
                  </Grid>
                )}
                <Grid
                  container
                  item
                  lg={6}
                  md={6}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                  spacing={2}
                >
                  {UE && (
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <InputLabel id="type">Cuota*</InputLabel>
                      {/* <RadioGroup aria-label="tipo" row name="tipo" value={cuota} onChange={(e)=>{setCuota(e.target.value)}}>
                    {checkCuota.map(link => {
                      if(link != null){return <FormControlLabel 
                        value={link} control={<Radio onClick={handleClickCuota} color="primary" icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label={link} />}
                      
                      })}
                    </RadioGroup>   */}
                      <ToggleButtonGroup
                        value={alignmentCuota}
                        exclusive
                        onChange={handleClickCuota}
                      >
                        {checkCuota.map((link) => {
                          if (link != "*" && link != null) {
                            return (
                              <ToggleButton
                                value={link}
                                aria-label="left aligned"
                                borderColor={
                                  cuota === ""
                                    ? theme.palette.error.dark
                                    : theme.palette.tables.tableBorderColor
                                }
                                textColor={theme.palette.primary.main}
                                selectedColor={theme.palette.background.custom}
                                classes={{ selected: classes.selected }}
                              >
                                {link}
                              </ToggleButton>
                            );
                          }
                        })}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                  {!hide && (
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <InputLabel id="type">Raza*</InputLabel>
                      {/* <RadioGroup aria-label="tipo" row name="tipo" value={raza} onChange={(e)=>{setRaza(e.target.value)}}>
                    {checkRaza.map(link => {
                      if(link != '*'){return <FormControlLabel 
                        value={link} control={<Radio onClick={handleClickRaza}icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label={link} />}
                      
                      })}
                    </RadioGroup>  */}
                      <ToggleButtonGroup
                        value={alignmentRaza}
                        exclusive
                        size={isSmallScreen ? 'small' : 'medium'}
                        onChange={handleClickRaza}
                      >
                        {checkRaza.map((link) => {
                          if (link != "*" && link != null) {
                            return (
                              <ToggleButton
                                value={link}
                                aria-label="left aligned"
                                borderColor={
                                  raza === ""
                                    ? theme.palette.error.dark
                                    : theme.palette.tables.tableBorderColor
                                }
                                textColor={theme.palette.primary.main}
                                selectedColor={theme.palette.background.custom}
                                classes={{ selected: classes.selected }}
                              >
                                {link}
                              </ToggleButton>
                            );
                          }
                        })}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                >
                  {!hide && (
                    <Grid item lg={3} md={3} xs={6} sm={6}>
                      <InputLabel id="type">Especie*</InputLabel>
                      {/* <RadioGroup aria-label="tipo" row name="tipo" value={especie} onChange={(e)=>{setEspecie(e.target.value)}}>
                    {checkEspecie.map(link => {
                      if(link != '*'){return <FormControlLabel 
                        value={link} control={<Radio onClick={handleClickEspecie}icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label={link} />}
                      
                      })}
                    </RadioGroup>  */}
                      <ToggleButtonGroup
                        value={alignmentEspecie}
                        exclusive
                        size={isSmallScreen ? 'small' : 'medium'}
                        onChange={handleClickEspecie}
                      >
                        {checkEspecie.map((link) => {
                          if (link != "*") {
                            return (
                              <ToggleButton
                                value={link}
                                aria-label="left aligned"
                                borderColor={
                                  especie === ""
                                    ? theme.palette.error.dark
                                    : theme.palette.tables.tableBorderColor
                                }
                                textColor={theme.palette.primary.main}
                                selectedColor={theme.palette.background.custom}
                                classes={{ selected: classes.selected }}
                              >
                                {link}
                              </ToggleButton>
                            );
                          }
                        })}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                </Grid>

                {!hide && (
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <InputLabel id="type">Programa*</InputLabel>
                    <ToggleButtonGroup
                      value={alignmentPrograma}
                      exclusive
                      size={isSmallScreen ? 'small' : 'medium'}
                      onChange={handleClickPrograma}
                    >
                      {checkPrograma.map((link) => {
                        if (link != "*") {
                          return (
                            <ToggleButton
                              value={link}
                              aria-label="left aligned"
                              disabled={disabledPrograma(link)}
                              borderColor={
                                programa === ""
                                  ? theme.palette.error.dark
                                  : theme.palette.tables.tableBorderColor
                              }
                              disabledColor={theme.palette.action.disabled}
                              textColor={theme.palette.primary.main}
                              selectedColor={theme.palette.background.custom}

                              classes={{ selected: classes.selected }}
                            >
                              {(isSmallScreen && link == 'GRAIN FED') ? 'GF' : link}
                            </ToggleButton>
                          );
                        }
                      })}
                    </ToggleButtonGroup>
                    {verHQF && (
                      <FormControlLabel
                        value={hqf}
                        disabled={cuotaResult()}
                        onClick={() => {
                          setHQF((value) => !value);
                        }}
                        control={<Checkbox checked={hqf} color="primary" />}
                        label="HQF"
                        style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                      />
                    )}
                    {showNop && (
                      <FormControlLabel
                        control={<Checkbox checked={isNop} onChange={(event) => { setIsNop(event.target.checked) }} name="is_nop" />}
                        label="Es NOP"
                        style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                      />
                    )}
                  </Grid>
                )}
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                  spacing={2}
                >
                  <Grid item lg={3} md={3} xs={6} sm={6}>
                    <TextField
                      id="Cabezas"
                      onWheel={event => event.target.blur()}
                      InputProps={{
                        inputProps: {
                          min: 0
                        },

                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={cabezasComp}
                      onChange={(e) => setCabezasComp(e.target.value)}
                      margin="normal"
                      size={isSmallScreen ? 'small' : 'medium'}
                      placeholder="Cabezas"
                      variant="outlined"
                      label="Cabezas"
                      type="number"
                      required
                      error={cabezasComp == "" ? true : false}
                      fullWidth
                    />
                  </Grid>
                  {/*  {hide && (
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField
                    id="Cantidad"
                    InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={cantidad}
                    onChange={e => setCantidad(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    label="Cantidad*"

                    placeholder="Cantidad*"
                    type="number"
                    fullWidth/>
                </Grid>)} */}

                  <Grid item lg={3} md={3} xs={6} sm={6}>
                    <TextField
                      id="Peso"
                      onWheel={event => event.target.blur()}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },

                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={peso}
                      size={isSmallScreen ? 'small' : 'medium'}
                      onChange={(e) => {
                        if (e.target.value > 800) {
                          setErrorPeso(true);
                          setPeso(800);
                        } else {
                          setPeso(e.target.value)
                        }
                      }}
                      margin="normal"
                      placeholder="Peso*"
                      variant="outlined"
                      label="Peso*"
                      error={peso == "" ? true : false}
                      type="number"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                {errorPeso && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Collapse in={errorPeso}>
                        <Alert
                          variant="outlined"
                          severity="warning"
                          sx={{ mb: 2, color: "inherit" }}
                          onClose={() => setErrorPeso(false)}
                        >
                          La compra no puede tener un peso mayor a 800Kg!
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: "flex" }}
                  spacing={2}
                >
                  <Grid
                    item
                    lg={3}
                    md={3}
                    xs={7}
                    sm={7}
                    style={{ display: "flex" }}
                    spacing={2}
                  >
                    <TextField
                      id="Precio"
                      onWheel={event => event.target.blur()}
                      InputProps={{
                        inputProps: { min: 0 },

                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={precio}
                      size={isSmallScreen ? 'small' : 'medium'}
                      onChange={(e) => handlePrecioChange(e.target.value)}
                      margin="normal"
                      placeholder="Precio*"
                      error={precio == "" ? true : false}
                      variant="outlined"
                      label="Precio*"
                      type="number"
                      fullWidth
                    />

                    {tipoCompra === "Faena" && (
                      <IconButton
                        onClick={() => {
                          setConRinde(!conRinde);
                        }}
                      >
                        <PercentIcon size="small" />
                      </IconButton>
                    )}
                  </Grid>
                  {conRinde && tipoCompra === "Faena" && (
                    <Grid item lg={3} md={3} xs={5} sm={5}>
                      <TextField
                        id="Rinde"
                        type="number"
                        onWheel={event => event.target.blur()}
                        min={50}
                        max={100}
                        InputProps={{
                          inputProps: { min: 50, max: 100 },
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={rinde}
                        onChange={(e) => {
                          if (
                            e.target.value < 50 ||
                            (e.target.value > 65 && e.target.value < 100)
                          ) {
                            setRindeError(true);
                          } else {
                            setRindeError(false);
                          }

                          if (e.target.value > 100) {
                            setRinde(100);
                            setRindeError(false);
                          } else if (
                            e.target.value > 65 &&
                            e.target.value < 100
                          ) {
                            setRinde(65);
                            setRindeError(false);
                          } else if (
                            e.target.value > 9 &&
                            e.target.value < 50
                          ) {
                            setRinde(50);
                            setRindeError(false);
                          } else {
                            setRinde(e.target.value);
                          }
                        }}
                        margin="normal"
                        size={isSmallScreen ? 'small' : 'medium'}
                        placeholder="% $/KgG"
                        variant="outlined"
                        label="% $/KgG"
                        error={rinde < 10 ? true : false}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {conRinde && tipoCompra === "Faena" && rindeError && (
                    <Grid
                      item
                      lg={6}
                      md={12}
                      xs={12}
                      sm={12}
                      style={{ display: "flex" }}
                      spacing={2}
                    >
                      <Collapse in={rindeError} sx={{ marginTop: "15px" }}>
                        <Alert
                          variant="outlined"
                          severity="error"
                          sx={{ mb: 2, color: "inherit" }}
                        >
                          El rinde debe estar entre 50% y 65%, o ser 100% !
                        </Alert>
                      </Collapse>
                    </Grid>
                  )}
                </Grid>
                {errorPrecio && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Collapse in={errorPrecio}>
                        <Alert
                          variant="outlined"
                          severity="warning"
                          sx={{ mb: 2, color: "inherit" }}
                          onClose={() => setErrorPrecio(false)}
                        >
                          La compra no puede tener un precio menor a $800!
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                {!hide && (
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ display: "flex" }}
                    spacing={2}
                  >
                    <Grid item lg={3} md={3} xs={6} sm={6}>
                      <TextField
                        id="Plazo"
                        size={isSmallScreen ? 'small' : 'medium'}
                        onWheel={event => event.target.blur()}
                        InputProps={{
                          inputProps: { min: 0, max: 70 },
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={plazo}
                        onChange={(e) => {
                          if (e.target.value > 70) {
                            setPlazo(70);
                          } else {
                            setPlazo(e.target.value);
                          }
                        }}
                        margin="normal"
                        placeholder="Plazo*"
                        variant="outlined"
                        label="Plazo*"
                        error={plazo == "" ? true : false}
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                {hide && (
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <InputLabel id="condicion_pago">Condicion de Pago</InputLabel>
                      <Select
                        labelId="type"
                        variant="outlined"
                        id="condicion_pago_select"
                        value={condicionPago}
                        label="Contacto de Carga*"
                        error={condicionPago == "" ? true : false}
                        onChange={(e) => {
                          setCondicionPago(e.target.value);
                        }}
                        fullWidth
                      >
                        {condicionesPago.map((value) => {
                          return (
                            <MenuItem value={value.codigo}>
                              {`${value.codigo} - ${value.descripcion}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                )}
                {hide && (
                  <>
                    <Grid item lg={3} md={3} xs={6} sm={6}>
                      <InputLabel id="cuotaInv">Cuotas</InputLabel>
                      <Select
                        labelId="cuotaInv"
                        variant="outlined"
                        id="cuotaInv"
                        value={cuotaInvernada}
                        error={cuotaInvernada == "" ? true : false}
                        label="cuotaInv"
                        onChange={(e) => {
                          setCuotaInvernada(e.target.value);
                          setPlazoArray(Array(e.target.value));
                          //console.log(Array(e.target.value));
                        }}
                        fullWidth
                      >
                        {[...Array(13)].map((e, i) => {
                          if (i > 0) {
                            return <MenuItem value={i}>{i}</MenuItem>;
                          }
                        })}
                      </Select>
                    </Grid>
                    <Grid item lg={3} md={3} xs={6} sm={6}>
                      {[...Array(cuotaInvernada)].map((e, i) => {
                        {
                          return (
                            <TextField
                              id="Plazo"
                              onWheel={event => event.target.blur()}
                              InputProps={{
                                inputProps: { min: 0 },

                                classes: {
                                  underline: classes.textFieldUnderline,
                                  input: classes.textField,
                                },
                              }}
                              value={plazoArray[i]}
                              onChange={(e) => onchangeInput(e, i)}
                              margin="normal"
                              placeholder={"Plazo" + (i + 1) + "*"}
                              variant="outlined"
                              error={
                                plazoArray[i] == "" ||
                                  plazoArray[i] == undefined
                                  ? true
                                  : false
                              }
                              label={"Plazo" + (i + 1) + "*"}
                              type="number"
                              fullWidth
                            />
                          );
                        }
                      })}
                    </Grid>
                  </>
                )}

                <Grid container item lg={12} xs={12} sm={12} spacing={2}>
                  {mostrarComisionista ? (
                    <Grid item lg={3} md={3} xs={11} sm={11}>
                      <InputLabel id="Comisionista">Comisionista</InputLabel>
                      <Autocomplete
                        disablePortal
                        size={isSmallScreen ? 'small' : 'medium'}
                        noOptionsText={"Sin opciones"}
                        id="combo-box-demo"
                        options={desplegables}
                        onChange={(e, value) => {
                          if (value && value.cuit != undefined) {
                            setComisionista(
                              value.cuit.toString() +
                              "-" +
                              value.razon_social.toString(),
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"

                            variant="outlined"
                            color="primary"
                            InputLabelProps={{
                              style: { color: "red" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} md={3} xs={11} sm={11}>
                      <TextField
                        id="comisionistaInput"

                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={comisionista}
                        onChange={(e) => setComisionista(e.target.value)}
                        margin="normal"
                        placeholder="Nuevo Comisionista"
                        variant="outlined"
                        label="Nuevo Comisionista"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item lg={1} md={1} xs={1} sm={1}>
                    <FormControlLabel
                      value="consgi"
                      control={<AddCircleIcon />}
                      labelPlacement="end"
                      className={classes.control}
                      label=""
                      onClick={() => {
                        setMostrarComisionista((value) => !value);
                        setComisionista("");
                      }}
                    />
                  </Grid>
                  {!isSmallScreen && (
                    <Grid item lg={2} md={2} xs={5} sm={5}>
                      <TextField
                        id="Comisión"
                        onWheel={event => event.target.blur()}
                        InputProps={{
                          inputProps: { min: 0 },

                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={comision}
                        onChange={(e) => setComision(e.target.value)}
                        margin="normal"
                        placeholder="Comisión"
                        variant="outlined"
                        label="Comision"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
                {isSmallScreen && (
                  <Grid container item lg={12} xs={12} sm={12} spacing={2}>
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                      <TextField
                        id="Comisión"
                        onWheel={event => event.target.blur()}
                        InputProps={{
                          inputProps: { min: 0 },

                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={comision}
                        onChange={(e) => setComision(e.target.value)}
                        margin="normal"
                        placeholder="Comisión"
                        variant="outlined"
                        label="Comision"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                {hide && (
                  <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={3} md={3} xs={12} sm={12}>
                      <TextField
                        id="Gastos adicionales"
                        onWheel={event => event.target.blur()}
                        margin="normal"
                        placeholder="Gastos adicionales"
                        variant="outlined"
                        value={gastosAdicionales}
                        label="Gastos adicionales"
                        type="number"
                        onChange={(e) => { setGastosAdicionales(e.target.value) }}
                        fullWidth
                      />
                    </Grid>

                  </Grid>
                )}

                {hide && (
                  <>
                    <Grid item lg={3} md={3} xs={6} sm={6}>
                      <TextField
                        id="Desbaste"
                        onWheel={event => event.target.blur()}
                        InputProps={{
                          inputProps: { min: 0 },

                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={Desbaste}
                        onChange={(e) => setDesbaste(e.target.value)}
                        margin="normal"
                        placeholder="Desbaste*"
                        error={Desbaste == "" ? true : false}
                        variant="outlined"
                        label="Desbaste*"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6} sm={6}>
                      <TextField
                        id="KilómetrosAproximados"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={km}
                        onChange={(e) => setKm(e.target.value)}
                        error={flete.length > 0 && (km === 0 || km === "") ? true : false}
                        margin="normal"
                        placeholder="Kilómetros aproximados"
                        variant="outlined"
                        label="Kilómetros aproximados"
                        type="text"
                        fullWidth
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      sm={12}
                      style={{ display: "flex" }}
                    >
                      <Grid item lg={3} md={11} xs={11} sm={11}>
                        {mostrarFlete ? (
                          <div>
                            <InputLabel id="Flete">Flete</InputLabel>
                            <Autocomplete
                              disablePortal
                              noOptionsText={"Sin opciones"}
                              id="combo-box-demo"
                              options={desplegables}
                              onChange={(e, value) => {
                                if (value && value.cuit != undefined) {
                                  setFlete(
                                    value.cuit.toString() +
                                    "-" +
                                    value.razon_social.toString(),
                                  );
                                } else {
                                  setFlete();
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <div>
                            <TextField
                              id="fleteInput"
                              InputProps={{
                                classes: {
                                  underline: classes.textFieldUnderline,
                                  input: classes.textField,
                                },
                              }}
                              value={flete}
                              onChange={(e) => setFlete(e.target.value)}
                              margin="normal"
                              placeholder="Nuevo Flete"
                              variant="outlined"
                              label="Nuevo Flete"
                              type="text"
                              fullWidth
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid item lg={1} md={1} xs={1} sm={1}>
                        <FormControlLabel
                          value="consgi"
                          control={<AddCircleIcon />}
                          className={classes.control}
                          label=""
                          labelPlacement="end"
                          onClick={() => {
                            setMostrarFlete((value) => !value);
                            setFlete("");
                          }}
                        />
                      </Grid>
                      <Grid item lg={2} md={12} sm={12} xs={12}>
                        <TextField
                          id="Precio-Flete"
                          onWheel={event => event.target.blur()}
                          InputProps={{
                            inputProps: { min: 0 },

                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={precioFlete}
                          size={isSmallScreen ? 'small' : 'medium'}
                          onChange={(e) => { setPrecioFlete(e.target.value) }}
                          margin="normal"
                          placeholder="Precio Flete"
                          error={(flete.length > 0 && (precioFlete === 0 || precioFlete === "")) ? true : false}
                          variant="outlined"
                          label="Precio Flete"
                          type="number"
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid item lg={3} md={6} xs={6} sm={6}>
                      <TextField
                        id="JaulaSimple"
                        onWheel={event => event.target.blur()}
                        InputProps={{
                          inputProps: { min: 0 },

                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={JaulaS}
                        onChange={(e) => setJaulaS(e.target.value)}
                        margin="normal"
                        placeholder="Jaula simple"
                        variant="outlined"
                        label="Jaula simple"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={3} md={6} xs={6} sm={6}>
                      <TextField
                        id="JaulaDoble"
                        onWheel={event => event.target.blur()}
                        InputProps={{
                          inputProps: { min: 0 },

                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={JaulaD}
                        onChange={(e) => setJaulaD(e.target.value)}
                        margin="normal"
                        placeholder="Jaula doble"
                        variant="outlined"
                        label="Jaula doble"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {false && (
                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="Procedencia"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={procedencia}
                      onChange={(e) => setProcedencia(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Procedencia"
                      placeholder="procedencia"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid
                  container
                  item
                  spacing={1}
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                >
                  <Grid item lg={6} md={6} xs={12} sm={12}>
                    <TextField
                      id="observaciones"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={observaciones}
                      onChange={(e) => setObservaciones(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Observaciones"
                      placeholder="observaciones"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isMediumScreen && hayEstadisticas && (
                <Grid container item lg={4} md={4} sm={12} xs={12}>
                  {estadisticas && (
                    <EstadisticasTable
                      onEstadisticas={estadisticasData}
                    ></EstadisticasTable>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid
              alignContent="center"
              container
              item
              spacing={1}
              lg={4}
              md={3}
              xs={12}
              sm={12}
              style={{ display: "flex" }}
            >
              <Grid item lg={6} md={6} xs={12} sm={12}>
                {isLoading2 ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={26} />
                  </div>
                )
                  : (
                    <Button
                      fullWidth
                      onClick={() => {
                        //setIsLoading2(true);
                        guardarCompra(props.history);
                      }}
                      disabled={deshabilitarPOST(tipoCompra)}
                      size="large"
                      variant="contained"
                      color="primary"
                    //component={Link} to={`/app/estadoCompras`}

                    //className={classes.createAccountButton}
                    >
                      Guardar
                    </Button>
                  )}
              </Grid>
              <Grid item lg={6} md={6} xs={12} sm={12}>
                <Button
                  //className={classes.createAccountButton}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleCancelar}
                  //component={Link}
                  //to={`/app/estadoCompras`}
                  disabled={isLoading2}
                >
                  Cancelar
                </Button>

              </Grid>
            </Grid>
            <Modal
              hideBackdrop
              open={openModal}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box className={classes.Modal}>
                <h2 id="child-modal-description">{mensaje}</h2>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to={`/app/estadoCompras`}
                  onClick={handleClose}
                  className={classes.buttonModal}
                >
                  Aceptar
                </Button>
              </Box>
            </Modal>
            <Snackbar open={openFeedback} autoHideDuration={2500} onClose={handleCloseFeedback}>
              <Alert elevation={6} variant="filled" onClose={handleCloseFeedback} severity={responseError ? "error" : "success"}>
                <AlertTitle>{responseError ? "Ocurrio un error al crear la compra" : `Compra creada con éxito: ${compraCreadaId}`}</AlertTitle>
              </Alert>
            </Snackbar>
            <Snackbar open={openFeedbackContacto} autoHideDuration={2000} onClose={handleCloseFeedbackContacto}>
              <Alert elevation={6} variant="filled" onClose={handleCloseFeedbackContacto} severity={responseErrorContacto ? "error" : "success"}>
                <AlertTitle>{responseErrorContacto ? "Oucrrio un error al crear el contacto de carga" : "Contacto  de carga creado con éxito"}</AlertTitle>
              </Alert>
            </Snackbar>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
export default NuevasCompras;

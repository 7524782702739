import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { withRouter } from "react-router-dom";
import {
  Slider,
  FormLabel,
  InputLabel,
  Tooltip,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { IconButton } from "@mui/material";
// import Autocomplete from "@mui/material/Autocomplete";
import useStyles from "./styles";
import UserService from "../../context/UserService";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import UserStateContext, { signOut } from "../../context/UserContext";
import { Grid } from "@mui/material";
// import jwt_decodde from "jwt-decode";
import moment from "moment";
// import clsx from "clsx";
import DownloadIcon from "@mui/icons-material/Download";
// import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
// import UploadIcon from "@mui/icons-material/Upload";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import { Preview } from "../variables/variables";
import EditCompraInv from "../editCompraInv/EditCompraInv";
import { Select, MenuItem } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link as LinKDom } from "react-router-dom";
import { withStyles } from "@material-ui/styles";

import ResponsiveDatePickers from "../../components/dateRangePicker/dateRangePicker";
import { CustomSearchButton } from "../../components/search-button/search-button";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
// import { light } from "@mui/material/styles/createPalette";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import AddIcon from '@mui/icons-material/Add';
import ModalAsociacionFaenaInvernada from "../ModalAsociacionFaenaInvernada/ModalAsociacionFaenaInvernada";
import { useMediaQuery } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import ModalAsociacionDtesInvernada from "../ModalAsociacionDtesInvernada/ModalAsociacionDtesInvernada";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { currencyFormatter } from "../../utils/utils";


const LargeTextTooltip = withStyles({
  tooltip: {
    fontSize: 'medium'
  }
})(Tooltip);

const StyledInput = ({ theme, ...props }, ref) => {
  return <input {...props} style={{
    border: '1px solid ' + theme.palette.primary.main,
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.primary,
    borderRadius: '6px',
    paddingTop: '3px',
    paddingBottom: '3px',
    minHeight: '28px',
    fontSize: 'inherit',
    fontFamily: 'inherit'
  }} />
}

const MERMA_STANDARD = 3;

function ComprasInvList(props) {
  const API_URL = window.config.API_URL;
  const params = new URLSearchParams(props.location.search)
  const formatter = currencyFormatter('ARG');
  const firstLoad = useRef(true);

  const { user } = useContext(UserStateContext)
  const permissions = user.permissions;
  const isInterno = user.type === 'Rioplatense'
  const isFeedlot = user.feedlot_id !== null
  const isContactoCarga = user.contacto_de_carga_id !== null
  const isInternal = user.type === 'Rioplatense'
  const canCreateRelations = permissions.feedlot_can_create_relations === true;
  const filtersCanView = isInterno
  const isFletero = user.is_fletero === true;
  const canEditFleteFields = permissions.feedlot_can_edit_compra_invernada === true;
  const canCreateDteRelations = permissions.feedlot_can_create_dte_relations === true;
  const theme = useTheme();
  var isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const origenRef = useRef();

  //clases de stylos
  var classes = useStyles();


  // para controlar modal de asociacion de faena a invernada
  const [openModalFaenaInvernada, setOpenModalFaenaInvernada] = useState(false);
  const [idInvernadaToAsociate, setIdInvernadaToAsociate] = useState(null);

  // para controlar modal de asociacion de dtes a invernada
  const [openModalAsociacionDtesInvernada, setOpenModalAsociacionDtesInvernada] = useState(false);
  const [idCompraInvToAsociate, setIdCompraInvToAsociate] = useState(null);


  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dataTables, setdataTables] = useState([]);
  const [ids, setIds] = useState({
    invernada: null,
    compra: null,
  })
  const [originalData, setOriginalData] = useState([])

  // for editing
  const [allowEdit, setAllowEdit] = useState(false);
  const [idBeingEdited, setIdBeingEdited] = useState(null);
  const [newOrigen, setNewOrigen] = useState("");
  const [newJaulaSimple, setNewJaulaSimple] = useState("");
  const [newJaulaDoble, setNewJaulaDoble] = useState("");

  const [feedlotDesp, setFeedlotDesp] = useState([]);
  const [contactoDC, setContactoDC] = useState([]);
  const [campos, setCampos] = useState([]);
  const [switchRecria, setswitchRecria] = useState(true);
  const [switchFeedlot, setSwitchFeedlot] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [switchViajesFaena, setSwitchViajesFaena] = useState(false);

  const [campoId, setCampoId] = useState(() => {
    const campoId = params.get('campoId')
    if (campoId !== null && campoId !== undefined && campoId !== 'null') {
      return campoId
    }
    return null

  });

  var [feedlot, setFeedlot] = useState(() => {
    const feedlot = params.get('feedlot')
    if (feedlot !== null && feedlot !== undefined && feedlot !== 'null') {
      return feedlot
    }
    return null
  });
  var [contactoDeCarga, setContactoDeCarga] = useState(() => {
    const contactoDeCarga = params.get('contactoDeCarga')
    if (contactoDeCarga !== null && contactoDeCarga !== undefined && contactoDeCarga !== 'null') {
      return contactoDeCarga
    }
    return null

  });
  var [isLoading2, setIsLoading2] = useState(false);
  var [estado, setEstado] = useState(() => {
    const estado = params.get('estado')
    if (estado !== null && estado !== undefined && estado !== 'null') {
      return estado
    }
    return null

  });
  const [fechaStart, setFechaStart] = useState(() => {
    const fechaStart = params.get('fechaStart')
    if (fechaStart) {
      return fechaStart
    }
    return moment().add(-30, "days").format("YYYY-MM-DD");
  });
  const [fechaEnd, setFechaEnd] = useState(() => {
    const fechaEnd = params.get('fechaEnd')
    if (fechaEnd) {
      return fechaEnd
    }
    return moment().add(7, "days").format("YYYY-MM-DD");
  });

  const handleSwitchRecria = useCallback((e) => {
    setswitchRecria(e.target.checked);
  }, [])

  const handleViajesFaena = useCallback((e) => {
    setSwitchViajesFaena(e.target.checked);
  }, [])

  const handleSwitchFeedlot = useCallback((e) => {
    setSwitchFeedlot(e.target.checked);
  }, [])

  const handleFeedback = useCallback((success, message) => {
    if (!success) {
      setResponseError(true)
    } else {
      setResponseError(false)
    }
    setResponseMessage(message)
    setOpenFeedback(true)
  }, []);

  // funcion para cerrar el modal de asociacion de faena a invernada
  const handleCloseModalFaenaInvernada = (noChanges = false) => {
    setOpenModalFaenaInvernada(false);
    setIdInvernadaToAsociate(null);
    if (!noChanges) {
      servidorGET();
    }
  }

  useEffect(() => {
    if (idBeingEdited !== null && allowEdit) {
      origenRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [allowEdit, idBeingEdited])

  const handleEditCell = useCallback((id) => {
    let originalRow = originalData.find((row) => row.id === id)
    setNewOrigen(originalRow.lugar_de_carga);
    setNewJaulaSimple(originalRow.jaula_simple);
    setNewJaulaDoble(originalRow.jaula_doble);
    setIdBeingEdited(id);
    setAllowEdit(true);
  }, [originalData])

  const handleSaveEdit = useCallback(async (dataIndex) => {
    setAllowEdit(false);
    setIdBeingEdited(null);
    setResponseError(false)
    setResponseMessage("");
    setOpenFeedback(false);
    let id = dataIndex.rowData[0];
    let data = {
      id_invernada: id,
      lugar_de_carga: newOrigen,
      jaula_simple: newJaulaSimple,
      jaula_doble: newJaulaDoble
    }
    try {
      let response = await UserService.updateCompraInvernadaByFletero(data);
      if (response.status !== 200) {
        throw new Error(response.data?.message || "Error al guardar los datos")
      }
      if (response.status === 200) {
        let originalRow = originalData.find((row) => row.id === id);
        originalRow.lugar_de_carga = newOrigen;
        originalRow.jaula_simple = newJaulaSimple;
        originalRow.jaula_doble = newJaulaDoble;
        setdataTables(dataParaTablas(originalData));
        setResponseMessage(response.data.message)
      }
    } catch (error) {
      setResponseMessage(error.message)
      setResponseError(true)
    } finally {
      setOpenFeedback(true)
    }
  }, [newOrigen, newJaulaSimple, newJaulaDoble, originalData])

  const getCompraOrigenDestino = useCallback((id) => {
    return originalData.find((row) => row.id === id)?.dataCompraOrigenDestino;
  }, [originalData])

  const isMermaGreaterThanStandard = useCallback((id) => {
    let compra = getCompraOrigenDestino(id);
    if (compra !== null && compra?.length > 0) {
      let mermaReal = compra[0].merma_real;
      let mermaAjustada = compra[0].merma_ajustada;
      return mermaReal > MERMA_STANDARD || mermaAjustada > MERMA_STANDARD
    }
    return false;
  }, [getCompraOrigenDestino]);

  const resaltarMerma = useCallback((id) => {
    let compra = getCompraOrigenDestino(id);
    if (compra !== null && compra?.length > 0) {
      let mermaReal = compra[0].merma_real;
      let mermaAjustada = compra[0].merma_ajustada;
      let acordado = compra[0].acordado;
      return (mermaReal > MERMA_STANDARD || mermaAjustada > MERMA_STANDARD) && acordado === false
    }
    return false;
  }, [getCompraOrigenDestino])

  const formatNumber = useCallback((number) => {
    try {
      return number.toLocaleString('es-AR')
    } catch (error) {
      return number
    }
  }, []);

  const formatFecha = useCallback((fecha) => {
    if (fecha === null) {
      return null;
    }
    try {
      return moment(fecha).format('DD/MM/YY')
    } catch (error) {
      return fecha
    }
  }, []);

  const getTipo = useCallback((is_recria, is_feedlot) => {
    if (is_recria) {
      return 'R'
    }
    if (is_feedlot) {
      return 'F'
    }
    return 'I'
  });

  const getDistinctValuesFromArray = useCallback((array, is_remitente = false) => {
    if (array === null) {
      return null;
    };
    if (is_remitente) {
      return [...new Set(array.map((row) => (row.includes('-') ? row.split('-')[1] : row)))].join(' , ');
    }
    return [...new Set(array)].join(' , ');
  }, []);

  // funcion para manejar data para la tabla
  const dataParaTablas = useCallback((data) => {
    let ArrayData = [];
    for (const [llave, valor] of Object.entries(data)) {

      if (valor["dataCompraOrigenDestino"] != null) {
        ArrayData.push([
          valor["id"],
          valor["dataCompraOrigenDestino"][0]["id"],
          getTipo(valor["is_recria"], valor["is_feedlot"]),
          valor["consignatario"]?.split("-").length > 1
            ? valor["consignatario"]?.split("-")[1]
            : valor["consignatario"],
          valor["contacto_de_Carga"],
          valor["lugar_de_carga"],
          valor["flete"]?.split("-").length > 1
            ? valor["flete"]?.split("-")[1]
            : valor["flete"],
          formatFecha(valor["fecha_de_carga"]),
          valor["jaula_simple"],
          valor["jaula_doble"],
          valor["feedlot"],
          valor["campo"],
          valor["cabezas_compradas"],
          valor["peso"],
          valor["desbaste"] + "%",
          formatter.format(valor["precio"]),
          valor["plazo"],
          valor["comision"] !== null ? valor["comision"] + "%" : null,
          valor["observaciones"],
          formatNumber(valor["dataCompraOrigenDestino"][0]["kilos_ticket"]),
          formatNumber(valor["dataCompraOrigenDestino"][0]["kilos_neto"]),
          valor["dataCompraOrigenDestino"][0]["cantidad_cargada"],
          valor["dataCompraOrigenDestino"][0]["promOrigen"],
          valor["dataCompraOrigenDestino"][0]["archivoOrigen"],
          valor["dataCompraOrigenDestino"][0]["horario_pesada_origen"],
          formatNumber(valor["dataCompraOrigenDestino"][0]["kilos_balanza"]),
          valor["dataCompraOrigenDestino"][0]["prom_destino"],
          valor["dataCompraOrigenDestino"][0]["cantidad_recepcionada"],
          valor["dataCompraOrigenDestino"][0]["muertos"] === 0 ? null : valor["dataCompraOrigenDestino"][0]["muertos"],
          valor["dataCompraOrigenDestino"][0]["archivo_destino"],
          valor["dataCompraOrigenDestino"][0]["horario_pesada_destino"],
          valor["dataCompraOrigenDestino"][0]["merma_real"] != null ? valor["dataCompraOrigenDestino"][0]["merma_real"] + "%" : null,
          formatNumber(valor["dataCompraOrigenDestino"][0]["kilos_ajustados"]),
          valor["dataCompraOrigenDestino"][0]["merma_ajustada"] != null
            ? valor["dataCompraOrigenDestino"][0]["merma_ajustada"] + "%"
            : null,
          formatFecha(valor["dataCompraOrigenDestino"][0]["fecha_de_ajuste"]),
          valor["dataCompraOrigenDestino"][0]['nro_lote'],
          valor["dataCompraOrigenDestino"][0]["acordado"],
          valor["dataCompraOrigenDestino"][0]["fecha_de_ajuste"] != undefined
            ? "Terminado"
            : "Parcial",
          valor["status_error"],
          //valor['in_sap'],
          //valor["errores"],
          getDistinctValuesFromArray(valor['fechas_faena']),
          getDistinctValuesFromArray(valor['remitentes_faena'], true),
          valor['sum_cabezas_faena'],
          valor['sum_peso_faena']
        ]);
      } else {
        ArrayData.push([
          valor["id"],
          null,
          getTipo(valor["is_recria"], valor["is_feedlot"]),
          valor["consignatario"]?.split("-").length > 1
            ? valor["consignatario"]?.split("-")[1]
            : valor["consignatario"],
          valor["contacto_de_Carga"],
          valor["lugar_de_carga"],
          valor["flete"]?.split("-").length > 1
            ? valor["flete"]?.split("-")[1]
            : valor["flete"],
          formatFecha(valor["fecha_de_carga"]),
          valor["jaula_simple"],
          valor["jaula_doble"],
          valor["feedlot"],
          valor["campo"],
          valor["cabezas_compradas"],
          valor["peso"],
          valor["desbaste"] + "%",
          formatter.format(valor["precio"]),
          valor["plazo"],
          valor["comision"] !== null ? valor["comision"] + "%" : null,
          valor["observaciones"],
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "Pendiente",
          valor["status_error"],
          //valor['in_sap'],
          //valor["errores"],
          getDistinctValuesFromArray(valor['fechas_faena']),
          getDistinctValuesFromArray(valor['remitentes_faena'], true),
          valor['sum_cabezas_faena'],
          valor['sum_peso_faena']
        ]);
      }
    }
    return ArrayData;
  }, [getDistinctValuesFromArray]);

  // funcion para cambiar status
  const setAcordado = useCallback(async (id, value) => {
    setResponseError(false)
    setResponseMessage("");
    try {
      let response = await UserService.putStatusFeedlot(id, value);
      if (response.status === 200) {
        let originalRow = originalData.find((row) => row.id === id);
        originalRow.dataCompraOrigenDestino[0].acordado = value;
        setdataTables(dataParaTablas(originalData));
        setResponseMessage("Estado actualizado correctamente")
      } else {
        throw new Error("Error al actualizar el estado")
      }
    } catch (error) {
      setResponseMessage(error.message)
      setResponseError(true)
    } finally {
      setOpenFeedback(true)
    }
  }, [originalData, dataParaTablas]);

  // const getUploadParams = ({ meta }) => {
  //   return { url: "https://httpbin.org/post" };
  // };

  // filtros personalizados
  function valueLabelFormat(value) {
    return value;
  }

  // abrir modal
  const handleOpen = useCallback((idInvernada, idCompra) => {
    // fila de la los datos para dsp buscar el ID
    setIds({
      invernada: idInvernada,
      compra: idCompra,
    });
    //setMensaje(`Borrar de compra?`);
    setOpen(true);
  }, []);

  // Saco la info del servidor nuevamente
  const servidorGET = useCallback(async (user) => {
    setdataTables([]);
    const url_params = {
        fechaStart: params.get('fechaStart') || fechaStart,
        fechaEnd: params.get('fechaEnd') || fechaEnd,
        feedlot: params.get('feedlot') || feedlot,
        contactoDeCarga: params.get('contactoDeCarga') || contactoDeCarga,
        estado: params.get('estado') || estado,
        campoId: params.get('campoId') || campoId
    }
    await UserService.getComprasList(
      //contactoDeCarga,
        url_params.contactoDeCarga,
      //feedlot,
        url_params.feedlot,
      "Invernada",
      //fechaStart,
      //fechaEnd,
      //estado,
        url_params.fechaStart,
        url_params.fechaEnd,
        url_params.estado,
      false,
      //campoId,
        url_params.campoId,
      switchRecria,
      switchFeedlot
    ).then(
      (response) => {
        if (response.status == 200) {
          let Info = dataParaTablas(response.data.data);
          setOriginalData(response.data.data)
          setdataTables(Info);
        }
      },
      (error) => {
        if (error.response.status == 401) {
          signOut(props.history);
        }
      },
    );
  }, [contactoDeCarga, feedlot, fechaStart, fechaEnd, estado, campoId, switchRecria, switchFeedlot, dataParaTablas]);

  // descargar documento firmado
  const onDownload = useCallback(async (val, title) => {
    const ext = val.split(".")[1];
    //const API_URL = window.config.API_URL;
    let blob = await fetch(val).then((r) => r.blob());
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${title}.${ext}`;
    link.click();
  }, []);

  // info inciail
  useEffect(() => {
    servidorGET();

    UserService.getListFeedlot().then(
      (response) => {
        if (response.status == 200) {
          let camposPerFeedlot = {};
          const dataTropaConsig = response.data.users.map((a) => ({
            id: a.id,
            nombre: a.nombre
          }));
          const camp = response.data.users.map((a) => {
            if (a.campos && a.campos.length > 0) {
              camposPerFeedlot[a.id] = a.campos.map((b) => (b))
            }
          })
          setFeedlotDesp(dataTropaConsig);
          //setCampos(camposPerFeedlot);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );
    // busco listado de contactos de carga
    UserService.getListContactoDeCarga().then(
      (response) => {
        if (response.status == 200) {
          setContactoDC(response.data.data);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );

    // get campos
    UserService.getCamposInvernada().then(
      (response) => {
        if (response.status == 200) {
          setCampos(response.data);
        }
      }
    )
  }, []);

  // cerrar Modal
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const resetIds = useCallback(() => {
    setIds({
      invernada: null,
      compra: null,
    });
  }, []);

  // borrar documento
  const deleteDOC = useCallback(async () => {
    try {
      let response = await UserService.deleteDataInv(ids.invernada);
      if (response.status == 200) {
        handleFeedback(true, "Datos eliminados correctamente")
        resetIds();
        servidorGET();
        handleClose();
      } else {
        throw new Error("Error al eliminar los datos de invernada")
      }
    } catch (error) {
      handleFeedback(false, error?.response?.data?.message || error.message)
    }
  }, [ids.invernada, handleFeedback]);

  // borrar compra completa
  const deleteCompra = useCallback(async () => {
    try {
      let response = await UserService.deleteCompra(ids.compra);
      if (response.status == 200) {
        handleFeedback(true, "Compra eliminada correctamente")
        resetIds();
        servidorGET();
        handleClose();
      } else {
        throw new Error("Error al eliminar la compra")
      }
    } catch (error) {
      handleFeedback(false, error?.response?.data?.message || error.message)
    }
  }, [ids.compra, handleFeedback]);


  //funcion para
  // const descargarNuevamenteDoc = async (id, tipo) => {
  //   await UserService.getInfoDocumento(id, tipo).then((response) => {
  //     if (response.status == 200) {
  //       const info = response.data[0];
  //       let form_data = new FormData();
  //       //form_data.append(info)7
  //       for (const [key, value] of Object.entries(info)) {
  //         form_data.append(key, value);
  //       }
  //       UserService.generarPDF(form_data, info["titulo"]);
  //     }
  //   });
  // };

  // const setDate = (event) => {
  //   setFechaEnd(moment(event.endDate).format("YYYY-MM-DD"));
  //   setFechaStart(moment(event.startDate).format("YYYY-MM-DD"));
  // };

  useEffect(() => {
    // update url params with filters
    props.history.push({
      search: `?fechaStart=${fechaStart}&fechaEnd=${fechaEnd}&feedlot=${feedlot}&contactoDeCarga=${contactoDeCarga}&estado=${estado}&campoId=${campoId}`,
    })
    let count = 0;
    if (feedlot !== null) {
      count++;
    }
    if (contactoDeCarga !== null) {
      count++;
    }
    if (estado !== null) {
      count++;
    }
    if (campoId !== null) {
      count++;
    }
    if (!switchRecria) {
      count++;
    }
    if (!switchFeedlot) {
      count++;
    }
    if (switchViajesFaena) {
      count++;
    }

    setBadgeCount(count);
  }, [fechaStart, fechaEnd, feedlot, contactoDeCarga, estado, campoId, switchRecria, switchFeedlot, switchViajesFaena])

  const handleCloseFeedback = useCallback(() => {
    setOpenFeedback(false);
  }, []);

  const downloadExcel = useCallback(async () => {
    setIsDownloadingExcel(true);
    setResponseMessage("")
    setResponseError(false)
    let response
    try {
      response = await UserService.getComprasList(
        contactoDeCarga,
        feedlot,
        "Invernada",
        fechaStart,
        fechaEnd,
        estado,
        true,
        campoId,
        switchRecria,
        switchFeedlot
      )
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'compras_invernada.xlsx');
        document.body.appendChild(link);
        link.click();
        setResponseMessage("Archivo descargado correctamente")
      }
    } catch (error) {
      setResponseMessage("Ocurrió un error al descargar el archivo")
      setResponseError(true)
    } finally {
      setIsDownloadingExcel(false);
      setOpenFeedback(true)
    }
  }, [contactoDeCarga, feedlot, fechaStart, fechaEnd, estado, campoId, switchRecria, switchFeedlot])

  const options = useMemo(() => ({
    responsive: "standard",
    virtualized: true,
    fixedSelectColumn: false,
    caseSensitive: false,
    rowsPerPage: 20,
    fixedHeader: true,
    tableBodyHeight: dataTables.length > 28 ? "700px" : (dataTables.length * 22 + 132).toString() + "px",
    rowsPerPageOptions: [20, 50, 100],
    sort: false,
    toolbar: isInternal,
    filter: isInternal,
    viewColumns: isInternal,
    download: false,
    print: false,
    search: isInternal,
    selectableRows: "none",

    customToolbar: !isInternal ? null : (displayData) => {
      return (
        <>
          {(!isDownloadingExcel) ? (
            <Tooltip title="Descargar a excel">
              <IconButton onClick={downloadExcel}>
                <CloudDownloadIcon></CloudDownloadIcon>
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress size={20} style={{ marginTop: '8px' }} />
          )}
        </>
      )
    }
  }), [isInternal, isDownloadingExcel, downloadExcel, dataTables]);

  const [mes, setMes] = useState(() => {
    return "";
  });

  const [week, setWeek] = useState(() => {
    return "";
  });

  const handleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleFechaChange = useCallback(() => {
    setMes("");
    setWeek("");
  }, []);
  const handleFeedlotChange = useCallback((e) => {
    setFeedlot(e.target.value);
  }, []);

  const handleCampoChange = useCallback((e) => {
    setCampoId(e.target.value);
  }, []);

  const handleEstadoChange = useCallback((e) => {
    //console.log(e.target.value);
    setEstado(e.target.value);
  }, []);

  const handleContactoDeCargaChange = useCallback((e) => {
    setContactoDeCarga(e.target.value);
  }, []);

  //limpiar filtros de feedlot y contacto de carga
  const handleCleanFilters = useCallback(() => {
    setFeedlot(null);
    setContactoDeCarga(null);
    setCampoId(null);
    setEstado(null);
    setSwitchFeedlot(true);
    setswitchRecria(true);
    setSwitchViajesFaena(false);
  }, []);

  const setDateFilter = useCallback((event) => {
    setMes("");
    setWeek("");
    setFechaEnd(moment(event.endDate).format("YYYY-MM-DD"));
    setFechaStart(moment(event.startDate).format("YYYY-MM-DD"));
  }, []);

  const cargarDataFiltro = useCallback(async () => {
    setIsLoading2(true);
    setdataTables([]);
    setOriginalData([])

    await UserService.getComprasList(
      contactoDeCarga,
      feedlot,
      "Invernada",
      fechaStart,
      fechaEnd,
      estado,
      false,
      campoId,
      switchRecria,
      switchFeedlot
    ).then(
      (response) => {
        if (response.status == 200) {
          let Info = dataParaTablas(response.data.data);
          setOriginalData(response.data.data)
          setdataTables(Info);
        }
      },
      (error) => {
        if (error.response.status == 401) {
          signOut(props.history);
        }
      },
    );
    setIsLoading2(false);
  }, [contactoDeCarga, feedlot, fechaStart, fechaEnd, estado, campoId, switchRecria, switchFeedlot, dataParaTablas]);

  const bigColumnOptions = useMemo(() => ({
    setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "80px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
    setCellProps: () => (isSmallScreen && { style: { minWidth: "80px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
  }), [isSmallScreen]);

  const defaultColumnOptions = useMemo(() => ({
    setCellProps: () => ({ style: { textAlign: 'center' } }),
  }), []);

  const yellowHeaderOptions = useMemo(() => ({
    setCellHeaderProps: () => ({
      style: { color: theme.palette.spotlight.yellow },
    })
  }), [theme.palette.spotlight.yellow]);

  const greenHeaderOptions = useMemo(() => ({
    setCellHeaderProps: () => ({
      style: { color: theme.palette.spotlight.green },
    })
  }), [theme.palette.spotlight.green]);

  const frozenColumnOptions = useMemo(() => ({
    setCellProps: () => ({
      style: !isSmallScreen ? {
        width: '100px',
        background:theme.palette.tables.header,
        position:'sticky',
        whiteSpace: "wrap",
        right: '0px',
        zIndex: 100,
      } : {}
    }),
    setCellHeaderProps: () => ({
      className: classes.centeredHeader,
      style: !isSmallScreen ? {
        width: '100px',
        background:theme.palette.tables.header,
        position:'sticky',
        whiteSpace: "wrap",
        right: '0px',
        zIndex: 101,
      } : {}
    }),
  }), [isSmallScreen, classes.centeredHeader, theme.palette.tables.header]);
  
  const frozenColumnOptionsLeft = useMemo(() => ({
    setCellProps: () => ({
      style: !isSmallScreen ? {
        width: '100px',
        background:theme.palette.tables.header,
        position:'sticky',
        whiteSpace: "wrap",
        left: '0px',
        zIndex: 100,
      } : {}
    }),
    setCellHeaderProps: () => ({
      className: classes.centeredHeader,
      style: !isSmallScreen ? {
        width: '100px',
        background:theme.palette.tables.header,
        position:'sticky',
        whiteSpace: "wrap",
        left: '0px',
        zIndex: 101,
      } : {}
    }),
  }), [isSmallScreen, classes.centeredHeader, theme.palette.tables.header]);

  const handleOpenModalDtes = useCallback((id) => {
    setIdCompraInvToAsociate(id);
    setOpenModalAsociacionDtesInvernada(true);
  }, [])

  const handleChangeDataFromDtesAssociation = useCallback((idCompra, newData, responseMessage) => {
    // will only be called if the association was successful
    let originalRow = originalData.find((row) => row.id === idCompra);
    originalRow.remitente = newData.remitente;
    originalRow.consignatario = newData.consignatario;
    originalRow.lugar_de_carga = newData.lugar_de_carga;
    setdataTables(dataParaTablas(originalData));
    setResponseMessage(responseMessage)
    setOpenFeedback(true)
  }, [originalData, dataParaTablas])

  return (
    <>
      <Grid item container justifyContent="space-between" spacing={0}>
        <Grid item container alignItems="center" spacing={2} lg={10}>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <InputLabel id="type">Rango de Fechas</InputLabel>
            <ResponsiveDatePickers
              setValorFecha={setDateFilter}
              fechaInicial={fechaStart}
              fechaFinal={fechaEnd}
              onChange={handleFechaChange}
            ></ResponsiveDatePickers>
          </Grid>
          {(filtersCanView && showFilters) && (
            <>
              <Grid item lg={1} md={12} sm={12} xs={12}>
                <InputLabel id="type">Contacto</InputLabel>
                <Select
                  labelId="type"
                  variant="outlined"
                  id="ContactoDeCarga"
                  value={contactoDeCarga}
                  label="Contacto de Carga*"
                  onChange={handleContactoDeCargaChange}
                  fullWidth
                >
                  {contactoDC.map((link) => {
                    return (
                      <MenuItem value={link.id}>
                        {link.username} {link.last_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item lg={1} md={12} sm={12} xs={12}>
                <InputLabel id="type">Feedlot</InputLabel>
                <Select
                  labelId="type"
                  variant="outlined"
                  id="Feedlot"
                  value={feedlot}
                  label="Feedlot"
                  onChange={handleFeedlotChange}
                  fullWidth
                >
                  {feedlotDesp.map((link) => {
                    return (
                      <MenuItem value={link.id} key={link.id}>
                        {link.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item lg={1} md={12} sm={12} xs={12}>
                <InputLabel id="type">Campo</InputLabel>
                <Select
                  labelId="type"
                  variant="outlined"
                  id="Campos"
                  value={campoId}
                  label="Campos"
                  onChange={handleCampoChange}
                  fullWidth
                >
                  {campos.map((link) => {
                    return (
                      <MenuItem value={link.id} key={link.id}>
                        {link.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item lg={1} md={12} sm={12} xs={12}>
                <InputLabel id="type">Estado</InputLabel>
                <Select
                  labelId="type"
                  variant="outlined"
                  id="Feedlot"
                  label="Estado"
                  value={estado}
                  onChange={handleEstadoChange}
                  fullWidth
                >
                  <MenuItem value="Parcial">Parcial</MenuItem>
                  <MenuItem value="Terminado">Terminado</MenuItem>
                </Select>
              </Grid>
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <FormGroup>
                  <Stack direction="row" spacing={1}>
                    <FormControlLabel control={
                      <Switch
                        checked={switchRecria}
                        onChange={handleSwitchRecria}
                        inputProps={{ 'aria-label': 'Recria' }}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />}
                      label="Recria" />
                    <FormControlLabel control={
                      <Switch
                        checked={switchFeedlot}
                        onChange={handleSwitchFeedlot}
                        inputProps={{ 'aria-label': 'feedlot' }}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />}
                      label="Feedlot" />
                      {(isInterno || isFletero) && (
                      <FormControlLabel control={
                      <Switch
                        checked={switchViajesFaena}
                        onChange={handleViajesFaena}
                        inputProps={{ 'aria-label': 'feedlot' }}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />}
                      label="Viajes Faena" />)}
                  </Stack>
                </FormGroup>
              </Grid>
            </>
          )}
          <Grid item lg={2} sm={12} md={12} xs={12}>
            {isLoading2 ? (
              <CircularProgress size={26} style={{ marginTop: "20px" }} />
            ) : (
              <div className={classes.searchButtons} style={{ marginLeft: showFilters && '10px' }}>
                <Tooltip title={showFilters ? "Ocultar Filtros" : "Mostrar Filtros"}>
                  <IconButton onClick={handleFilters}>
                    <Badge badgeContent={badgeCount} color="secondary">
                      {!showFilters ? (
                        <FilterListIcon
                          sx={{ color: theme.palette.primary.main }}
                          fontSize="large"
                        ></FilterListIcon>
                      ) : (
                        <CloseIcon
                          sx={{ color: theme.palette.primary.main }}
                          fontSize="large"
                        >
                        </CloseIcon>
                      )}
                    </Badge>
                  </IconButton>
                </Tooltip>
                {(feedlot !== null ||
                  contactoDeCarga !== null ||
                  estado !== null ||
                  campoId !== null
                ) && (
                    <Tooltip title="Limpiar Filtros">
                      <IconButton onClick={handleCleanFilters}>
                        <FilterAltOffIcon
                          sx={{ color: theme.palette.primary.main }}
                          fontSize="large"
                        ></FilterAltOffIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                <CustomSearchButton
                  onClick={cargarDataFiltro}
                  color={theme.palette.primary.main}
                />
              </div>
            )}
          </Grid>
        </Grid>
        {permissions.compras_can_create && (
          <Grid item lg={2} sm={12} md={12} xs={12} className={classes.nuevaCompraButton}>
            <Button
              size="big"
              variant="contained"
              color="primary"
              href="#/app/nuevasCompras?tipo=Invernada"
              startIcon={<AddShoppingCartIcon style={{ color: theme.palette.primary.main }} />}
            >
              Nueva Compra
            </Button>
          </Grid>)}
      </Grid>
      <Grid
        item
        lg={12}
        sm={12}
        style={{ display: "table", tableLayout: "fixed", width: "100%" }}
      >
        <MUIDataTable
          title={
            <>
              <span>
                {/* <h2>Listado compras</h2> */}
                <div ref={origenRef} style={{ display: 'flex', alignItems: 'center' }}>
                  Resumen desde:&nbsp;&nbsp;
                  <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
                  &nbsp;&nbsp;hasta:&nbsp;&nbsp;
                  <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
                  {allowEdit && (
                    <p style={{ color: theme.palette.error.main, fontSize: '16px' }}>&nbsp;&nbsp;- No olvide guardar los cambios</p>
                  )}
                </div>
              </span>
            </>
          }
          data={dataTables}
          options={options}
          columns={[
            {
              name: "ID",
              options: {
                ...defaultColumnOptions,
                customBodyRender: (value, dataIndex, updateValue) => {
                  const habilitar_edicion = originalData.find((row) => (row.id === dataIndex.rowData[0]))?.habilitar_edicion;
                  if (permissions.compras_can_edit && habilitar_edicion) {
                    return (
                      <Link
                        variant="text"
                        sx={{
                          color: theme.palette.totals.subtotal,
                        }}
                        component={LinKDom}
                        to={`/app/editCompra/${value}`}
                      >
                        {value}
                      </Link>
                    )
                  } else {
                    return (
                      <div>{value}</div>
                    )
                  }
                }
              }
            },
            {
              name: "IdDatosInv",
              options: {
                filter: true,
                display: false,
              },
            },
            {
              name: "Tipo",
              options: {
                ...defaultColumnOptions,
              }
            },
            {
              name: "Consignatario",
              options: {
                ...bigColumnOptions
              }
            },
            {
              name: "Contacto",
              options: {
                ...bigColumnOptions
              }
            },
            {
              name: "Origen",
              options: {
                ...bigColumnOptions,
                customBodyRender: (value, dataIndex, updateValue) => {
                  let id = dataIndex.rowData[0];
                  if (id === idBeingEdited && allowEdit) {
                    return (
                      <StyledInput theme={theme} value={newOrigen} type="text" onChange={(e) => (setNewOrigen(e.target.value))} />
                    )
                  } else {
                    return value
                  }
                }
              }
            },
            {
              name: "Flete",
              options: {
                ...bigColumnOptions
              }
            },
            {
              name: "F. Carga",
              options: {
                filter: true,
                ...bigColumnOptions
              },
            },
            {
              name: "JS",
              options: {
                ...defaultColumnOptions,
                customBodyRender: (value, dataIndex, updateValue) => {
                  let id = dataIndex.rowData[0];
                  if (id === idBeingEdited && allowEdit) {
                    return (
                      <StyledInput value={newJaulaSimple} type="number" onChange={(e) => (setNewJaulaSimple(e.target.value))} theme={theme} />
                    )
                  } else {
                    return value
                  }
                }
              }
            },
            {
              name: "JD",
              options: {
                ...defaultColumnOptions,
                customBodyRender: (value, dataIndex, updateValue) => {
                  let id = dataIndex.rowData[0];
                  if (id === idBeingEdited && allowEdit) {
                    return (
                      <StyledInput value={newJaulaDoble} type="number" onChange={(e) => (setNewJaulaDoble(e.target.value))} theme={theme} />
                    )
                  } else {
                    return value
                  }
                }
              }
            },
            {
              name: "Feedlot",
              options: {
                ...bigColumnOptions
              }
            },
            {
              name: "Campo",
              options: {
                ...bigColumnOptions
              }
            },
            {
              name: "Cabs",
              options: {
                ...defaultColumnOptions,
              }
            },
            {
              name: "Km",
              options: {
                ...defaultColumnOptions,
              }
            },
            {
              name: "Desbaste",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno || isContactoCarga,
              },
            },
            {
              name: "Precio",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno,
              },
            },

            {
              name: "Plazo",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno,
              },
            },
            {
              name: "Comis",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno,
              },
            },

            {
              name: "Obs",
              options: {
                filter: true,
                display: isInterno,
              },
            },
            {
              name: "Kg Ticket",
              options: {
                filter: true,
                display: isContactoCarga || isInterno,
                ...defaultColumnOptions,
                ...greenHeaderOptions
              },
            },
            {
              name: "Kg Neto",
              options: {
                filter: true,
                display: isContactoCarga || isInterno,
                ...defaultColumnOptions,
                ...greenHeaderOptions
              },
            },
            {
              name: "C. Carga",
              options: {
                filter: true,
                display: isContactoCarga || isInterno,
                ...defaultColumnOptions,
                ...greenHeaderOptions
              },
            },
            {
              name: "Prom",
              options: {
                filter: true,
                display: isContactoCarga || isInterno,
                ...defaultColumnOptions,
                ...greenHeaderOptions
              },
            },
            {
              name: "File O.",
              options: {
                filter: true,
                display: isContactoCarga || isInterno,
                /* sort: false,
                empty: false, */
                ...greenHeaderOptions,
                ...defaultColumnOptions,

                customBodyRender: (value, dataIndex, updateValue) => {
                  if (value != undefined && value != "") {
                    // compruebo que no sea el usuario logueado
                    return (
                      <>
                        <div className={classes.contained}>
                          <Button
                            aria-label="edit"
                            size="medium"
                            color="primary"
                            //component={Link}
                            //to={API_URL.concat(value)}
                            download="How-to-download-file.pdf"
                            onClick={() => {
                              onDownload(
                                API_URL + "/media/" + value,
                                "Archivo_Origen",
                              );
                            }}
                          >
                            <DownloadIcon
                              fontSize="small"
                              style={{ color: theme.palette.primary.main }}
                            />
                          </Button>
                        </div>
                      </>
                    );
                  }
                },
                //corchete de options de la ultima coluimna
              },
            },

            {
              name: "Hora Peso",
              options: {
                filter: true,
                display: isContactoCarga || isInterno,
                ...defaultColumnOptions,
                ...greenHeaderOptions
              },
            },

            {
              name: "Kg Balanza",
              options: {
                filter: true,
                display: isFeedlot || isInterno,
                ...defaultColumnOptions,
                ...yellowHeaderOptions
              },
            },

            {
              name: "Prom",
              options: {
                filter: true,
                display: isFeedlot || isInterno,
                ...defaultColumnOptions,
                ...yellowHeaderOptions
              },
            },

            {
              name: "C Recep.",
              options: {
                filter: true,
                display: isFeedlot || isInterno,
                ...defaultColumnOptions,
                ...yellowHeaderOptions
              },
            },
            {
              name: "Muertos",
              options: {
                filter: true,
                display: isFeedlot || isInterno,
                ...defaultColumnOptions,
                ...yellowHeaderOptions
              },
            },
            {
              name: "File D.",
              options: {
                filter: true,
                /* sort: false,
                empty: false, */
                display: isFeedlot || isInterno,
                ...yellowHeaderOptions,
                ...defaultColumnOptions,

                customBodyRender: (value, dataIndex, updateValue) => {
                  if (value != undefined && value != "") {
                    // compruebo que no sea el usuario logueado
                    return (
                      <>
                        <div className={classes.contained}>
                          <Button
                            aria-label="edit"
                            size="medium"
                            color="primary"
                            //component={Link}
                            //to={API_URL.concat(value)}
                            download="How-to-download-file.pdf"
                            onClick={() => {
                              onDownload(
                                API_URL + "/media/" + value,
                                "Archivo_Destino",
                              );
                            }}
                          >
                            <DownloadIcon
                              fontSize="small"
                              style={{ color: theme.palette.primary.main }}
                            />
                          </Button>
                        </div>
                      </>
                    );
                  }
                },
                //corchete de options de la ultima coluimna
              },
            },
            {
              name: "Hora Peso",
              options: {
                filter: true,
                display: isFeedlot || isInterno,
                ...defaultColumnOptions,
                ...yellowHeaderOptions
              },
            },
            {
              name: "Merma Real",
              options: {
                filter: true,
                display: isInterno,
                filterType: "custom",
                setCellProps: (cellValue, rowIndex, columnIndex) => {
                  let id = dataTables[rowIndex][0];
                  if (resaltarMerma(id)) {
                    return {
                      style: {
                        backgroundColor: theme.palette.spotlight.red,
                      },
                    };
                  }
                },
                customFilterListOptions: {
                  render: (v) => {
                    if (v[0]) {
                      return `Merma hasta:${v[0]}`;
                    }
                    return [];
                  },
                  update: (filterList, filterPos, index) => {
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                  },
                },
                filterOptions: {
                  logic(value, filters) {
                    if (filters.length) return filters < value;
                    return false;
                  },
                  display: (
                    filterList,
                    onChange,
                    index,
                    column,
                    filterData,
                  ) => (
                    <div>
                      <FormLabel>Merma Real</FormLabel>
                      <FormGroup row>
                        <Slider
                          defaultValue={filterList[index][0]}
                          onChange={(event, newValue) => {
                            filterList[index][0] = filterData[27][newValue];
                            onChange(filterList[index], index, column);
                          }}
                          valueLabelFormat={(value) =>
                            valueLabelFormat(filterData[27][value])
                          }
                          getAriaValueText={(value) =>
                            valueLabelFormat(filterData[27][value])
                          }
                          valueLabelDisplay="auto"
                          min={0}
                          max={filterData[27].length - 1}
                        />
                      </FormGroup>
                    </div>
                  ),
                },
                ...defaultColumnOptions,
              },
            },

            {
              name: "Kg Ajust.",
              options: {
                filter: true,
                display: isInterno,
                setCellProps: (cellValue, rowIndex, columnIndex) => {
                  let id = dataTables[rowIndex][0];
                  if (resaltarMerma(id)) {
                    return {
                      style: {
                        backgroundColor: theme.palette.spotlight.red,
                      },
                    };
                  }
                },
                ...defaultColumnOptions,
              },
            },
            {
              name: "Merma Ajust.",
              options: {
                filter: true,
                display: isInterno,
                ...defaultColumnOptions,
                filterType: "custom",
                setCellProps: (cellValue, rowIndex, columnIndex) => {
                  let id = dataTables[rowIndex][0];
                  if (resaltarMerma(id)) {
                    return {
                      style: {
                        backgroundColor: theme.palette.spotlight.red,
                      },
                    };
                  }
                },

                customFilterListOptions: {
                  render: (v) => {
                    if (v[0]) {
                      return `Merma hasta:${v[0]}`;
                    }
                    return [];
                  },
                  update: (filterList, filterPos, index) => {
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                  },
                },
                filterOptions: {
                  logic(value, filters) {
                    if (filters.length) return filters < value;
                    return false;
                  },
                  display: (
                    filterList,
                    onChange,
                    index,
                    column,
                    filterData,
                  ) => (
                    <div>
                      <FormLabel>Merma Ajustada</FormLabel>
                      <FormGroup row>
                        <Slider
                          defaultValue={filterList[index][0]}
                          onChange={(event, newValue) => {
                            filterList[index][0] = filterData[29][newValue];
                            onChange(filterList[index], index, column);
                          }}
                          valueLabelFormat={(value) =>
                            valueLabelFormat(filterData[29][value])
                          }
                          getAriaValueText={(value) =>
                            valueLabelFormat(filterData[29][value])
                          }
                          valueLabelDisplay="auto"
                          min={0}
                          max={filterData[29].length - 1}
                        />
                      </FormGroup>
                    </div>
                  ),
                },
                /* customBodyRender: (valor, dataIndex, updateValue) => {
                  if(valor!=undefined){
                  if ((valor.split('%')[0])>3 && dataIndex.rowData[32]!=true ){
                    return (
                      <div className={classes.rojoCircle} >{valor}</div>
                      )
                  }else{
                    return (
                    <p>{valor}</p>
                    )
                  }}
                  
              } */
              },
            },

            {
              name: "Fecha Ajust.",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno,
                setCellProps: (cellValue, rowIndex, columnIndex) => {
                  let id = dataTables[rowIndex][0];
                  if (resaltarMerma(id)) {
                    return {
                      style: {
                        backgroundColor: theme.palette.spotlight.red,
                      },
                    };
                  }
                },
              },
            },
            {
              name: "Lote",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno,
              },
            },
            {
              name: "Resaltar Merma",
              options: {
                filter: true,
                ...defaultColumnOptions,
                display: isInterno,

                /* sort: false,
                empty: false, */

                customBodyRender: (valor, dataIndex, updateValue) => {
                  let id = dataIndex.rowData[0];
                  if (isMermaGreaterThanStandard(id)) {
                    return (
                      <>
                        <div className={classes.contained}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={valor}
                            label="Age"
                            onChange={(e) => {
                              updateValue(e.target.value);
                              //dataIndex.rowData[15]= e.target.value
                              setAcordado(
                                dataIndex.rowData[0],
                                e.target.value,
                              );
                            }}
                          >
                            <MenuItem value={true}>NO</MenuItem>
                            <MenuItem value={false}>SI</MenuItem>
                          </Select>
                        </div>
                      </>
                    );
                  }
                },
              },
            },
            {
              name: "Status",
              options: {
                filter: true,
                display: isInterno,
                ...defaultColumnOptions,
                customBodyRender: (valor, dataIndex, updateValue) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {valor == "Parcial" ? (
                        <CircleRoundedIcon className={classes.yellow} />
                      ) : valor == "Terminado" ? (
                        <CircleRoundedIcon className={classes.verde} />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                },
              },
            },
            {
              name: "SAP",
              options: {
                filter: true,
                display: isInterno,
                ...defaultColumnOptions,
                customBodyRender: (valor, dataIndex, updateValue) => {
                  const in_sap = originalData.find((row) => (row.id === dataIndex.rowData[0]))?.in_sap;
                  const errores = originalData.find((row) => (row.id === dataIndex.rowData[0]))?.errores;
                  const oc_error = originalData.find((row) => (row.id === dataIndex.rowData[0]))?.with_oc_errors;
                  const errores_oc = originalData.find((row) => (row.id === dataIndex.rowData[0]))?.errores_creacion_oc;
                  if (!valor && in_sap) {
                    return (
                      <LargeTextTooltip
                        title={'Todo OK'}
                      >
                        <CircleRoundedIcon className={classes.verde} />
                      </LargeTextTooltip>
                    )
                  } else if (!valor && !in_sap && oc_error) { 
                    return (
                      <LargeTextTooltip
                      title={
                        <>
                        <b>Ocurrio un error al crear la OC en SAP</b>
                        <ul>
                          {errores_oc.map((value, index) => {
                            if (value !== null) {
                              return (
                                <li key={index}>{value}</li>
                              )
                            }
                            return null;
                          })}
                        </ul>
                        </>
                      }>
                        <CircleRoundedIcon className={classes.orange} />
                      </LargeTextTooltip>
                    )
                  } else if (!valor && !in_sap) {
                    return (
                      <LargeTextTooltip
                        title={'Sin errores, pero aun no se actualizó en SAP'}
                      >
                        <CircleRoundedIcon className={classes.yellow} />
                      </LargeTextTooltip>
                    )
                  } else {
                    return (
                      <LargeTextTooltip title={
                        <ul>
                          {errores.map((value, index) => {
                            if (value !== null) {
                              return (
                                <li key={index}>{value}</li>
                              )
                            }
                            return null;
                          })}
                        </ul>
                      }>
                        <CircleRoundedIcon className={classes.rojo} />
                      </LargeTextTooltip>
                    );
                  }
                },
              },
            },
            {
              name: "Fechas F.",
              options: {
                display: (isInterno || isFletero) && switchViajesFaena,
                ...bigColumnOptions
              }
            },
            {
              name: "Remitentes F.",
              options: {
                display: (isInterno || isFletero) && switchViajesFaena,
                ...bigColumnOptions

              }
            },
            {
              name: "Cabs F.",
              options: {
                ...bigColumnOptions,
                display: (isInterno || isFletero) && switchViajesFaena
              }
            },
            {
              name: "Peso F.",
              options: {
                ...bigColumnOptions,
                display: (isInterno || isFletero) && switchViajesFaena
              }
            },
            {
              name: "Acciones",
              options: {
                filter: true,
                /* sort: false,
                empty: false, */
                display: true,
                ...defaultColumnOptions,
                ...frozenColumnOptions,

                customBodyRender: (value, dataIndex, updateValue) => {
                  // mando el valor de ID para la funcion de borrar documento y abro el modal.
                  let id = dataIndex.rowData[0];
                  const isBeingEddited = id === idBeingEdited;
                  const habilitar_edicion = originalData.find((row) => (row.id === dataIndex.rowData[0]))?.habilitar_edicion;
                  return (
                    <>
                      <div className={classes.contained}>
                        <>
                          {(permissions.feedlot_can_edit && habilitar_edicion) && (
                            <EditCompraInv
                              dataRow={dataIndex.rowData}
                              idCompra={dataIndex.rowData[0]}
                              cargarData={servidorGET}
                              origen={"editar"}
                              dataOriginal={originalData}
                            ></EditCompraInv>
                          )}
                          {(permissions.feedlot_can_delete && habilitar_edicion) && (
                            <Tooltip title={"Borrar"}>
                              <IconButton
                                aria-label="borrar"
                                size="small"
                                color="primary"
                                onClick={() => handleOpen(dataIndex.rowData[1], dataIndex.rowData[0])}
                              >
                                <DeleteIcon
                                  color="primary"
                                  fontSize="small"
                                  style={{ color: theme.palette.primary.main }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {(canCreateRelations) && (
                            <Tooltip title={"Asociar Faena"}>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => { setOpenModalFaenaInvernada(true); setIdInvernadaToAsociate(dataIndex.rowData[0]) }}
                              >
                                <AgricultureIcon
                                  color="primary"
                                  fontSize="small"
                                  style={{ color: theme.palette.primary.main }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {(canCreateDteRelations && habilitar_edicion) && (
                            <Tooltip title={"Asociar Dtes"}>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => { handleOpenModalDtes(dataIndex.rowData[0]) }}
                              >
                                <AddIcon
                                  color="primary"
                                  fontSize="small"
                                  style={{ color: theme.palette.primary.main }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {canEditFleteFields && (
                            <Tooltip title={"Editar Tabla"}>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => { allowEdit && isBeingEddited ? handleSaveEdit(dataIndex) : handleEditCell(dataIndex.rowData[0]) }}
                                disabled={!isBeingEddited && allowEdit}
                              >
                                {(allowEdit && isBeingEddited) ? (
                                  <SaveIcon
                                    color="primary"
                                    fontSize="small"
                                    style={{ color: theme.palette.primary.main }}
                                  />
                                ) : (
                                  <ColorLensIcon
                                    color="primary"
                                    fontSize="small"
                                    style={{ color: theme.palette.primary.main }}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      </div>
                      <Modal
                        hideBackdrop
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <Box className={classes.Modal}>
                          <h2 id="child-modal-description">Borrar datos de invernada o compra completa?</h2>
                          {ids.invernada != null && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => {
                                deleteDOC();
                              }}
                              className={classes.buttonModal}
                            >
                              Borrar datos invernada
                            </Button>
                          )}
                          {ids.compra != null && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => {
                                deleteCompra();
                              }}
                              className={classes.buttonModalCompra}
                            >
                              Borrar compra completa
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                              handleClose();
                            }}
                            className={classes.buttonModalCancelar}
                          >
                            Cancelar
                          </Button>
                        </Box>
                      </Modal>
                    </>
                  );
                },
                //corchete de options de la ultima coluimna
              },
            },
          ]} // aca terina Columns
        />
        <ModalAsociacionFaenaInvernada open={openModalFaenaInvernada} handleClose={handleCloseModalFaenaInvernada} idInvernada={idInvernadaToAsociate} />
        <ModalAsociacionDtesInvernada 
          open={openModalAsociacionDtesInvernada} 
          setOpen={setOpenModalAsociacionDtesInvernada} 
          idCompra={idCompraInvToAsociate}
          handleChangeData={handleChangeDataFromDtesAssociation}
          cargarData={servidorGET}
          />
      </Grid>
      <Snackbar
        open={openFeedback}
        autoHideDuration={3000}
        onClose={handleCloseFeedback}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={responseError ? "error" : "success"}
        >
          <AlertTitle>
            {responseMessage}
          </AlertTitle>
        </Alert>
      </Snackbar>
      <Grid item lg={12} sm={12}></Grid>
    </>
  );
}

export default withRouter(ComprasInvList);

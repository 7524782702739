import axios from "axios";
import authHeader from "./auth-header";

import { AxiosInstance } from "axios";

const API_URL = window.config.API_URL;
const LOCAL_URL = window.config.LOCAL_URL;

const RIO_API_URL = window.config.RIO_API_URL;
const user = JSON.parse(localStorage.getItem("user"));

let payload = { fecha: "2022-11-10" };

/* const data = { "fecha": "2022-11-10" };*/
class UserService {
  //listado de usuarios
  getUserList(tipo) {
    return axios.get(API_URL + `/auth/user_list/?tipo=${tipo}`, { headers: authHeader()});
  }
  // apuntar a endpoint
  /*  async getStockResult(fecha) {
    return axios.get(RIO_API_URL+`/stockweb/?fecha=${fecha}`);

  } */
  // APUNTA A API listado STOCK
  async getStockResult(fecha, type) {
    return axios.get(API_URL + `/auth/stock/?fecha=${fecha}&type=${type}`, {
      headers: authHeader(),
    });
  }

  // funcion para pedir informacion de Faena con fecha de inicio y fecha final.
  getFaena(
    fechaInicio,
    FechaFinal,
    tipo,
    usuarioFaena,
    tipoCompra,
    marca,
    especie,
    remitente,
    consig,
    marcasAgrup,
    tropaToSearch,
    establecimientos
  ) {
    return axios.get(
      API_URL +
      `/auth/faena/?start_date=${fechaInicio}&end_date=${FechaFinal}&tipo=${tipo}&marca=${marca}&especie=${especie}&usuarioFaena=${usuarioFaena}&tipoCompra=${tipoCompra}&remitente=${remitente}&consig=${consig}&marcasAgrup=${marcasAgrup}&tropaSearch=${tropaToSearch}&establecimientos=${establecimientos}`,
      { headers: authHeader() },
    );
  }
  //fucion para pedir informacion de TROPA
  getTropa(
    fechaInicio,
    FechaFinal,
    usuarioFaena,
    tipoCompra,
    marca,
    especie,
    remitente,
    consig,
    marbling,
    area,
    color,
    costo,
    tropaToSearch,
    establecimientos
  ) {
    return axios.get(
      API_URL +
      `/auth/tropa/?start_date=${fechaInicio}&end_date=${FechaFinal}&marca=${marca}&especie=${especie}&usuarioFaena=${usuarioFaena}&tipoCompra=${tipoCompra}&remitente=${remitente}&consig=${consig}&marbling=${marbling}&area=${area}&color=${color}&costo=${costo}&tropaSearch=${tropaToSearch}&establecimientos=${establecimientos}`,
      { headers: authHeader() },
    );
  }
  getTropaExcel(
    fechaInicio,
    FechaFinal,
    usuarioFaena,
    tipoCompra,
    marca,
    especie,
    remitente,
    consig,
    marbling,
    area,
    color,
    costo,
    tropaToSearch,
    pdf,
    establecimientos
  ) {
    return axios.get(
      API_URL +
      `/auth/tropa_excel/?start_date=${fechaInicio}&end_date=${FechaFinal}&marca=${marca}&especie=${especie}&usuarioFaena=${usuarioFaena}&tipoCompra=${tipoCompra}&remitente=${remitente}&consig=${consig}&marbling=${marbling}&area=${area}&color=${color}&costo=${costo}&tropaSearch=${tropaToSearch}&pdf=${pdf}&establecimientos=${establecimientos}`,
      { headers: authHeader(), responseType: "blob" },
    );
  }
  // apuntar a endpoint..
  getProveedor(cuit) {
    return axios.get(API_URL + `/auth/proveedores/?cuit=${cuit}`, {
      headers: authHeader(),
    });
  }

  getProveedorRazonS(razonS) {
    return axios.get(API_URL + `/auth/proveedores2/?razonS=${razonS}`, {
      headers: authHeader(),
    });
  }
  //pedir informacion de USUARIO específico
  getUserInfo(user_id) {
    return axios.get(API_URL + `/auth/userInfo/?id=${user_id}`, {
      headers: authHeader(),
    });
  }

  async getUsuariosFaena() {
    return axios.get(API_URL + `/auth/usuariosFaena/`, {
      headers: authHeader(),
    });
  }

  getCompra(id) {
    return axios.get(API_URL + `/auth/compra/${id}`, {
      headers: authHeader(),
    });
  }

  //COMPRAS!
  //Info desplegable DestinoComercial
  async getDestinoComercial() {
    const response = await axios.get(API_URL + `/auth/destino_list/`, {
      headers: authHeader(),
    });
    if (response.status == 200) {
      let destino = [...new Set(response.data.map((x) => x.Destino_Comercial))];
      let Raza = [...new Set(response.data.map((x) => x.Raza))];
      let Cuota = [...new Set(response.data.map((x) => x.Cuota))];
      let Especie = [...new Set(response.data.map((x) => x.Especie))];

      const responsePrograma = await axios.get(
        API_URL + `/auth/programa_list/`,
        { headers: authHeader() },
      );
      let programa = [...new Set(responsePrograma.data.map((x) => x.programa))];

      let result = [destino, Raza, Cuota, Especie, programa];
      return result;
    }
  }
  //Info desplegable Feedlot
  async getFeedLot() {
    return axios.get(API_URL + `/auth/feedLot_list/`, {
      headers: authHeader(),
    });
  }

  // APUNTA A API - BUSQUEDA DE PROVEEDORES PARA DESPLEGABLES
  getListProveedor() {
    return axios.get(API_URL + `/auth/proveedores2/`, {
      headers: authHeader(),
    });
  }
  getListFeedlot() {
    return axios.get(API_URL + `/auth/feedlot_usuarios/`, {
      headers: authHeader(),
    });
  }

  //Info desplegable consignatario y remitente

  getListConsigRemit() {
    return axios.get(API_URL + `/auth/proveedoresCompras/`, {
      headers: authHeader(),
    });
  }
  //Info desplegable consignatario y remitente

  getListConsigRemitTropa() {
    return axios.get(API_URL + `/auth/proveedoresTropa/`, {
      headers: authHeader(),
    });
  }
  // APUNTA A API - BUSQUEDA DE PROVEEDORES PARA DESPLEGABLES
  getFiltros() {
    return axios.get(API_URL + `/auth/filtros/`, { headers: authHeader() });
  }
  //Info desplegable compradores
  getListcompradores() {
    return axios.get(API_URL + `/auth/user_list_Comprador/`, {
      headers: authHeader(),
    });
  }

  // listado de compras
  getComprasList(
    cuitUsuarioCarga='',
    feedlot='',
    tipoCompra='',
    fecha_desde= '',
    fecha_hasta = '',
    estado = '',
    for_donwload = false,
    campo_id = null,
    is_recria=true,
    is_feedlot=true
  ) {
    return axios.get(
      API_URL +
      `/auth/compras_list/?tipoCompra=${tipoCompra}&feedlot=${feedlot}&cuitUC=${cuitUsuarioCarga}&fecha_desde=${fecha_desde}&fecha_hasta=${fecha_hasta}&status=${estado}&for_download=${for_donwload}&campo_id=${campo_id}&is_recria=${is_recria}&is_feedlot=${is_feedlot}`,
      { headers: authHeader() , responseType: for_donwload === true ? 'blob' : 'json'},

    );
  }

  // listado de compras para Estado de compras
  getListComprasEstado(
    start_date,
    end_date,
    agrupacion,
    consignatario,
    remitente,
    statusCompra,
    comprador,
    moneda,
    especie,
    destinoComercial,
    tCompra,
    peso,
    cuota,
    desagrupado,
    establecimiento,
    is_propia=null,
  ) {
    //console.log(tCompra)
    return axios.get(
      API_URL +
      `/auth/compras_estado/?start_date=${start_date}&end_date=${end_date}&agrupacion=${agrupacion}&consignatario=${consignatario}&remitente=${remitente}&status=${statusCompra}&comprador=${comprador}&especie=${especie}&destinoComercial=${destinoComercial}&tCompra=${tCompra}&peso=${peso}&cuota=${cuota}&desagrupado=${desagrupado}&moneda=${moneda}&establecimiento=${establecimiento}&is_propia=${is_propia}`,
      { headers: authHeader() },
    );
  }

  getListComprasEstadoExcel(
    start_date,
    end_date,
    agrupacion,
    consignatario,
    remitente,
    statusCompra,
    comprador,
    moneda,
    especie,
    destinoComercial,
    tCompra,
    peso,
    cuota,
    desagrupado,
    pdf,
    establecimiento
  ) {
    //console.log(tCompra)
    return axios.get(
      API_URL +
      `/auth/compras_estado_excel/?start_date=${start_date}&end_date=${end_date}&agrupacion=${agrupacion}&consignatario=${consignatario}&remitente=${remitente}&status=${statusCompra}&comprador=${comprador}&especie=${especie}&destinoComercial=${destinoComercial}&tCompra=${tCompra}&peso=${peso}&cuota=${cuota}&desagrupado=${desagrupado}&moneda=${moneda}&pdf=${pdf}&establecimiento=${establecimiento}`,
      { headers: authHeader(), responseType: 'blob' },
    );
  }

  getListComprasEstadoAgrupadasExcel(
    start_date,
    end_date,
    agrupacion,
    consignatario,
    remitente,
    statusCompra,
    comprador,
    moneda,
    especie,
    destinoComercial,
    tCompra,
    peso,
    cuota,
    desagrupado,
    pdf
  ) {
    //console.log(tCompra)
    return axios.get(
      API_URL +
      `/auth/compras_agrupadas_excel/?start_date=${start_date}&end_date=${end_date}&agrupacion=${agrupacion}&consignatario=${consignatario}&remitente=${remitente}&status=${statusCompra}&comprador=${comprador}&especie=${especie}&destinoComercial=${destinoComercial}&tCompra=${tCompra}&peso=${peso}&cuota=${cuota}&desagrupado=${desagrupado}&moneda=${moneda}&pdf=${pdf}`,
      { headers: authHeader(), responseType: 'blob' },
    );
  }
  //Info desplegable detalle de estado de compras
  getComprasEstadoDetalle(start_date, end_date, especie, destino) {
    return axios.get(
      API_URL +
      `/auth/compras_estado_detalle/?start_date=${start_date}&end_date=${end_date}&especie=${especie}&destino=${destino}`,
      { headers: authHeader() },
    );
  }

  //borrar compra
  deleteCompra(id) {
    return axios.delete(API_URL + `/auth/compras_delete/${id}/`, {
      headers: authHeader(),
    });
  }

  //registrar compra
  async postCompra(
    tipo_de_compra,
    fecha_de_compra,
    fecha_de_negocio,
    fecha_de_carga,
    fecha_de_faena,
    comprador,
    planta,
    status,
    consignatario,
    remitente,
    destino_Comercial,
    cuota,
    cuota_Inverada,
    raza,
    especie,
    programa,
    programa_real,
    contacto_de_carga_id,
    lugar_de_carga,
    feedlot_id,
    cantidad,
    desbaste,
    kilómetros_aproximados,
    flete,
    jaula_simple,
    jaula_doble,
    cabezas_compradas,
    precio,
    peso,
    plazo,
    comisionista,
    comision,
    procedencia,
    observaciones,
    hqf,
    last_modified_by,
    rinde,
    is_propia,
    is_nop,
    precio_flete,
    gastos_adicionales,
    condicion_pago,
    campo_id,
    is_recria=false,
    is_feedlot=false,
    establecimiento
  ) {
    // paso data a JSON
    const info = JSON.stringify({
      tipo_de_compra,
      fecha_de_compra,
      fecha_de_negocio,
      fecha_de_carga,
      fecha_de_faena,
      comprador,
      planta,
      status,
      consignatario,
      remitente,
      destino_Comercial,
      cuota,
      cuota_Inverada,
      raza,
      especie,
      programa,
      programa_real,
      contacto_de_carga_id,
      lugar_de_carga,
      feedlot_id,
      cantidad,
      desbaste,
      kilómetros_aproximados,
      flete,
      jaula_simple,
      jaula_doble,
      cabezas_compradas,
      precio,
      peso,
      plazo,
      comisionista,
      comision,
      procedencia,
      observaciones,
      hqf,
      last_modified_by,
      rinde,
      is_propia,
      is_nop,
      precio_flete,
      gastos_adicionales,
      condicion_pago,
      campo_id,
      is_recria,
      is_feedlot,
      establecimiento
    });

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await fetch(API_URL + "/auth/compras_register/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.access}`,
        "Access-Control-Allow-Origin": LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Allow: "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
      },
      body: info,
    });
    return response;
  }

  //editar compra
  async putCompra(
    id,
    tipo_de_compra,
    fecha_de_compra,
    fecha_de_negocio,
    fecha_de_carga,
    fecha_de_faena,
    comprador,
    planta,
    status,
    consignatario,
    remitente,
    destino_Comercial,
    cuota,
    cuota_Inverada,
    raza,
    especie,
    programa,
    programa_real,
    contacto_de_carga_id,
    lugar_de_carga,
    feedlot_id,
    cantidad,
    desbaste,
    kilómetros_aproximados,
    flete,
    jaula_simple,
    jaula_doble,
    cabezas_compradas,
    precio,
    peso,
    plazo,
    comisionista,
    comision,
    procedencia,
    observaciones,
    hqf,
    last_modified_by,
    rinde,
    enviarMailActualizacion,
    arrayCambios,
    is_propia,
    is_nop,
    precio_flete,
    gastos_adicionales,
    condicion_pago,
    campo_id,
    is_recria=false,
    is_feedlot=false,
    establecimiento
  ) {
    // paso data a JSON

    const info = JSON.stringify({
      tipo_de_compra,
      fecha_de_compra,
      fecha_de_negocio,
      fecha_de_carga,
      fecha_de_faena,
      comprador,
      planta,
      status,
      consignatario,
      remitente,
      destino_Comercial,
      cuota,
      cuota_Inverada,
      raza,
      especie,
      programa,
      programa_real,
      contacto_de_carga_id,
      lugar_de_carga,
      feedlot_id,
      cantidad,
      desbaste,
      kilómetros_aproximados,
      flete,
      jaula_simple,
      jaula_doble,
      cabezas_compradas,
      precio,
      peso,
      plazo,
      comisionista,
      comision,
      procedencia,
      observaciones,
      hqf,
      last_modified_by,
      rinde,
      enviarMailActualizacion,
      arrayCambios,
      is_propia,
      is_nop,
      precio_flete,
      gastos_adicionales,
      condicion_pago,
      campo_id,
      is_recria,
      is_feedlot,
      establecimiento
    });

    const user = JSON.parse(localStorage.getItem("user"));
    return await fetch(API_URL + `/auth/compras_update/${id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.access}`,
        "Access-Control-Allow-Origin": LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Allow: "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
      },
      body: info,
    });
  }

  // cambiar status compra
  //editar compra
  async putStatusCompra(id, status, last_modified_by) {
    // paso data a JSON

    const info = JSON.stringify({ id, status, last_modified_by });
    const user = JSON.parse(localStorage.getItem("user"));

    //await axios.put(API_URL + `/auth/compras_update_status/${id}/`,info, { headers: authHeader() })

    const response = await fetch(
      API_URL + `/auth/compras_update_status/${id}/`,
      {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": LOCAL_URL,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
        body: info,
      },
    );

    return response;
  }
  async putStatusFeedlot(id, status) {
    return axios.put(API_URL + `/auth/feedlot_status/?id=${id}&status=${status}`, {}, { headers: authHeader() })
  }

  async putStatusCompraLink(id, status) {
    // paso data a JSON

    const info = JSON.stringify({ id, status });
    const user = JSON.parse(localStorage.getItem("user"));

    //await axios.put(API_URL + `/auth/compras_update_status/${id}/`,info, { headers: authHeader() })

    const response = await fetch(
      API_URL + `/auth/compras_update_status_link/${id}/`,
      {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": LOCAL_URL,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
        body: info,
      },
    );

    return response;
  }

  // usuarios de carga
  getListContactoDeCarga() {
    return axios.get(API_URL + `/auth/contactosDC_list/`, {
      headers: authHeader(),
    });
  }
  //guardar Contacto de Carga
  async postContactoDeCarga(data) {

    return axios.post(API_URL + "/auth/contactosDC_register/", data, {
      headers: authHeader(),
    })
  }
  //editar Contacto de Carga

  async putContactoDeCarga(
    id,
    username,
    last_name,
    telefono,
    setIsLoading,
    setError,
  ) {
    const info = JSON.stringify({ username, last_name, telefono });
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await fetch(API_URL + `/auth/contactosDC_update/${id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.access}`,
        "Access-Control-Allow-Origin": LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Allow: "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
      },
      body: info,
    })
      .catch((error) => { })
      .then(function (response) {
        if (response.status === 200) {
          setError(null);
          setIsLoading(false);
        }
      });
  }
  //borrar Contacto de Carga

  deleteContactodeCarga(id) {
    return axios.delete(API_URL + `/auth/contactosDC_delete/${id}/`, {
      headers: authHeader(),
    });
  }

  ////////////////ESTADISTICAS

  getEstadisticas(cuit, especie) {
    return axios.get(
      API_URL + `/auth/estadisticas/?cuit=${cuit}&especie=${especie}`,
      { headers: authHeader() },
    );
  }
  ////declaracion General para proveedor
  getcheckGeneral(cuit) {
    return axios.get(API_URL + `/auth/doc_compra_cert/?cuit=${cuit}`, {
      headers: authHeader(),
    });
  }

  async getTropaSearch(tropa) {
    return axios.get(API_URL + `/auth/tropa_search/?tropa=${tropa}`, {
      headers: authHeader(),
    });
  }
  //info para informe de FAena
  async getInforme(
    tropa,
    start_date,
    end_date,
    especie,
    ebeln,
    flagMarcaAgrupada,
    tropaToSearch,
  ) {
    return axios.get(
      API_URL +
      `/auth/InformeFaena/?tropa=${tropa}&start_date=${start_date}&end_date=${end_date}&especie=${especie}&ebeln=${ebeln}&marca_agrup=${flagMarcaAgrupada}&tropaSearch=${tropaToSearch}`,
      { headers: authHeader() },
    );
    /* await axios.get(API_URL + `/auth/InformeFaena/?tropa=${tropa}&start_date=${start_date}&end_date=${end_date}&especie=${especie}`, { headers: authHeader() })
    .then((res) => {
      //return res;
      console.log(res)
      var filename = 'informe.pdf';
      console.log(res)
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      console.log(link.href)
      link.download = filename;
      link.click();  })
 */
  }

  async ImprimirInformeGeneral(tropas, start_date, end_date) {
    let tropasArray = [];

    if (typeof tropas != "string") {
      tropasArray.push(...new Set(tropas.map((x) => x.tropa)));
    } else {
      tropasArray.push(tropas);
    }

    //return axios.get(API_URL + `/auth/InformeFaena/?tropa=${tropa}&start_date=${start_date}&end_date=${end_date}&especie=${especie}`, { headers: authHeader() });
    await axios
      .post(
        API_URL + `/auth/ImprimirInformeGeneral/`,
        { tropasArray, start_date, end_date },
        { headers: authHeader() },
      )
      .then((res) => {
        if (tropasArray.length == 1) {
          var numeroTropa = tropas.split("/");
          //console.log(numeroTropa);
          var filename = `${numeroTropa[0] + numeroTropa[1]}_${start_date}.pdf`;
        } else {
          var filename = `Informe_General.pdf`;
        }
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = filename;
        link.click();
      });
  }
  // funcion para DTE pendientes de asignacion
  getDte() {
    return axios.get(API_URL + `/auth/dte_pendientes/`, {
      headers: authHeader(),
    });
  }
  // funcion para DTE
  dteControl() {
    return axios.get(API_URL + `/auth/dte_control/`, { headers: authHeader() });
  }

  // funcion para DTE
  dteDelete(dte) {
    return axios.delete(API_URL + `/auth/dte_delete/?dte=${dte}`, {
      headers: authHeader(),
    });
  }

  // asignar dte a compra
  async putSingleCompraDte(id, dte, consolidacion) {
    // paso data a JSON
    const user = JSON.parse(localStorage.getItem("user"));

    let form_data = new FormData();

    form_data.append("dte", dte);
    form_data.append("compra_id", id);
    form_data.append("consolidacion", consolidacion);

    return axios
      .post(API_URL + "/auth/compras_update_Dte/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
  }

  // tropas pendientes de asignacion de dte
  async getTropasPendientesDte(fechaDesde, fechaHasta) {
    return axios.get(API_URL + `/auth/tropas_pendientes_dte/?fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`, { headers: authHeader() })
  }

  //editar compra asignando SOLAMENTE DTE!
  async putCompraDte(id, dte) {
    // paso data a JSON
    const user = JSON.parse(localStorage.getItem("user"));

    let responses = []

    for (let index = 0; index < dte.length; index++) {
      let form_data = new FormData();

      const element = dte[index];
      form_data.append("dte", element.dte);
      form_data.append("compra_id", id);
      if (dte.length > 1) {
        form_data.append("consolidacion", false);
      } else {
        form_data.append("consolidacion", true);
      }

      const response = await axios
        .post(API_URL + "/auth/compras_update_Dte/", form_data, {
          headers: {
            Authorization: `Bearer ${user.access}`,
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Allow: "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "multipart/form-data",
            "X-CSRFTOKEN": "csrfCookie",
          },
          beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
          },
        })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        });

      responses.push(response)
    }
    return responses;
  }

  //Guardar Imagen
  async postImagen(tropa, data, fecha) {
    const user = JSON.parse(localStorage.getItem("user"));

    // paso data a JSON
    //const info = JSON.stringify({})
    for (let index = 0; index < data.length; index++) {
      let form_data = new FormData();

      const element = data[index];
      form_data.append("tropa", tropa);
      form_data.append("fecha", fecha);
      form_data.append("image_url", element.img);

      return await axios
        .post(API_URL + "/auth/posts/", form_data, {
          headers: {
            Authorization: `Bearer ${user.access}`,
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Allow: "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "multipart/form-data",
            "X-CSRFTOKEN": "csrfCookie",
          },
          beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
          },
        })
        // .then((res) => {
        //   return res;
        // })
        // .catch((error) => {
        //   return error.response;
        // });
    }
    //console.log(data)
  }
  //GET Imagen
  async getImage(tropa, fecha) {
    // paso data a JSON

    //console.log(tropa)

    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios
      .get(API_URL + `/auth/posts/?tropa=${tropa}&fecha=${fecha}`, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

  //guardar Indicadores
  async postIndicadores(
    destino,
    semana,
    cuota,
    valor,
    year,
    agrupacion,
    month,
  ) {
    const info = JSON.stringify({
      destino,
      semana,
      cuota,
      valor,
      year,
      agrupacion,
      month,
    });

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .post(API_URL + "/auth/indicadores/", info, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

  async putIndicadores(destino, semana, cuota, valor, year, agrupacion, month) {
    const info = JSON.stringify({ destino, semana, cuota, valor, year, month });

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .put(
        API_URL +
        `/auth/indicadores_update/?semana=${semana}&cuota=${cuota}&valor=${valor}&destino=${destino}&year=${year}&agrupacion=${agrupacion}&month=${month}`,
        info,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Allow: "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

  // funcion para DTE pendientes de asignacion
  async getIndicadores(semana, year, agrupacion, month) {
    return axios.get(
      API_URL +
      `/auth/indicadores_get/?semana=${semana}&year=${year}&agrupacion=${agrupacion}&month=${month}`,
      { headers: authHeader() },
    );
  }

  async getFaenaStats(month, year) {
    return axios.get(
      API_URL +
      `/auth/faena_stats/?mes=${month}&year=${year}`,
      { headers: authHeader() },
    );
  }

  async updateStockStatus(
    id,
    status
  )
  {
    const info = JSON.stringify({ id, status });
    const user = JSON.parse(localStorage.getItem("user"));

    const response = await fetch(
      API_URL + `/auth/stock_update_status/${id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Origin": LOCAL_URL,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
        body: info,
      },
    );

    return response;
  }

  //guardar Stock
  async postStock(
    fecha,
    tropa,
    corrales,
    kgVivoProm,
    procedencia,
    cabezas,
    status,
    especie,
    raza,
    conformacion,
    gordura,
    observaciones,
    programa,
    caja,
    aptitud,
    image_url,
    cambioCC,
    usuverd,
  ) {
    const info = JSON.stringify({
      fecha,
      tropa,
      corrales,
      kgVivoProm,
      procedencia,
      cabezas,
      status,
      especie,
      raza,
      conformacion,
      gordura,
      observaciones,
      programa,
      caja,
      aptitud,
      image_url,
      cambioCC,
      usuverd,
    });

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .post(API_URL + "/auth/stock_register/", info, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
  //Editar Stock
  async putStock(
    id,
    fecha,
    tropa,
    corrales,
    cabezas,
    status,
    especie,
    raza,
    conformacion,
    gordura,
    observaciones,
    programa,
    caja,
    aptitud,
    image_url,
    cambioCC,
    usuverd,
  ) {
    const info = JSON.stringify({
      fecha,
      tropa,
      corrales,
      cabezas,
      status,
      especie,
      raza,
      conformacion,
      gordura,
      observaciones,
      programa,
      caja,
      aptitud,
      image_url,
      cambioCC,
      usuverd,
    });

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .put(API_URL + `/auth/stock_update/${id}/`, info, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
  //GET STOCK
  async getStock(fecha) {
    return axios.get(API_URL + `/auth/stock_list/?fecha=${fecha}`, {
      headers: authHeader(),
    });
  }
  //ELIMIAr STOCK
  deteleStockLinea(id) {
    return axios.delete(API_URL + `/auth/stock_delete/${id}/`, {
      headers: authHeader(),
    });
  }
  //Guardar Theme para el usuario
  async putThemeUser(id, theme) {
    // paso data a JSON

    const info = JSON.stringify({ theme });

    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(API_URL + `/auth/update_theme/${id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.access}`,
        "Access-Control-Allow-Origin": LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Allow: "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
      },
      body: info,
    });
  }
  // borrar el documento
  async detelePhoto(id) {
    return axios.delete(API_URL + `/auth/post_delete/${id}/`, {
      headers: authHeader(),
    });
  }

  // enviar enviar mail a las compras no confirmadas
  // async emailSend(data, emailPersonalizado, emailPersonalizadoMensaje) {
  //   // console.log(data)

  //   for (let index = 0; index < data.length; index++) {

  //     let form_data = new FormData();

  //     //const element = data[index];

  //     form_data.append("compraID", element[0]);
  //     form_data.append("emailPersonalizado", emailPersonalizado)
  //     form_data.append("mensaje", emailPersonalizadoMensaje)
  //     const user = JSON.parse(localStorage.getItem('user'));

  //     const response = await axios.post(API_URL + '/auth/emailSend/', form_data, {
  //       headers: {
  //         'Authorization': `Bearer ${user.access}`,
  //         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //         "Allow": "GET, POST, PUT, DELETE, OPTIONS",
  //         'Access-Control-Allow-Headers': 'Content-Type',
  //         "Content-Type": "multipart/form-data",
  //         'X-CSRFTOKEN': 'csrfCookie',
  //       },
  //       beforeSend: function (xhr) {
  //         xhr.setRequestHeader('X-CSRFToken', '{{csrf_token}}');
  //       },
  //     }).then((res) => {
  //       return res;
  //     }).catch((error) => {
  //       return error.response;
  //     });

  //   }

  // }
  async emailSend(
    data,
    emailPersonalizado,
    emailPersonalizadoMensaje,
    incluyeBotones,
  ) {
    const promises = data.map(async (element) => {
      let form_data = new FormData();
      form_data.append("compraID", element[0]);
      form_data.append("emailPersonalizado", emailPersonalizado);
      form_data.append("mensaje", emailPersonalizadoMensaje);
      form_data.append("botones", incluyeBotones);

      const user = JSON.parse(localStorage.getItem("user"));

      try {
        const response = await axios.post(
          API_URL + "/auth/emailSend/",
          form_data,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              Allow: "GET, POST, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Content-Type",
              "Content-Type": "multipart/form-data",
              "X-CSRFTOKEN": "csrfCookie",
            },
            beforeSend: function (xhr) {
              xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
            },
          },
        );
        return response;
      } catch (error) {
        return error.response;
      }
    });

    try {
      const responses = await Promise.all(promises);
      return responses;
    } catch (error) {
      throw error;
    }
  }

  async emailSendTropa(data) {
    for (let index = 0; index < data.length; index++) {
      let form_data = new FormData();

      const element = data[index];
      form_data.append("tropa", element[1]);
      form_data.append("remitente", element[0]);
      form_data.append("ebeln", element[2]);

      const user = JSON.parse(localStorage.getItem("user"));

      const response = await axios
        .post(API_URL + "/auth/emailSendTropa/", form_data, {
          headers: {
            Authorization: `Bearer ${user.access}`,
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Allow: "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "multipart/form-data",
            "X-CSRFTOKEN": "csrfCookie",
          },
          beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
          },
        })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        });
    }
  }
  // guardar Documento
  async postDocumento(
    titulo,
    cuit,
    razonS,
    direccion,
    renspa,
    provincia,
    telefono,
    email,
    fechaAlta,
    fechaVencimiento,
    firmo,
    tropa,
    archivo,
    nombre_usuario,
    posicion_usuario
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("titulo", titulo);
    form_data.append("cuit_productor", cuit);
    form_data.append("razonS", razonS);
    form_data.append("direccion", direccion);
    form_data.append("renspa", renspa);
    form_data.append("ubicacion", provincia);
    form_data.append("telefono", telefono);
    form_data.append("email", email);
    form_data.append("fecha_de_alta", fechaAlta);
    form_data.append("fecha_de_vencimiento", fechaVencimiento);
    form_data.append("firmo", firmo);
    form_data.append("doc_url", archivo);
    form_data.append("nombre_usuario", nombre_usuario);
    form_data.append("posicion_usuario", posicion_usuario);

    if (tropa != undefined || tropa != "No Aplica") {
      form_data.append("tropa", tropa);
    } else {
      form_data.append("tropa", "No Aplica");
    }

    const response = await axios
      .post(API_URL + "/auth/doc/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        if (titulo != "Organic" && firmo == true) {
          //this.generarPDF(form_data,titulo)
        }

        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  async putDocumento(
    id,
    titulo,
    cuit,
    razonS,
    direccion,
    renspa,
    provincia,
    telefono,
    email,
    fechaAlta,
    fechaVencimiento,
    firmo,
    tropa,
    nombre_usuario,
    posicion_usuario
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    //console.log(data)
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("id", id);

    form_data.append("titulo", titulo);
    form_data.append("cuit_productor", cuit);
    form_data.append("razonS", razonS);
    form_data.append("direccion", direccion);
    form_data.append("renspa", renspa);
    form_data.append("ubicacion", provincia);
    form_data.append("telefono", telefono);
    form_data.append("email", email);
    form_data.append("fecha_de_alta", fechaAlta);
    form_data.append("fecha_de_vencimiento", fechaVencimiento);
    form_data.append("firmo", firmo);
    form_data.append("nombre_usuario", nombre_usuario);
    form_data.append("posicion_usuario", posicion_usuario);

    if (tropa != undefined) {
      form_data.append("tropa", tropa);
    } else {
      form_data.append("tropa", "No Aplica");
    }

    const response = await axios
      .put(API_URL + `/auth/doc_update/${id}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        if (titulo != "Organic" && firmo == true) {
          //this.generarPDF(form_data,titulo)
        }
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }
  // editar Documento

  // pedir Documento
  async getDocumento(cuit) {
    return axios.get(API_URL + `/auth/doc/?cuit=${cuit}`, {
      headers: authHeader(),
    });
  }
  // funcion para sacar datos de un documento.
  async getInfoDocumento(id, tipo) {
    return axios.get(API_URL + `/auth/doc_info/?id=${id}&tipo=${tipo}`, {
      headers: authHeader(),
    });
  }
  // eliminar Documento
  async deleteDoc(id) {
    return axios.delete(API_URL + `/auth/doc_delete/${id}/`, {
      headers: authHeader(),
    });
  }

  // CHECKLISTTTTTTTTTTTTTTT
  async generarPDF(form_data, tipoForm) {
    const user = JSON.parse(localStorage.getItem("user"));

    axios
      .post(API_URL + "/auth/generate/pdf/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        //return res;
        var filename = tipoForm + ".pdf";
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = filename;
        link.click();
        /* const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL; */
      })
      .catch((error) => {
        //return error.response;
      });
  }
  // listado de renspas para Documentos
  async getRenspa(cuit) {
    return axios.get(API_URL + `/auth/renspa/?cuit=${cuit}`, {
      headers: authHeader(),
    });
  }

  // listado de renspas para Documentos
  async getTropasPrograma(cuit) {
    return axios.get(API_URL + `/auth/tropaPrograma/?cuit=${cuit}`, {
      headers: authHeader(),
    });
  }
  //guardar certificado de bienestar de animales
  async postBienestarAnimales(
    fecha_de_alta,
    fecha_de_vencimiento,
    cuitProveedor,
    nombreCompania,
    provincia,
    localidad,
    politicaBienestar,
    descPoliticaBienestar,
    capacitacionPersonal,
    fecha_de_capacitacionPersonal,
    manga,
    corralCierre,
    rampa,
    manejoDeAnimales,
    descripcionManejoAnimales,
    manejodeCarga,
    descripcionManejoCarga,
    email,
    firmo,
    codigo,
    revision,
    titulo,
    nombre_usuario,
    posicion_usuario
  ) {
    const info = {
      fecha_de_alta,
      fecha_de_vencimiento,
      cuitProveedor,
      nombreCompania,
      provincia,
      localidad,
      politicaBienestar,
      descPoliticaBienestar,
      capacitacionPersonal,
      fecha_de_capacitacionPersonal,
      manga,
      corralCierre,
      rampa,
      manejoDeAnimales,
      descripcionManejoAnimales,
      manejodeCarga,
      descripcionManejoCarga,
      email,
      firmo,
      codigo,
      revision,
      titulo,
      nombre_usuario,
      posicion_usuario,
    };
    let form_data = new FormData();
    //form_data.append(info)
    /* for (const [key, value] of Object.entries(info)) {
      if (key=='doc_url'){
        console.log(doc_url)
        form_data.append('doc_url',doc_url)
      }else{
        form_data.append(key,value)
 
      }
    } */
    for (const [key, value] of Object.entries(info)) {
      form_data.append(key, value);
    }

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .post(API_URL + "/auth/bienestar_animales/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        if (firmo == true) {
          // this.generarPDF(form_data,titulo)
        }
        return res;
      });

    return response;
  }

  //guardar certificado de bienestar de animales
  async putBienestarAnimales(
    id,
    fecha_de_alta,
    fecha_de_vencimiento,
    cuitProveedor,
    nombreCompania,
    provincia,
    localidad,
    politicaBienestar,
    descPoliticaBienestar,
    capacitacionPersonal,
    fecha_de_capacitacionPersonal,
    manga,
    corralCierre,
    rampa,
    manejoDeAnimales,
    descripcionManejoAnimales,
    manejodeCarga,
    descripcionManejoCarga,
    email,
    firmo,
    codigo,
    revision,
    titulo,
    nombre_usuario,
    posicion_usuario
  ) {
    const info = {
      fecha_de_alta,
      fecha_de_vencimiento,
      cuitProveedor,
      nombreCompania,
      provincia,
      localidad,
      politicaBienestar,
      descPoliticaBienestar,
      capacitacionPersonal,
      fecha_de_capacitacionPersonal,
      manga,
      corralCierre,
      rampa,
      manejoDeAnimales,
      descripcionManejoAnimales,
      manejodeCarga,
      descripcionManejoCarga,
      email,
      firmo,
      codigo,
      revision,
      titulo,
      nombre_usuario,
      posicion_usuario,
    };

    let form_data = new FormData();
    //form_data.append(info)
    /* for (const [key, value] of Object.entries(info)) {
      if (key=='doc_url' ){
        form_data.append('doc_url',doc_url)
      }else{
        form_data.append(key,value)

      }
    } */
    for (const [key, value] of Object.entries(info)) {
      form_data.append(key, value);
    }
    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .put(API_URL + `/auth/bienestar_animales_edit/${id}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        if (firmo == true) {
          //this.generarPDF(form_data,titulo)
        }
        return res;
      });

    return response;
  }

  //guardar certificado de bienestar de Transport
  async postBienestarAnimalesTransporte(
    fecha_de_alta,
    fecha_de_vencimiento,
    cuitProveedor,
    nombreCompania,
    controlHabilitacionCamiones,
    fecha_de_controlHabilitacionCamiones,
    controlEstadoCamion,
    descripcionControlEstadoCamion,
    cantidadYEstadoAnimales,
    avisoAPlanta,
    protocoloEmergencias,
    descripcionProtocoloEmergencias,
    capacitacionChoferes,
    fecha_de_controlCapacitacionChoferes,
    email,
    firmo,
    codigo,
    revision,
    titulo,
    nombre_usuario,
    posicion_usuario
  ) {
    const info = {
      fecha_de_alta,
      fecha_de_vencimiento,
      cuitProveedor,
      nombreCompania,
      controlHabilitacionCamiones,
      fecha_de_controlHabilitacionCamiones,
      controlEstadoCamion,
      descripcionControlEstadoCamion,
      cantidadYEstadoAnimales,
      avisoAPlanta,
      protocoloEmergencias,
      descripcionProtocoloEmergencias,
      capacitacionChoferes,
      fecha_de_controlCapacitacionChoferes,
      email,
      firmo,
      codigo,
      revision,
      titulo,
      nombre_usuario,
      posicion_usuario,
    };

    //form_data.append(info)
    let form_data = new FormData();
    //form_data.append(info)
    /* for (const [key, value] of Object.entries(info)) {
      if (key=='doc_url' ){
        form_data.append('doc_url',doc_url)
      }else{
        form_data.append(key,value)

      }
    } */
    for (const [key, value] of Object.entries(info)) {
      form_data.append(key, value);
    }

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .post(API_URL + "/auth/bienestar_animales_Transp/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        if (firmo == true) {
          //this.generarPDF(form_data,titulo)
        }
        return res;
      });

    return response;
  }

  //Editar certificado de bienestar de Transport
  async putBienestarAnimalesTransporte(
    id,
    fecha_de_alta,
    fecha_de_vencimiento,
    cuitProveedor,
    nombreCompania,
    controlHabilitacionCamiones,
    fecha_de_controlHabilitacionCamiones,
    controlEstadoCamion,
    descripcionControlEstadoCamion,
    cantidadYEstadoAnimales,
    avisoAPlanta,
    protocoloEmergencias,
    descripcionProtocoloEmergencias,
    capacitacionChoferes,
    fecha_de_controlCapacitacionChoferes,
    email,
    firmo,
    codigo,
    revision,
    titulo,
    nombre_usuario,
    posicion_usuario
  ) {
    const info = {
      fecha_de_alta,
      fecha_de_vencimiento,
      cuitProveedor,
      nombreCompania,
      controlHabilitacionCamiones,
      fecha_de_controlHabilitacionCamiones,
      controlEstadoCamion,
      descripcionControlEstadoCamion,
      cantidadYEstadoAnimales,
      avisoAPlanta,
      protocoloEmergencias,
      descripcionProtocoloEmergencias,
      capacitacionChoferes,
      fecha_de_controlCapacitacionChoferes,
      email,
      firmo,
      codigo,
      revision,
      titulo,
      nombre_usuario,
      posicion_usuario,
    };

    let form_data = new FormData();
    //form_data.append(info)
    /*  for (const [key, value] of Object.entries(info)) {
       if (key=='doc_url' && doc_url.length>0){
         form_data.append('doc_url',doc_url[0].img)
       }else{
         form_data.append(key,value)
 
       }
     } */

    for (const [key, value] of Object.entries(info)) {
      form_data.append(key, value);
    }

    const user = JSON.parse(localStorage.getItem("user"));

    const response = await axios
      .put(API_URL + `/auth/bienestar_animales_Transp_edit/${id}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        if (firmo == true) {
          // this.generarPDF(form_data,titulo)
        }
        //this.generarPDF(form_data,titulo)
        return res;
      });

    return response;
  }
  //cargar certificado al documento
  async putCertificado(data, certificado, tipo) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("tipo", tipo);

    form_data.append("doc_url", data);

    const response = await axios
      .put(API_URL + `/auth/posts_cert/${certificado}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });

    //console.log(data)
  }

  async putCertificadoTropa(data, ebeln, columna) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    //console.log(data,certificado,tipo)
    let form_data = new FormData();

    form_data.append("col", columna);
    if (data.length > 0) {
      form_data.append(columna, data[0].img);
    } else {
      form_data.append(columna, "");
    }

    const response = await axios
      .put(API_URL + `/auth/tropa_update/${ebeln}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });

    //console.log(data)
  }

  async deleteCert(id, tipo) {
    if (tipo == "Check List de Bienestar Animal para campos") {
      return axios.delete(API_URL + `/auth/bienestar_animales_delete/${id}/`, {
        headers: authHeader(),
      });
    } else if (tipo == "Check List Bienestar Animal Empresas Transportistas") {
      return axios.delete(
        API_URL + `/auth/bienestar_animales_Transp_delete/${id}/`,
        { headers: authHeader() },
      );
    } else {
      return axios.delete(API_URL + `/auth/doc_delete/${id}/`, {
        headers: authHeader(),
      });
    }
  }

  //dashboard
  // funcion para sacar datos de un documento.
  async getDashboard(
    fechaStart,
    fechaEnd,
    especie,
    remitente,
    consig,
    grouping,
    filtroUsuarioFaena,
  ) {
    return axios.get(
      API_URL +
      `/auth/dashboard/?start_date=${fechaStart}&end_date=${fechaEnd}&especie=${especie}&remitente=${remitente}&consig=${consig}&grouping=${grouping}&usuarios=${filtroUsuarioFaena}`,
      { headers: authHeader() },
    );
  }

  // guardar Documento
  async postDatosOrigenInv(
    ID,
    kilosTicket,
    kilosNeto,
    cantidadCargada,
    promedio,
    Image,
    horarioPesada,
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("compra_id", ID);
    form_data.append("kilos_ticket", kilosTicket);
    form_data.append("kilos_neto", kilosNeto);
    form_data.append("cantidad_cargada", cantidadCargada);
    form_data.append("promOrigen", promedio);
    form_data.append("horario_pesada_origen", horarioPesada);
    form_data.append("archivoOrigen", Image);
    form_data.append("acordado", false);

    const response = await axios
      .post(API_URL + "/auth/compras_register_origen/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  async putDatosOrigenInv(
    id,
    id_compra,
    kilosTicket,
    kilosNeto,
    cantidadCargada,
    promedio,
    Image,
    horarioPesada,
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("compra_id", id_compra);
    form_data.append("kilos_ticket", kilosTicket);
    form_data.append("kilos_neto", kilosNeto);
    form_data.append("cantidad_cargada", cantidadCargada);
    form_data.append("promOrigen", promedio);
    form_data.append("horario_pesada_origen", horarioPesada);
    form_data.append("archivoOrigen", Image);
    form_data.append("acordado", false);

    const response = await axios
      .put(API_URL + `/auth/compras_update_origen/${id}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  // guardar INVERNADA DESTINO
  async postDatosDestinoInv(
    ID,
    kilosBalanza,
    promedio,
    cantidadRecep,
    horarioPesada,
    Image,
    muertos,
    nro_lote
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("compra_id", ID);
    form_data.append("kilos_balanza", kilosBalanza);
    form_data.append("prom_destino", promedio);
    form_data.append("cantidad_recepcionada", cantidadRecep);
    form_data.append("horario_pesada_destino", horarioPesada);
    form_data.append("archivo_destino", Image);
    form_data.append("acordado", false);
    form_data.append("muertos", muertos);
    form_data.append("nro_lote", nro_lote);


    const response = await axios
      .post(API_URL + "/auth/compras_register_destino/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  async putDatosDestinosInv(
    id,
    id_compra,
    kilosBalanza,
    promedio,
    cantidadRecep,
    horarioPesada,
    Image,
    muertos,
    nro_lote
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("compra_id", id_compra);
    form_data.append("kilos_balanza", kilosBalanza);
    form_data.append("prom_destino", promedio);
    form_data.append("cantidad_recepcionada", cantidadRecep);
    form_data.append("horario_pesada_destino", horarioPesada);
    form_data.append("archivo_destino", Image);
    form_data.append("acordado", false);
    form_data.append("muertos", muertos);
    form_data.append("nro_lote", nro_lote);

    const response = await axios
      .put(API_URL + `/auth/compras_update_destino/${id}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  // guardar INVERNADA Ajustes
  async postDatosAdjustesInv(
    ID,
    mermaReal,
    kilosAjustados,
    mermaAjustada,
    fechaAjuste,
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("compra_id", ID);
    form_data.append("merma_real", mermaReal);
    form_data.append("kilos_ajustados", kilosAjustados);
    form_data.append("merma_ajustada", mermaAjustada);
    form_data.append("fecha_de_ajuste", fechaAjuste);

    const response = await axios
      .post(API_URL + "/auth/compras_register_ajustes/", form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  // guardar INVERNADA Ajustes
  async putDatosAdjustesInv(
    idDAtos,
    ID,
    mermaReal,
    kilosAjustados,
    mermaAjustada,
    fechaAjuste,
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    // paso data a JSON
    //const info = JSON.stringify({})
    let form_data = new FormData();

    form_data.append("compra_id", ID);
    form_data.append("merma_real", mermaReal);
    form_data.append("kilos_ajustados", kilosAjustados);
    form_data.append("merma_ajustada", mermaAjustada);
    form_data.append("fecha_de_ajuste", fechaAjuste);

    const response = await axios
      .put(API_URL + `/auth/compras_update_ajustes/${idDAtos}/`, form_data, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;

    //console.log(data)
  }

  async putPrecioAjustesInv (ID, precio){
    const user = JSON.parse(localStorage.getItem("user"));

    let form_precio = new FormData()

    form_precio.append("precio", precio)

    const response = await axios
      .put(API_URL +  `/auth/compra/${ID}/`, form_precio, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Allow: "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "multipart/form-data",
          "X-CSRFTOKEN": "csrfCookie",
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader("X-CSRFToken", "{{csrf_token}}");
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

  //borrar compra
  deleteDataInv(id) {
    return axios.delete(API_URL + `/auth/compras_datosInv_delete/${id}/`, {
      headers: authHeader(),
    });
  }

  // obtener reporte de faena en binario
  getFaenaDownloadReport(
    fechaInicio,
    FechaFinal,
    tipo,
    usuarioFaena,
    tipoCompra,
    marca,
    especie,
    remitente,
    consig,
    marcasAgrup,
    tropaToSearch,
    pdf,
    totales,
    show_especie,
    establecimientos
  ) {
    return axios.get(
      API_URL +
      `/auth/faena_download_report/?start_date=${fechaInicio}&end_date=${FechaFinal}&tipo=${tipo}&marca=${marca}&especie=${especie}&usuarioFaena=${usuarioFaena}&tipoCompra=${tipoCompra}&remitente=${remitente}&consig=${consig}&marcasAgrup=${marcasAgrup}&tropaSearch=${tropaToSearch}&pdf=${pdf}&totales=${totales}&show_especie=${show_especie}&establecimientos=${establecimientos}`,
      { headers: authHeader(), responseType: 'blob' },
    );
  }

  getOcPendientes(
    fechaInicio,
    fechaFinal
  ){
    return axios.get(API_URL + `/auth/oc_pendientes/?fecha_desde=${fechaInicio}&fecha_hasta=${fechaFinal}`,{ headers: authHeader(),})
  }

  async getGrupoCompras() {
    return axios.get(API_URL + `/auth/grupo_compras/`, {
      headers: authHeader(),
    });
  }

  async getCompraFeedlot(id) {
    return axios.get(API_URL + `/auth/compra_feedlot/?id=${id}`, {
      headers: authHeader(),
    });
  }

  async getAlmacenes() {
    return axios.get(API_URL + `/auth/almacenes/`, {
      headers: authHeader(),
    });
  }

  async getCondicionesPago() {
    return axios.get(API_URL + '/auth/condiciones_pago/', {
      headers: authHeader(),
    })
  }

  async getPreciosEstimadosMeta() {
    return axios.get(API_URL + '/auth/precios_estimados/meta/', { headers: authHeader() })
  }

  async getPreciosEstimados(url_params) {
    return axios.get(API_URL + '/auth/precios_estimados/' , { headers: authHeader(), params: url_params})
  }

  async postPreciosEstimados(data) {
    return axios.post(API_URL + '/auth/precios_estimados/', data, { headers: authHeader() })
  }

  async getFaenaComprasByIdInvernada(id) {
    return axios.get(API_URL + `/auth/logistica_invernada/${id}`, {
      headers: authHeader(),
    });
  }

  async postFaenaCompra(data) {
    return axios.post(API_URL + `/auth/logistica_invernada/`, data, {
      headers: authHeader(),
    });
  }

  async getComprasRelacionadas(filters) {
    const params = new URLSearchParams(filters).toString();
    return axios.get(API_URL + `/auth/logistica_invernada/compras/?${params}`, {
      headers: authHeader(),
    });
  }

  async deleteRelacionCompra(id_invernada, id_faena) {
    return axios.delete(API_URL + `/auth/logistica_invernada/?id_invernada=${id_invernada}&id_faena=${id_faena}`, {
      headers: authHeader(),
    });
  }
  
  async updateCompraInvernadaByFletero(data) {
    return axios.post(API_URL + '/auth/logistica_invernada/compras/flete/update/', data, { headers: authHeader() })
  }

  async getCompraInvernadaDetail(id) {
    return axios.get(API_URL + `/auth/invernada/compra/${id}`, { headers: authHeader() })
  }

  async getCamposInvernada(){
    return axios.get(API_URL + '/auth/invernada/campos/', { headers: authHeader() })
  }

  async getEstablecimientos(){
    return axios.get(API_URL + '/auth/establecimientos/', { headers: authHeader() })
  }
  async getDtesInvernadaPendientesByCompraId(id) {
    return axios.get(API_URL + `/auth/invernada/dtes/pendientes/${id}`, { headers: authHeader() })
  }
  
  async postRelationsDtesInvernada(data) {
    return axios.post(API_URL + '/auth/invernada/dtes/', data, { headers: authHeader() })
  }

  async getRelationsDtesInvernadaByCompraId(id) {
    return axios.get(API_URL + `/auth/invernada/dtes/?compraId=${id}`, { headers: authHeader() })
  }

  async deleteRelationDtesInvernada(id) {
    return axios.delete(API_URL + `/auth/invernada/dtes/?relationId=${id}`, { headers: authHeader() })
  }

  async createDteInvernada(data) {
    return axios.post(API_URL + '/auth/invernada/dtes/create/', data, { headers: authHeader() })
  }
}

export default new UserService();

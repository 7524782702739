import tinycolor from "tinycolor2";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { sub } from "date-fns";
const breakpoints = createBreakpoints({});

const primary = "#8c734b";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const black = "black";
const white = "white";
const tableBorderColor = "#d1d7de";
const background2 = "#ebebeb";//"#161b22"; //'#f6f3f0';//'#24292f'
const background = "#f8f9fa"; //'#f8f9fa';
const subtotals = "#e2e2e2";
const totals = "#c2c2c2";
const textPrimary = "#313235";
const toggleButton = "#ebebeb"//"#161b22";   //'#f6f3f0'
const tableHeaders = "#ebebeb"//"#161b22";   //'#f6f3f0'
const headerText = textPrimary//"#e1e8ee";
const disabledColor = '#8c959f'
const strippedRow = '#f2f4f6'

const buttonColor = background2

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: textPrimary, //"#fff",
      secondary: textPrimary,
      hint: headerText,
      black: headerText, //tableBorderColor,
      disabled: disabledColor,
    },
    icons: {
      color: textPrimary,
    },
    divider: tableBorderColor,
    borderColor: tableBorderColor,
    toggleButtons: {
      background: toggleButton,
      color: primary,
    },
    background: {
      main: white,
      default: background, //"#121212",
      light: background,
      paper: "#f5f7f8",
      custom: background2,
    },
    line1: {
      primary: "rgb(255 20 20)",
      secondary: "#ff5c93",
      main: "#ff6800",
    },
    totals: {
      total: totals, //tinycolor(primary).darken().toHexString(),
      subtotal: subtotals, //primary
      general: tinycolor(subtotals).darken(25).toHexString(),
      ue: tinycolor(subtotals).darken(5).toHexString(),
    },
    tables: {
      tableBorderColor: tableBorderColor,
      header: tableHeaders,
      headerText: headerText,
      romaneoBorder: tinycolor(tableBorderColor).darken().toHexString(),
    },
    spotlight: {
      green: tinycolor("#81c784").darken().toHexString(),
      yellow: tinycolor("#ffb74d").darken().toHexString(),
      red: tinycolor("#e57373").darken().toHexString(),
    },
    compras_states: {
      confirmado: tinycolor('#B9E0A5').darken().toHexString(),
      sin_confirmar: tinycolor('#F7A9A8').darken().toHexString(),
      arribado: tinycolor('#AFCBFF').darken().toHexString(),
      postergado: tinycolor('#FFCCF2').darken().toHexString(),
      tramite: tinycolor('#fcec5b').darken().toHexString(),
    },
    dashboard: {
      default: "#c16125",
      uno: "#ce883e",
      dos: "#c59474",
      tres: "#bbaf9d",
      cuatro: "#7e6756",
      cinco: "#7c858c",
      seis: "#a3915f",
      backgroundWidget: "#f5f7f8",
    },
    sidebar: {
      inactiveBg: background2,
      inactiveBgHover: tinycolor(background2).lighten().toHexString(),
      inactiveColor: headerText,
      activeBg: tinycolor(background2).lighten().toHexString(),
      activeBgHover: tinycolor(background2).lighten().toHexString(),
      activeColor: primary,
    },
    textarea: {
      background: tinycolor("#f5f7f8").lighten().toHexString(),
      color: textPrimary,
    },
    action: {
      disabled: disabledColor
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  shadows: ["none"],

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          backgroundColor: background2,
          width: "15px",
          height: "15px",
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: background2,
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgb(120, 120, 120)",
          borderRadius: "10px",
        },
        "*::-webkit-scrollbar-corner": {
          backgroundColor: background2,
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgb(85, 84, 84);",
        },
        "@font-face": {
          fontFamily: "SF Mono",
          src: 'url("/fonts/SF-Mono.dmg")', // Replace with the path to your font file
          fontWeight: "normal",
          fontStyle: "normal",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        background: background2,
        color: textPrimary,
      },
    },
    MuiTableCell: {
      footer: {
        //border: "none",
      },
    },
    //cambio
    MuiDrawer: {
      paper: {
        backgroundColor: background2, //"rgb(35, 35, 45)",
      },
    },
    MuiInputAdornment: {
      root: {
        color: textPrimary,
      },
    },
    //cambio

    MuiIconButton: {
      root: {
        color: "#D6D6D6",
      },
      label: {
        height: "8px",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#D6D6D6",
      },
    },
    MuiSvgIcon: {
      root: { color: textPrimary },
      selected: { color: primary },
    },
    //cambio
    MuiButtonBase: {
      root: {
        
        grouped: {
          color: textPrimary,
          borderColor: tableBorderColor,
        },
        standard: {
          borderColor: tableBorderColor,
        },
        '&.Mui-disabled': {
          color : disabledColor
        }
      },
    },
    MuiToggleButton : {
      root :{
        '&.Mui-disabled': {
          color : disabledColor
        }
      }
    },
    MuiTypography :{
      root: {
        "&.Mui-disabled": {
          color: disabledColor
        }
      },
      label: {
        "&.Mui-disabled": {
          color: disabledColor
        }
      }
    },
    MuiDatePicker: {
      input: {
        color: textPrimary,
      },
    },
    MuiOutlinedInput: {
      borderColor: tableBorderColor, //'white',
      "&.Mui-disabled": { borderColor: tableBorderColor, color: disabledColor },

      notchedOutline: {
        borderColor: tableBorderColor, //'white'
        borderRadius: "15px",
        maxHeight: "60px",
      },
      root: {
        "&.Mui-disabled": { borderColor: tableBorderColor, color: disabledColor },
        color: textPrimary,
        borderColor: primary,
        
      },
      '&:hover': {
        borderColor: primary
      }
    },

    MuiFormLabel: {
      // Name of the slot
      root: {
        "&$disabled": { color: disabledColor },
        color: textPrimary,
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: background2, //'rgb(35, 35, 45)'
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&$disabled": {
            borderColor: tableBorderColor, //"white"
            color: disabledColor,
          },
        },
      },
      input: {
        color: textPrimary,
        borderColor: tableBorderColor, //'white',
        "&$disabled": {
          borderColor: tableBorderColor, //"white",
          color: disabledColor
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: "initial",
          backgroundColor: "black",
        },
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "0px",
      },
    },
    MuiTableBody :{
      root: {
        borderRadius: '5px',
      }
    },
    MUIDataTable : {
      responsive: { 
        borderRadius: '5px'
      }
      
    },
    MUIDataTableSelectCell: {
      fixedHeader: {
        position: "relative",
      },
      fixedLeft: {
        position: "relative",
      },
      headerCell: {
        position: "relative",
      },
    },
    MuiDataGrid: {
      columnHeaderTitle: {
        fontWeight: "600",
        justifyContent: "center",
      },
      columnHeaderTitleContainer: {
        justifyContent: "center",
      },
      cell: {
        border: tableBorderColor,
        borderBottom: "1px solid " + tableBorderColor,
      },
      root: {
        border: "1px solid " + tableBorderColor,
        borderColor: tableBorderColor,
      },
    },
    MuiDialogTitle: {
      root: {
        overflow: "initial",
      },
    },
    //termina primer cambio
    MuiMenu: {
      paper: {
      border: "2px solid " + tableBorderColor,
    },},
    MuiCard: {
      root: {
        backgroundColor: background2, //'rgb(35, 35, 45)'
      },
    },

    //cambio
    MuiDialog: {
      paper: {
        backgroundColor: background2,
      },
      root: {
        backgroundColor: background2,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: background, //'rgb(35, 35, 45)'
        borderRadius: "5px",
        borderColor: primary
      },
      elevation: {
        backgroundColor: background,
        borderRadius: "5px",
      },
      outlined: {
        borderRadius: "5px",
      },
      rounded: {
        borderRadius: "5px",
      }
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        backgroundColor: background2,
      },
      root: {
        backgroundColor: background2,
        borderRadius: "15px",
      },
      listbox: {
        baclgroundColor: background2,
        color: textPrimary,
      },
      "&.Mu-focused": {
        color: 'red'
      },
      
    },
    MuiButton: {
      outlinedPrimary: {
        backgroundColor: buttonColor,//background2,
        color: primary,
        fontWeight: 'bold',
        textTransform: "capitalize",
        height: '50px',
        "&:hover": {
          backgroundColor: buttonColor,//tinycolor(background2).lighten().toHexString(),
          border: "2px solid " + primary,
        },
      },
      containedPrimary: {
        backgroundColor: buttonColor,//background2,
        color: primary,
        fontWeight: 'bold',
        textTransform: "capitalize",
        height: '50px',
        "&:hover": {
          backgroundColor: buttonColor,//tinycolor(background2).lighten().toHexString(),
          border: "2px solid " + primary,
        },
      },
      contained: {
        backgroundColor: buttonColor,//background2,
        color: primary,
        fontWeight: 'bold',
        textTransform: "capitalize",
        height: '50px',
        "&:hover": {
          backgroundColor: buttonColor,//tinycolor(background2).lighten().toHexString(),
          border: "2px solid " + primary,
        },
        "&.Mui-disabled": {
          //backgroundColor: tinycolor(background2).lighten().toHexString(),
          color: disabledColor,
        },
      },
      root: {
        borderRadius: "15px",
        maxHeight: "55px",
        "&:hover": {},
      },
    },
    MuiLink: {
      underlineHover: {
        cursor: "pointer",
      },
      underlineAlways: {
        cursor: "pointer",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: background,
          color: primary,
        },
      },
      "&:hover, &:focus ": {
        backgroundColor: background,
      },
      linkActive: {
        backgroundColor: tinycolor(background).darken().toHexString(),
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: background,
        },
      },
    },


    MuiFormControlLabel :{
      disabled: {
        color: disabledColor
      },
      label: {
        '&.Mui-disabled': {
          color: disabledColor
        }
      } 
    },

    MuiListItemText: {
      root: {
        //color: "#D6D6D6",
      },
    },
    MuiLinkTextActive: {
      root: {
        //color: "#D6D6D6",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: background2, //"rgb(35, 35, 45)",
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "#fff",
      },
    },
    MUIDataTableBodyCell: {
      root: {
        minWidth: "max-content",
        //minHeight: '1rem',
        //Width: 'auto',
        padding: "0px 3px 0px 3px",
        //alignSelf: 'center'
      },
    },
    MUIDataTableResize: {
      resizer: {
        borderColor: tableBorderColor,
      }
    },
    MuiTableCell: {
      head: {
        border: "2px solid " + tableBorderColor,
        borderBottom: "4px solid " + tableBorderColor,
        lineHeight: "1.2rem",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
      },
      body: {
        border: "2px solid " + tableBorderColor,
      },
      root: {
        padding: "3px",
      },
    },
    MuiChip: {
      root: {
        color: "black",
      },
      label: {
        color: "black",
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: strippedRow,
        }
      }
    },
    MUIDataTable: {
      tableRoot: {
        borderRadius: "20px 20px 0 0",
      },
    },
    MUIDataTableHeadCell: {
      root: {
        minWidth: "max-content",
        //minHeight: '0.5rem',
        Width: "auto",
        //Height: 'auto',
        maxHeight: "0.1px",
        padding: "0px 3px 0px 3px",
        alignSelf: "center",
        color: headerText,
        textAlign: "center !important",
      },
      fixedHeader: {
        backgroundColor: background2, //'rgb(35, 35, 45)',
        textAlign: "center !important",
        alignItems: "center !important",
        color: headerText,
      },
      sortAction: {
        textAlign: "center",
        alignItems: "center",
      },
      data: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      },
      toolButton: {
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: textPrimary,
      },
    },
    MuiTableHead: {
      root: {
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        color: headerText,
        
      },
      head: {
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        color: headerText,
        
      },
    },
    MUIDataTableToolbar: {
      filterPaper: {
        [breakpoints.down("xs")]: {
          maxWidth: "100%",
        },
      },
      actions: {
        display: "flex",
        flex: "initial",
        // move all icons to the right
        "& > span, & > button": {
          order: 99,
        },
        // target the custom toolbar icon
        "& > span:last-child, & > button:last-child": {
          order: 1,
        },
        // target any icon
        "& > span:nth-child(4), & > button:nth-child(4)": {
          order: 2,
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
  },
  shadows: ["none"],
};

export default defaultTheme;

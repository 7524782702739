import { makeStyles } from "@material-ui/styles";
import themes from "../../themes";

export default makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        padding: '10px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 1,
        border: '3px solid ' + theme.palette.borderColor,

        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    list_item: {
        '& span': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                textWrap: 'wrap'
            }
        }
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    error: {
        marginTop: '10px',
        color: theme.palette.secondary.main
    },
    title: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    subtitle: {
        fontWeight: 'bold !important',
    },
    select: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.borderColor,
        }
    },
    create_dte: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    form_inputs: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
    },
    form_rows: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form_button: {
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    no_error: {
        color: theme.palette.spotlight.green
    },
    error: {
        color: theme.palette.spotlight.red
    }
}));
import React, { useState, useContext, useEffect, memo } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Fade,
} from "@material-ui/core";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import moment from "moment";
import Dropzone from 'react-dropzone-uploader'
import { useHistory } from 'react-router';
import { Preview } from "../variables/variables";
import { useTheme } from "@material-ui/styles";
import './styilo.css'
import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";

const FormInvDestino = memo(function FormInvDestino({ datosCompra, ...props }) {
  //var user = JSON.parse((localStorage.getItem('userData')))
  const history = useHistory();
  var classes = useStyles();
  //var rol = localStorage.getItem('permissions')
  const theme = useTheme();
  const { user } = useContext(UserStateContext)
  const permissions = user.permissions;
  const isInterno = user.type === 'Rioplatense'

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');


  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState(moment().format('YYYY-MM-DD'))
  var [fechaVencimiento, setFechaVencimiento] = useState(moment().add(1, 'year').format('YYYY-MM-DD'))


  // local
  var [consignatario, setConsignatario] = useState('');
  var [contacto, setContacto] = useState(() => { if (user.cuit != null) { return user.cuit } else { return '' } });
  var [origen, setOrigen] = useState(() => { if (user.razonS != null) { return user.razonS } else { return '' } });
  var [flete, setFlete] = useState("");
  var [fechaCarga, setFechaCarga] = useState("");
  var [feedlot, setFeedlot] = useState("");
  var [cantidad, setCantidad] = useState("");
  var [kilosAprox, setKilosAprox] = useState('');
  var [kilosBalanza, setKilosBalanza] = useState('');
  var [promedio, setPromedio] = useState("");
  var [cantidadRecep, setCantidadRecep] = useState('');
  var [promedio, setPromedio] = useState("");
  var [horarioPesada, setHorarioPesada] = useState(moment().format('HH:mm'));
  const [editable, setEditable] = useState(false);
  const [gotResponse, setGotResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [muertos, setMuertos] = useState(0);
  const [cantidadCargada, setCantidadCargada] = useState(0);
  const [showErrorBalance, setShowErrorBalance] = useState(false);
  const [nroLote, setNroLote] = useState(null);



  var [desplRenspa, setDesplRenspa] = useState([])
  var [edit, setEdit] = useState(false);
  var [ID, setID] = useState();
  var [idDatos, setIDDatos] = useState();

  //manejo de tiempos del servidor
  let [errorServidor, setErrorServidor] = useState(null);

  let [isLoading2, setIsLoading2] = useState(false);
  let [desplegables, setDespletables] = useState([]);
  let [sumarImg, setSumarImg] = React.useState(false);
  let [Image, setImage] = React.useState([]);

  // feedback
  const [openFeedback, setOpenFeedback] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
    if (!responseError && !isInterno) {
        props.servidor(user)
        props.cerrar()
      }
  }
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  // called every time a file's `status` changes
  const toast = (innerHTML, clase) => {
    const el = document.getElementById('toast')
    el.innerHTML = innerHTML
    el.style = clase
  }

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'headers_received') {
      toast(`${meta.name} Subido!`, 'color:#05f105;font-size:1.2rem')
      remove()
      setImage(file)
      //await UserService.postImagen(fotos)
      //setOpen(false)
      setSumarImg(false)
    }
    else if (status === 'aborted') {
      toast(`${meta.name}, Error en la subida del archivo...`, 'color:red;font-size:1.2rem')
    }

  }

  useEffect(() => {
    if ((muertos + cantidadRecep != cantidadCargada)){
      if(!showErrorBalance){
        setShowErrorBalance(true)
      }
    } else {
      setShowErrorBalance(false)
    }
  },[muertos, cantidadRecep, cantidadCargada]);

  //funcion para guardar doc
  const guardarDocumento = async () => {
    setResponseMessage('')
    setResponseError(false)
    setIsLoading2(true)
    let response;
    try {
      if (edit === false) {
        response = await UserService.postDatosDestinoInv(ID, kilosBalanza, parseFloat(promedio).toFixed(2), cantidadRecep, horarioPesada, Image, muertos, nroLote);
      } else {
        response = await UserService.putDatosDestinosInv(idDatos, ID, kilosBalanza, parseFloat(promedio).toFixed(2), cantidadRecep, horarioPesada, Image, muertos, nroLote);
      }
      if (response.status == 201 || response.status == 200) {
        setResponseMessage("Datos guardados correctamente");
        props.handleHasBeenEdited()
      } else {
        throw new Error(response?.data?.Compra[0] || "Error al guardar los datos")
      }
    }
    catch (error) {
      setResponseMessage(error?.message || "Error al guardar los datos");
      setResponseError(true);
    } finally {
      setIsLoading2(false)
      setOpenFeedback(true);
    }
  }

  //
  const calcularProm = (val) => {
    setCantidadRecep(parseInt(val))
    if (kilosBalanza != undefined) {
      setPromedio(parseFloat(kilosBalanza / val).toFixed(2))

    }

  }

  const kilosBalYProm = (val) => {
    setKilosBalanza(val)
    if (cantidadRecep != undefined) {
      //setPromedio(val/cantidadRecep)
      setPromedio(parseFloat(val / cantidadRecep).toFixed(2))

    }
  }

  const getDataInvernada = async () => {
    setLoading(true)
    try {
      let response = await UserService.getCompraInvernadaDetail(props.compraId)
      if (response.status === 404) {
        return null
      } else if (response.status !== 200) {
        throw new Error("Error al obtener los datos de la compra")
      }
      return response.data
    } catch (error) {
      setErrorServidor(true)
      setError("Error al obtener los datos de la compra")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setID(props.compraId)
    getDataInvernada().then(data => {
      setGotResponse(true)
      if (data) {
        setConsignatario(data.consignatario)
        setContacto(data.contacto_de_carga_id?.username)
        setOrigen(data.lugar_de_carga)
        setFlete(data.flete)
        setFechaCarga(data.fecha_de_carga)
        setFeedlot(data.feedlot_id?.nombre)
        setCantidad(data.cabezas_compradas)
        setKilosAprox(data.peso)
        if (data?.invernada) {
          setEdit(true)
          setIDDatos(data.invernada?.id)
          setKilosBalanza(data.invernada?.kilos_balanza)
          setCantidadRecep(data.invernada?.cantidad_recepcionada)
          setPromedio(data.invernada?.prom_destino)
          setCantidadCargada(data.invernada?.cantidad_cargada)
          setMuertos(data.invernada?.muertos)
          setNroLote(data.invernada?.nro_lote)
          
          if (data.invernada?.horario_pesada_destino) {
            setHorarioPesada(data.invernada?.horario_pesada_destino)
          }
        }
      }
    })

  }, []);

  useEffect(() => {
    if (gotResponse && 
        parseFloat(kilosBalanza) >= 0 &&
        parseInt(cantidadRecep) >= 0 &&
        (muertos + cantidadRecep) === parseInt(cantidadCargada) &&
        nroLote !== null
      ) {
      setEditable(true)
    } else {
      setEditable(false)
    }
  }, [gotResponse, kilosBalanza, cantidadRecep, muertos, cantidadRecep, cantidadCargada, nroLote])

  return (
    <>

      <div className={classes.marginNormal}>
        <Fade in={errorServidor}>
          <Typography color="secondary" className={classes.errorMessage}>
            {error}
          </Typography>
        </Fade>
      </div>

      <div className={classes.form}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Datos de Destino
            </Typography>


          </Grid>

          {showErrorBalance && (
          <Grid item lg={12} xs={12} sm={12}>
            <Fade in={showErrorBalance}>
              <Alert elevation={6} variant="filled" severity="error"><AlertTitle>Muertos + Cantidad Recepcionada no coincide con la cantidad cargada</AlertTitle></Alert>
            </Fade>
          </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Consignatario" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={consignatario}
                onChange={e => setConsignatario(e.target.value)}
                margin="normal"
                placeholder="Consignatario"
                variant="outlined"
                label="Consignatario"
                type="text"
                fullWidth
                disabled

              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Contacto" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={contacto}
                onChange={e => setContacto(e.target.value)}
                margin="normal"
                placeholder="Contacto"
                variant="outlined"
                label="Contacto"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Origen" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={origen}
                onChange={e => setOrigen(e.target.value)}
                margin="normal"
                placeholder="Origen"
                variant="outlined"
                label="Origen"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Flete" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={flete}
                onChange={e => setFlete(e.target.value)}
                margin="normal"
                placeholder="Flete"
                variant="outlined"
                label="Flete"
                type="text"
                disabled
                fullWidth />
            </Grid>

            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="F. Carga" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={fechaCarga}
                onChange={e => setFechaCarga(e.target.value)}
                margin="normal"
                placeholder="F. Carga"
                variant="outlined"
                label="F. Carga"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Feedlot" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={feedlot}
                onChange={e => setFeedlot(e.target.value)}
                margin="normal"
                placeholder="Feedlot"
                variant="outlined"
                label="Feedlot"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Cantidad" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={cantidad}
                onChange={e => setCantidad(e.target.value)}
                margin="normal"
                placeholder="Cantidad"
                variant="outlined"
                label="Cantidad"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Kilos Aprox" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={kilosAprox}
                onChange={e => setKilosAprox(e.target.value)}
                margin="normal"
                placeholder="Kilos Aprox"
                variant="outlined"
                label="Kilos Aprox"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Kilos Balanza" InputProps={{
                inputProps: { min: 0 },

                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                onWheel={event => event.target.blur()}
                value={kilosBalanza}
                onChange={e => { if (e.target.value >= 0) { kilosBalYProm(e.target.value) } }}
                margin="normal"
                placeholder="Kilos Balanza"
                variant="outlined"
                label="Kilos Balanza"
                type="number"
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Cantidad Recepcionada" InputProps={{
                inputProps: { min: 0 },

                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                onWheel={event => event.target.blur()}
                value={cantidadRecep}
                onChange={e => { if (e.target.value >= 0) { calcularProm(e.target.value) } }}
                margin="normal"
                placeholder="Cantidad Recepcionada"
                variant="outlined"
                label="Cantidad Recepcionada"
                type="number"
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Muertos" InputProps={{
                inputProps: { min: 0 },

                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                onWheel={event => event.target.blur()}
                value={muertos}
                onChange={e => setMuertos(parseInt(e.target.value))}
                margin="normal"
                placeholder="Muertos"
                variant="outlined"
                label="Muertos"
                type="number"
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField
                id="Nro Lote"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                value={nroLote}
                onChange={(e) => setNroLote(e.target.value)}
                margin="normal"
                placeholder="Nro Lote"
                variant="outlined"
                label="Nro Lote"
                type="text"
                error={nroLote === null}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Promedio" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                InputLabelProps={{ shrink: true }}

                value={promedio}
                onChange={e => setPromedio(e.target.value)}
                margin="normal"
                placeholder="Promedio"
                variant="outlined"
                label="Promedio"
                type="text"
                disabled
                fullWidth />
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <TextField id="Horario Pesada" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
                value={horarioPesada}
                onChange={e => setHorarioPesada(e.target.value)}
                margin="normal"
                placeholder="Horario Pesada"
                variant="outlined"
                label="Horario Pesada"
                type="time"
                InputLabelProps={{ shrink: true }}

                fullWidth />
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              <div id="toast"></div>

              <Dropzone
                classNames={classes.dropZone}
                styles={{
                  dropzone: {
                    color: theme.palette.primary.main,
                    overflow: 'hidden',
                    margin: '10px 0px',
                    borderColor: theme.palette.tables.tableBorderColor
                  },
                  submitButton: {
                    padding: '15px',
                    fontSize: '1.2em',
                  },
                  preview: {
                    color: theme.palette.primary.main
                  },
                  inputLabel: {
                    color: theme.palette.primary.main
                  }
                }}
                /* getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                onSubmit={handleSubmit}*/
                PreviewComponent={Preview}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}


                accept="image/*,.pdf"
                inputContent='Arrastra tus archivos o hace click aqui'
              /*  submitButtonContent="Guardar Datos"
               inputWithFilesContent='Sumar otro archivo' */
              />
            </Grid>







          </Grid>

          <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{ justifyContent: 'center', display: 'flex' }} justify="center">
            <Grid item lg={3} xs={12} sm={12}>
              <Button
                fullWidth
                onClick={() => guardarDocumento(props.history)}

                size="large"
                variant="contained"
                color="primary"
                disabled={
                  !editable
                }

                className={classes.createAccountButton}
              //component={Link} to={`/app/gestionDoc`}
              >

                GUARDAR
              </Button>
            </Grid>
            <Grid item lg={3} xs={12} sm={12}>
              <Button
                className={classes.createAccountButton}
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                //component={Link} to={`/app/feedLot`}
                onClick={props.cerrar}>


                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={openFeedback}
        autoHideDuration={2000}
        onClose={handleCloseFeedback}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={responseError ? "error" : "success"}
        >
          <AlertTitle>
            {responseMessage}
          </AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
})
export default FormInvDestino;